import axios from 'axios';

const server = axios.create({
    
    baseURL : 'https://api.classicedu.org/public/api',
    headers : { 
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded'
        //'Content-Type' : 'multipart/form-data; boundary=<calculated when request is sent>'
    }
});

export default server;