import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserBookings,gethostelPdf } from '../../actions/HostelBookings';
import swal from 'sweetalert';
import $ from 'jquery';
import Server from '../../utils/Server';
import AppConstants from '../../assets/constants/';
import Images from '../../assets/images/';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import HostelPDF from './pdf'

class EditBooking extends React.Component {
  constructor(props){
    super(props);
    const { booking } = props;
    this.state = { booking : booking, user_id : props.match.params.user_id, hostel_id : props.match.params.hostel_id, booking_id: props.match.params.booking_id, totalAmount: booking ? booking.total_price : 0, orderPrice: booking ? booking.balance_amount : 0, paymentType: '', userData: booking ? booking.user_details : {}};
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.pdfExportComponent = React.createRef(null);
    this.contentArea = React.createRef(null);
  }

  componentDidMount() {
    this.props.onGetUserBookings(this.state.user_id, '?hostel_id=' + this.state.hostel_id + '&booking_id=' + this.state.booking_id);
  }

  componentDidUpdate(prevProps) {
    const {booking} = this.props;
    if (booking && Object.entries(booking).length > 0) {
      if (prevProps.booking !== this.props.booking) {
        const {booking} = this.props;
        console.log('States updated');
        this.setState({booking: booking, totalAmount: booking ? booking.total_price : 0, orderPrice: booking ? booking.balance_amount : 0, userData: booking ? booking.user_details : {}});
      }
    }
  }

  //  handleExportWithComponent = async (event) => {
  //   // await this.props.gethostelPdf(Id)
  //   // console.log(Id,"hostelpdfData",hostelpdfData)
  //   this.pdfExportComponent.current.save();
  // }
  renderPDFButton = (Id) =>{
    const handleExportWithComponent = async (event) => {
      await this.props.gethostelPdf(Id)
      //console.log(Id,"hostelpdfData",hostelpdfData)
      this.pdfExportComponent.current.save();
    }
    const {hostelpdfData} = this.props;
    return( 
      <td>
      <div className="pdf-invisible"  >
        <PDFExport ref={this.pdfExportComponent} paperSize="A4" >
        <div  ref={this.contentArea}>
          <HostelPDF pdfData={hostelpdfData} />
          </div>
        </PDFExport> 
      </div>
      
        <div className="">  
            <i class="fa fa-print" onClick={()=> handleExportWithComponent()}></i>
        </div>
        </td>
      )
    }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let booking = this.props.booking;
    booking[name] = value;
    this.setState({ booking : booking });
  }

  handleValueChange = (e) => {
    var name = e.editor.name;
    var value = e.editor.getData();
    let booking = this.props.booking;

    booking[name] = value;
    this.setState({ booking : booking });
  }

  handleChangeAmount = (e) => {
    this.setState({orderPrice: e.target.value});
  }

  onNewBookingSubmit = (e) => {
    e.preventDefault();

    const {paymentType, totalAmount, orderPrice, booking_id} = this.state;
    const {booking} = this.props;
    var userData = booking ? booking.user_details : {};
    var formData = new FormData(document.getElementById('edit_booking_form'));
    formData.append('booking_id', booking_id); // Attach booking ID

    if(booking && Object.entries(booking).length > 0) {
      formData.append('mobile_number', userData.mobile_number);
      formData.append('id', booking.hostel_id);
      formData.append('start_date', booking.start_date);
      formData.append('end_date', booking.end_date);
      formData.append('no_of_months', booking.no_of_months);
      formData.append('total_price', booking.total_price);
    }

    // Offline payment
    if(paymentType != '' && paymentType == 0) {
        swal({
            title: "Are you sure?",
            text: "To pay this hostel fees?",
            buttons: ['Cancel', 'PAY'],
        })
        .then(willDelete => {
            if (willDelete) {
                formData.append('payment_type', 1);

                Server.post('/HostelPaymentSuccess', formData)
                    .then(response => {
                        if(response.data.statuscode === 200) {
                            swal({ title :'Thank you', text : response.data.message, icon : 'success'});
                            setTimeout(() => window.location.reload(), 1000);
                        } 
                    })
                    .catch(error => {
                        swal('OOPS!...', error.message);
                    });
            }
        });
    } 
    // Online payment through razorpay
    else {
        // Payment gateway if payment type is online payment
        formData.append('order_price', 1); // //orderPrice from states should be included

        swal({
          title: "Are you sure?",
          text: "To pay this hostel fees?",
          buttons: ['Cancel', 'PAY'],
        })
        .then(willDelete => {
            if (willDelete) {
                Server.post('/AdminSaveCheckout', formData)
                .then(response => {
                    if(response.data.statuscode === 200) {
                        // Initialize payment
                        var options = {
                            "key": AppConstants.RAZORPAY_API_KEY,
                            // "amount": (totalAmount * 100),
                            "amount": 1,
                            "name": 'Hostel Booking',
                            "description": 'Classic Education Dharwad',
                            "image": Images.logo,
                            "order_id" : response.data.rzpay_order_id,
                            "currency": 'INR',
                            "theme" : {
                            "color": "#44a1a0"
                            },
                            "handler": function (response){
                                if(response && response.razorpay_payment_id !== '')
                                {
                                    // Make payment success in server

                                    formData.append('rzpay_order_id', response.razorpay_order_id);
                                    formData.append('rzpay_payment_id', response.razorpay_payment_id);
                                    formData.append('rzpay_signature', response.razorpay_signature);
                                    formData.append('payment_type', 1);

                                    Server.post('/HostelPaymentSuccess', formData)
                                        .then(response => {
                                            if(response.data.statuscode === 200) {
                                                swal({ title :'Thank you', text : response.data.message, icon : 'success'});
                                                setTimeout(() => window.location.reload(), 1000);
                                            } 
                                        })
                                        .catch(error => {
                                            swal('OOPS!...', error.message);
                                        });
                                }
                            },
                            "prefill" : {
                            "contact" : userData.mobile_number,
                            "email" : userData.email
                            }
                        }
            
                        let rzp = new window.Razorpay(options); rzp.open();
                    }
                })
                .catch(error => {
                    swal('OOPS!...', error.message);
                });
            }
        });
    }
}

  render() {

    const { booking } = this.props;
    const {orderPrice, totalAmount, paymentType} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/hostel_bookings">Hostel Bookings</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Booking</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Booking </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/hostel_bookings/add_booking"><button type="button" className="btn btn-primary btn-sm">New Booking <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit booking form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card">
              <br/>

              <form id="edit_booking_form" onSubmit={this.onNewBookingSubmit} autoComplete="off">
              <div className="row">
                  <div className="col">
                      <div className="form-group">
                          <label className="small_text">Student * : </label>
                          <input type="text" placeholder="Student" className="form-control" defaultValue={(booking && booking.user_details) ? (booking.user_details.name + ' - ' + booking.user_details.mobile_number) : ''} disabled />
                      </div>

                      <div className="form-group">
                          <label className="small_text"> Hostel * : </label>
                          <select className="form-control" defaultValue={booking ? booking.hostel_id : ''} required>
                              <option value={booking ? booking.hostel_id : 0}>{booking ? booking.hostel_name : ''}</option>
                          </select>
                      </div>

                      <div className="row form-group">
                          <div className="col">
                              <label className="small_text">Total Months * : </label>
                              <input type="number" className="form-control" placeholder="Total Months" disabled value={booking ? booking.no_of_months : 0} />
                          </div>
                          <div className="col">
                              <label className="small_text">Price Per Month * : </label>
                              <input type="number" placeholder="Price Per Month" className="form-control" disabled value={booking ? booking.price_per_month : 0}/>
                          </div>
                      </div>

                      <div className="row form-group">
                          <div className="col">
                              <label className="small_text">Joining Date : </label>
                              <input type="date" className="form-control" disabled value={booking ? booking.start_date : ''} required />
                          </div>
                          <div className="col">
                              <label className="small_text">Relieving Date * : </label>
                              <input type="date" className="form-control" disabled value={booking ? booking.end_date : ''} required />
                          </div>
                      </div>
                  </div>

                  <div className="col">
                      <div className="form-group">
                          <label className="small_text">Total Amount * : </label>
                          <input type="number" placeholder="Total Amount" className="form-control" disabled value={booking ? booking.total_price : 0} />
                      </div>

                      <div className="form-group">
                          <label className="small_text">Balance Amount * : </label>
                          <input type="number" placeholder="Balance Amount" className="form-control" disabled value={booking ? booking.balance_amount : 0} required/>
                      </div>

                      <div className="form-group">
                          <label className="small_text">Payable Amount * : </label>
                          <input type="number" name="order_price" placeholder="Payable Amount" className="form-control" disabled={booking.balance_amount <= 0} value={orderPrice} onChange={(e) => this.handleChangeAmount(e)} required/>
                      </div>

                      <div className="form-group">
                          <label className="small_text"> Payment Type * : </label>
                          <select name="payment_type" className="form-control" value={paymentType} onChange={(e) => this.setState({paymentType: e.target.value})} required>
                              <option value=""> Select Payment Type </option>
                              <option value="0"> Offline Payment </option>
                              <option value="1"> Online Payment </option>
                          </select>
                      </div>

                      {
                          paymentType != '' && paymentType == 0 && 
                          <div className="form-group">
                              <label className="small_text"> Receipt Number : </label>
                              <input type="text" name="receipt_number" placeholder="Receipt Number" className="form-control"/>
                          </div>
                      }
                  </div>
              </div>
            
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading || (orderPrice <= 0)} > PAY Rs. {orderPrice} </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>


          {/* Display all payments history */}
          <div className="row">
              <div className="col" />
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
                  <table className="table table-responsive table-bordered table-striped">
                    <thead w="100%">
                      <tr>
                        <th className="bg-blue text-light" width="5%">Sl.No.</th>
                        <th className="bg-blue text-light" width="20%">Total Amount</th>
                        <th className="bg-blue text-light" width="20%">Paid Amount</th>
                        {/* <th className="bg-blue text-light" width="15%">Balance</th> */}
                        <th className="bg-blue text-light" width="15%">Paid Date</th>
                        {/* <th className="bg-blue text-light" width="5%">Delete</th> */}
                        <th className="bg-blue text-light" width="15%">Print</th>
                      </tr>
                    </thead>
                    <tbody>

                      {/* Display all the bookings */}
                      {booking && Object.entries(booking).length > 0 ? 
                        booking.payments.map((payment, index) => (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>{'Rs. ' + payment.total_price}</td>
                            <td>{'Rs. ' + payment.paid_price}</td>
                            {/* <td>{'Rs. ' + (payment.total_price - payment.paid_price)}</td> */}
                            <td>{payment.created_date}</td>
                            {this.renderPDFButton(payment.id)}
                            {/* <td>{this.renderPDFButton(payment.id)}</td> */}
                          </tr>
                        ))
                      : 
                        <tr><td colSpan="10" className="text-center">{'No payments history available'}</td></tr>
                      }

                    </tbody>
                  </table>
              </div>
              <div className="col" />
          </div>

          <div>
  

</div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    booking : state.booking.booking,
    hostelpdfData : state.booking.hostelpdfData
  }
};

const mapActionsRoProps = {
  onGetUserBookings : getUserBookings,
  gethostelPdf : gethostelPdf
  // onUpdateBooking : updateBooking
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditBooking);
