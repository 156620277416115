import { GET_PAGE_SUCCESS } from '../actions/Pagination';

const initialState = {
    total_count : 0,
    page_count : 0,
    page_size : 10
}

export default function Pagination_reducer (state = initialState, { type, payload }) {

    switch(type)
    {
        case GET_PAGE_SUCCESS : 
            return Object.assign({}, state, { total_count : payload.total_count, page_count : payload.page_count,  page_size : payload.page_size });
        default :
            return state;
    }
}