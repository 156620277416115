import React from 'react';
import './DeliveryCharge.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addDeliveryCharge } from '../../actions/DeliveryCharge';

import swal from 'sweetalert';



class AddDeliveryCharge extends React.Component {

  constructor(props){
    super(props);
    this.state = { images : { achiever_image_path : ''} };
    this.onAddDeliveryChargeSubmit = this.onAddDeliveryChargeSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    let images = this.state.images;
    images[e.target.name] = URL.createObjectURL(e.target.files[0]);
    this.setState({ images : images });
  }



  onAddDeliveryChargeSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new delivery charge?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_delivery_charge_form'));
        this.props.addDeliveryCharge(formData);
      }
    });
  }

  render() {

    const { images } = this.state;
    let image_preview = '';

    if(images.achiever_image_path){
      image_preview = <img src={images.achiever_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/deliveryCharge">Delivery Charge</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Delivery Charge</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Delivery Charge</h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/* Add new achiever form */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="add_delivery_charge_form" onSubmit={this.onAddDeliveryChargeSubmit} autoComplete="off">

                <div className="form-group">
                    <input type="text" name="order_value" placeholder="Order Value *" className="form-control" required/>
                </div>

                <div className="form-group">
                    <input type="number" name="delivery_charge_amount" placeholder="Delivery Charge *" className="form-control" required/>
                </div>

        
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Delivery Charge</button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {

  console.log("testingggg",state);
  return {
    isLoading : state.admin.isLoading,

  }
};

const mapActionsRoProps = {
  addDeliveryCharge : addDeliveryCharge,

};

export default connect(mapStatesToProps, mapActionsRoProps) (AddDeliveryCharge);
