import React from 'react';
import './AdminUsers.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserRoles, addNewUser } from '../../actions/Users';
import { onDisplayError } from '../../actions/Login';
import swal from 'sweetalert';
import $ from 'jquery';

class AddAdmin extends React.Component {

  constructor(props){
    super(props);
    this.state = { profile_pic: '' };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount() {
    this.props.onGetUserRoles();
  }

  onChangeHandler = (e) => {
    this.setState({ profile_pic: URL.createObjectURL(e.target.files[0]) });
  }

  onAddAdminSubmit = (e) => {
    e.preventDefault();

    if($('#password').val().length < 8)
    {
      this.props.onDisplayErroMessage('Password must have atleast 6 characters');
    }
    else if($('#password').val() !== $('#confirm_password').val())
    {
      this.props.onDisplayErroMessage('Confirm password must be same as password');
    }
    else
    {
      swal({
        title: "Are you sure?",
        text: "To add this new admin?",
        buttons: ['Cancel', 'Add'],
      })
      .then(willDelete => {
        if (willDelete) {
          var formData = new FormData(document.getElementById('add_user_form'));
          this.props.onAddNewUser(formData);
        }
      });
    }
  }

  render() {
    const { profile_pic } = this.state;
    const { roles } = this.props;

    let image_preview = '';

    if(profile_pic){
      image_preview = <img src={profile_pic} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/admins">Admins</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Admin</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Admin </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">

            </div>
          </div>
          
          {/*  Add new user form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_user_form" onSubmit={this.onAddAdminSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Full Name : </label>
                      <input type="text" name="name" placeholder="Full Name *" className="form-control" autoFocus required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID * : </label>
                      <input type="email" name="email" placeholder="Email ID *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Mobile Number * : </label>
                      <input type="number" name="mobile_number" placeholder="Mobile Number *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Date of Birth : </label>
                      <input type="date" name="dob" placeholder="Date of Birth" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Select Role * : </label>
                      <select name="user_role" className="form-control" required>
                        <option value="">Select Role</option>
                        {
                          roles && roles.length > 0 && roles.map((role) => (
                            <option key={role.id} value={role.id}> {role.role_name} </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Gender : </label>
                        <select name="gender" className="form-control" required>
                          <option value=""> Select Gender </option>
                          <option value="Male"> Male </option>
                          <option value="Female"> Female </option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status : </label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Profile Photo: </label>
                        <input type="file" name="profile_pic" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {image_preview}
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <label className="small_text">Communication Address : </label>
                      <textarea rows={5} name="communication_address" placeholder="Communication Address" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Permanent Address : </label>
                      <textarea rows={5} name="permanent_address" placeholder="Permanent Address" className="form-control"/>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="small_text"> New Password * : </label>
                        <input type="password" id="password" name="password" placeholder="Password" className="form-control" required/>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="small_text"> Confirm Password * : </label>
                        <input type="password" id="confirm_password" name="confirm_password" placeholder="Confirm Password" className="form-control" required/>
                      </div>
                    </div>

                    {/* <input type="hidden" name="user_role" value={1} /> */}
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Admin </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading : state.admin.isLoading,
        roles : state.user.roles
    }
};

const mapActionsRoProps = {
    onAddNewUser : addNewUser,
    onGetUserRoles : getUserRoles,
    onDisplayErroMessage : onDisplayError
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddAdmin);