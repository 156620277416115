import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

export const getAllRollingImages = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllImages')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetRollingImagesSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                    // dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewRollingImage = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewImage', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getRollingImageDetails = (image_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditImage/' + image_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetRollingImageSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateRollingImage = (image_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdateImage/' + image_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteRollingImage = (image_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteImage/' + image_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllRollingImages());
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetRollingImagesSuccess = (data) => {
    return {
        type : GET_IMAGES_SUCCESS,
        payload : {
            rollingImages : data
        }
    }
}

export const onGetRollingImageSuccess = (image) => {
    return {
        type : GET_IMAGE_SUCCESS,
        payload : {
            rollingImage : image
        }
    }
}
