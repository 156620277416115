import React, { useRef } from 'react';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import "./PDF.css";
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import * as moment from 'moment'
moment.locale();


const payments = {
	"0" : "Cash" ,
	"1" : "Online"
}
function AdmissionPDF (props) {
 const {pdfData} = props;
return (
    <>
		<div className='bodyformat'> 
			<div>
				<div className="pdf-header">
					<div className="header-logo  flex-column">
						<div className="logo d-flex justify-content-center mt-2 mb-1"><img src={Images.logo} /></div>
						<div className="d-flex justify-content-center "><h7>CLASSIC KAS & IAS STUDY CIRCLE</h7></div>
						<div className="d-flex justify-content-center yellowColor mb-2"><h7>KARNATAKA CLASSIC EDUCATION Pvt Ltd</h7></div>

						<div className="d-flex justify-content-center font-weight-bold"><h5>Receipt/Tax Invoice</h5></div>
					</div> 
					<hr  className="m-0"/> 
				</div>
				
				<div className="pdf-body mt-2">
					<div className="receipt-contents d-flex justify-content-between"> 
						<div className="invoice-info" style={{marginLeft : '-15px'}}>
							<span>Date : </span>
							<strong>{pdfData?.ordered_date && DateFormat(pdfData?.ordered_date, 2)}</strong>
						</div>
						<div className="text-right" style={{marginLeft : '-15px'}}>
							<div className="invoice-info">
								<span>Receipt No : </span>
								<strong>KCA{pdfData?.id}</strong>
							</div>
							<div className="invoice-info">
								<span>GST No : </span>
								<strong></strong>
							</div>
						</div>
					</div>

					<div className="mt-2 mb-2 text-left">
						<div className="invoice-info">
							<span>Full Name : </span>
							<strong>{pdfData?.name}</strong>
						</div>
						<div className="invoice-info">
							<span>Communication Address : </span>
							<strong>{pdfData?.communication_address}</strong>
						</div>
					</div>

					<div className="pdf-info-table row">
						<div className="col-12 col-sm-6 p-0">
								<div><h6>Course Details</h6>
								<table className="table table-bordered ml-auto">
								<tbody>
										<tr>
										<td className="greyColor">Course Type:</td>
										<td>{pdfData?.batch_type == '1' ? 'Online' : 'Offline'}</td>
										</tr>

										<tr>
										<td className="greyColor">Course Name:</td>
										<td>{pdfData?.course_name}</td>
										</tr>

										<tr>
										<td className="greyColor">Batch Name:</td>
										<td>{pdfData?.batch_name}</td>
										</tr>

										<tr>
										<td className="greyColor">Duration:</td>
										<td>{'From ' + moment( pdfData?.batch_start_date ).format('DD-MM-YYYY')  + ' at ' + moment( pdfData?.batch_start_date+" "+pdfData?.batch_start_time ).format('h:mm a') +' for ' + pdfData?.batch_duration}</td>
										</tr>
								</tbody>
								</table>	

								</div>
							
						</div>
						
						<div className="col-12 col-sm-6 text-right">
							<div>
								<h6 className="text-center">Collect Acadamic Fees</h6>
								<table className="table table-bordered ml-auto">
									<thead>
										<tr>
											<td className="greyColor">Fee Type</td>
											<td>Amount</td>
										</tr>
									</thead>
									<tbody>
										<tr>
										<td className="greyColor">Admission Fees</td>
											<td>{parseFloat(pdfData?.admission_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Books Fees</td>
											<td>{parseFloat(pdfData?.books_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Hostel Fees</td>
											<td>{parseFloat(pdfData?.hostel_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Meals Fees</td>
											<td>{parseFloat(pdfData?.meal_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Transport Fees</td>
											<td>{parseFloat(pdfData?.transportation_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Other Fees</td>
											<td>{parseFloat(pdfData?.other_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Total Fees</td>
										<td className="greyColor">{parseFloat(pdfData?.total_fees).toFixed(2)}</td>
										</tr>

										<tr>
											<td className="p-1 white-border"></td>
											<td className="p-1 white-border"></td>
										</tr>

										<tr>
										<td className="greyColor">CGST @ 9</td>
											<td>{parseFloat(pdfData?.total_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">SGST @ 9</td>
											<td>{parseFloat(pdfData?.total_fees).toFixed(2)}</td>
										</tr>
										<tr>
										<td className="greyColor">Total Fees</td>
										<td className="greyColor">{parseFloat(pdfData?.total_fees).toFixed(2)}</td>
										</tr>

										<tr>
										<td className="greyColor">Payment Mode</td>
										<td className="greyColor">{payments[pdfData?.payment_type]}</td>
										</tr>

									</tbody>
								</table>
							</div>
						</div>
					</div>
					<hr />
					
				</div>
			</div>
			<div>
				<div className="foot text-left">
						<div className="note m-2">
							<b>Note</b>
							<p>* Fees Once paid will not be refunded or transferred</p>
						</div>

						<div className="footer row">
							<div className="col-8">
							<h6 className="heading"> KARNATAKA CLASSIC EDUCATION Pvt Ltd </h6>
							<p className="address">The Classic KAS & IAS Study Circle Unit A,<br/>
								Akshata Arcade, Saptapur Bavi,<br/>
								Dharwad-580001 <br/>
							</p>
								
							</div>
							<div className="col-4">
							<p className="address">Email:  classicappdwd@gmail.com </p>
							<p className="address">PH: +91 9980552080, <br/> +91 9980552081</p>
							</div>
						</div>
			</div>
		</div>
		</div>
    </>
  );
}

export default AdmissionPDF;