import React from 'react';
import './Faculties.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFacultyDetails, updateFaculty } from '../../actions/Faculties';
import swal from 'sweetalert';

class Faculties extends React.Component {
  constructor(props){
    super(props);
    this.state = { faculty_id : this.props.match.params.faculty_id, images : { faculty_image : '' } };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetFacultyDetails(this.state.faculty_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let faculty = this.props.faculty;
    let images = this.state.images;
    faculty[name] = value;

    if(e.target.name === 'faculty_image'){
      images[name] = URL.createObjectURL(e.target.files[0]);
      faculty[name] = '';
    }

    this.setState({ faculty : faculty, images : images });
  }

  onUpdateFacultySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this faculty?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_faculty_form'));
        this.props.onUpdateFaculty(this.state.faculty_id, formData);
      }
    });
  }

  render() {

    const { faculty, base_url } = this.props;
    const { images } = this.state;
    let image_preview = '';

    if(faculty && faculty.faculty_image){
      image_preview = <img src={base_url + faculty.faculty_image} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.faculty_image){
      image_preview = <img src={images.faculty_image} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/faculties">Faculties</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Faculty</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Faculty </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/faculties/add_faculty"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewFaculty}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit faculty form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
            <br/>

              <form id="edit_faculty_form" onSubmit={this.onUpdateFacultySubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Full Name * : </label>
                      <input type="text" name="faculty_name" placeholder="Full Name *" value={faculty.faculty_name ? faculty.faculty_name : ''} onChange={this.onChangeHandler} className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Mobile Number * : </label>
                      <input type="text" name="faculty_mobile" placeholder="Mobile Number *" value={faculty.faculty_mobile ? faculty.faculty_mobile : ''} onChange={this.onChangeHandler} className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Email ID * : </label>
                      <input type="email" name="faculty_email" placeholder="Email ID *" value={faculty.faculty_email ? faculty.faculty_email : ''} onChange={this.onChangeHandler} className="form-control" required/>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                      <label className="small_text">Faculty Photo * : </label>
                        <input type="file" name="faculty_image" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {image_preview}
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Faculty Details : </label>
                      <textarea rows={5} name="faculty_details" placeholder="Faculty Details *" value={faculty.faculty_details ? faculty.faculty_details : ''} onChange={this.onChangeHandler} className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Status * : </label>
                      <select name="status" className="form-control" value={faculty.status ? faculty.status : 1} onChange={this.onChangeHandler} required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr/>
                
                <div className="form-group text-center mt-2">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Faculty </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    faculty : state.faculty.faculty,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  onGetFacultyDetails : getFacultyDetails,
  onUpdateFaculty : updateFaculty
};

export default connect(mapStatesToProps, mapActionsRoProps) (Faculties);
