import server from '../utils/Server';
import SetAuthToken from '../utils/SetAuthToken';

export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCESS';
export const ADMIN_LOGOUT_FAILURE = 'ADMIN_LOGOUT_FAILURE';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const onAdminLogin = ( formData ) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/AdminLogin', formData)
        .then(response => {
            dispatch(onLoadingFalse());
            if(response.data.statuscode === 200)
            {
                dispatch(onLoginSuccess(response.data.message));
                dispatch(setCurrentUser(response.data.data));
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('adminInfo', JSON.stringify(response.data.data));
                SetAuthToken(response.data.data.token);
            }
            else
            {
                dispatch(onLoginFaiure(response.data.message));
            }
        })
        .catch(error => {
            dispatch(onLoadingFalse());
            dispatch(onLoginFaiure('Failed, Try again later'));
        });
    };
};

export const setCurrentUser = (adminInfo) => {
    return {
        type : SET_CURRENT_USER,
        payload : {
            adminInfo : adminInfo
        }
    }
}

export const onLoginSuccess = (message) => {
    return {
        type : ADMIN_LOGIN_SUCCESS,
        payload : {
            message : message
        }
    }
}

export const onLoginFaiure = (error) => {
    return {
        type : ADMIN_LOGIN_FAILURE,
        payload : {
            error : error
        }
    }
}

export const onLoadingTrue = () => {
    return {
        type : LOADING_TRUE
    }
}

export const onLoadingFalse = () => {
    return {
        type : LOADING_FALSE
    }
}

export const onDisplayMessage = (message) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            message : message
        }
    }
}

export const onDisplayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : {
            error : error,
        }
    }
}

export const hideMessage = () => {
    return {
        type : HIDE_MESSAGE
    }
}

export const onAdminLogout = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
    
        // return server.post('/Logout', null)
        // .then(response => {
        //     dispatch(onLoadingFalse());
        //     if(response.data.statuscode === 200)
        //     {
                dispatch(onLogoutSuccess('Successfully logged out'));
                localStorage.removeItem('token');
                localStorage.removeItem('adminInfo');
                SetAuthToken('');
        //     }
        //     else
        //     {
        //         dispatch(onLogoutFailure('Failed, Try again later'));
        //     }
        // })
        // .catch(error => {
            dispatch(onLoadingFalse());
        //     dispatch(onLogoutFailure('Failed, Try again later'));
        // });
    }
}

export const updateProfile = (admin_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/ProfileUpdate/' + admin_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                    dispatch(setCurrentUser(response.data.data));
                    localStorage.setItem('adminInfo', JSON.stringify(response.data.data));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'));
            })
    }
}

export const changePassword = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdatePassword/', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'));
            })
    }
}

export const sendNotifications = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/AddNewNotification', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    console.log("testing11:",response);
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {   console.log("testing22:",response);
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'));
            })
    }
}

export const onLogoutSuccess = (message) => {
    return {
        type : ADMIN_LOGOUT_SUCCESS,
        payload : {
            message : message
        }
    }
}

export const onLogoutFailure = (error) => {
    return {
        type : ADMIN_LOGOUT_FAILURE,
        payload : {
            error : error,
        }
    }
}
