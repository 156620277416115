import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_FAILED_ORDERS_SUCCESS = 'GET_FAILED_ORDERS_SUCCESS';
export const GET_FAILED_ORDER_SUCCESS = 'GET_FAILED_ORDER_SUCCESS';
//export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
//export const GET_ADMISSION_PDF_SUCCESS = 'GET_ADMISSION_PDF_SUCCESS';

export const getFailedOrders = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getFailedOrders' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetFailedOrdersSuccess(response.data.data));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getFailedOrdersDetail = ( transaction_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/getFailedOrderDetail/' + transaction_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetFailedOrderSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addFaildOrder = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/addFaildOrder', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


/*
export const getBatchPayments = (userID, batchID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetBatchPayments/' + userID + '/' + batchID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetPaymentsSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewAdmission = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AdminPaymentSuccess', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                    window.location.reload();
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};





export const deleteAdmission = (type, admission_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteAdmission/' + admission_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllAdmissions(type, ''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const adminssionPdf = (order_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/getAdmissionDetail/' + order_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    console.log("testing",response.data.data[0]);
                    dispatch(onGetPdfDataSuccess(response.data.data[0]));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};*/

export const onGetFailedOrdersSuccess = (orders) => {
    return {
        type : GET_FAILED_ORDERS_SUCCESS,
        payload : {
            orders : orders
        }
    }
}


export const onGetFailedOrderSuccess = (order) => {
    return {
        type : GET_FAILED_ORDER_SUCCESS,
        payload : {
            order : order
        }
    }
}
/*
export const onGetPaymentsSuccess = (payments) => {
    return {
        type : GET_PAYMENTS_SUCCESS,
        payload : {
            payments : payments
        }
    }
}


export const onGetPdfDataSuccess = (admissionPdfData) => {
    return {
        type : GET_ADMISSION_PDF_SUCCESS,
        payload : {
            admissionPdfData : admissionPdfData
        }
    }
}*/