import { GET_WRITTEN_EXAMS_SUCCESS, GET_WRITTEN_EXAM_SUCCESS } from '../actions/WrittenExam';

const initialState = {

    writtenExams : [],
    writtenExam : {}

}

export default function Gallery_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_WRITTEN_EXAM_SUCCESS :
            return Object.assign({}, state, { writtenExam : payload.writtenExam });
            
        case GET_WRITTEN_EXAMS_SUCCESS :
            return Object.assign({}, state, { writtenExams : payload.writtenExam });

        default :
            return state;

    }
}
