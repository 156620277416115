import React from 'react';
import './Subscription.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addSubscription } from '../../actions/Subscription';
import { getBookDetails } from '../../actions/Books';

import swal from 'sweetalert';

class AddSubscription extends React.Component {
  constructor(props){
    super(props);
    const {params} = props.match;

    this.state = { book_id : params.book_id, images : { book_image_path : ''} };

    this.onAddSubscriptionSubmit = this.onAddSubscriptionSubmit.bind(this);
  }


  componentWillMount () {
    this.props.onGetBookDetails(this.state.book_id);
  }


  onDurationChangeHandler = (e) => {

    const { book } = this.props;
    var duration = document.getElementById('subscription_duration').value;
    var unitPrice = book.book_price;
    var actualValue = parseFloat(duration) * parseFloat(unitPrice);
    document.getElementById('subscription_actual_value').value = Math.round(actualValue); 
  }

  onAddSubscriptionSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new book?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_subscription_form'));
        this.props.addSubscription(formData);
      }
    });
  }

  render() {

    const { book_id, images, bookType } = this.state;
    const { book } = this.props;

    let image_preview = '';

    if(images.book_image_path){
      image_preview = <img src={images.book_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/books">Books</Link> </li>
                  <li className="breadcrumb-item"><Link to={"/subscription/" + book_id}>Subscription</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Subscription</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Subscription </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new book form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_subscription_form" onSubmit={this.onAddSubscriptionSubmit} autoComplete="off">

                <div className="row">
                  <div className="col">

                    <div className="form-group">
                      <div>
                      <label className="small_text">Book name * : </label>
                      </div>
                      <input type="text" name="book_name" placeholder="Book Name *" className="form-control" value={book.book_name ? book.book_name : ''} disabled/>
                  
                    </div>

                     <div className="form-group">
                      <div>
                        <label className="small_text">Book Unit Price in rupees * : </label>

                      <input type="text" name="book_price" placeholder="Book Unit Price *" className="form-control" value={book.book_price ? book.book_price : ''} disabled/>
                       </div>
                    </div>

              

                    <div className="form-group">
                    <div>
                        <label className="small_text">Subscription type * : </label>
                      </div>
                      <select name="subscription_type" id="subscription_type" className="form-control" required>
                      
                        <option value="0"> Select Subscription type </option>

                        <option value="Week"> Week </option>
                        <option value="Month"> Month </option>
                        <option value="QuarterYear"> Quarter Year </option>
                        <option value="HalfYear"> Half Year </option>
                        <option value="Year"> Year </option>

                      </select>

                    </div>

                     <div className="form-group">
                     <div>
                        <label className="small_text">Subscription duration * : </label>
                      </div>
                      <input type="text" name="subscription_duration" id="subscription_duration" placeholder="Subscription duration *" className="form-control" onChange={this.onDurationChangeHandler}  required/>
                    </div>

                    <div className="form-group">
                    <div>
                        <label className="small_text">Subscription actual price * : </label>
                      </div>
                      <input type="text" name="subscription_actual_value" id="subscription_actual_value" placeholder="Subscription actual price *" className="form-control" required/>
                    </div>


                    <div className="form-group">

                    <div>
                        <label className="small_text">Subscription offer price * : </label>
                      </div>
                      <input type="text" name="subscription_value" placeholder="Subscription offer price *" className="form-control" required/>
                    </div>

                  <input type="hidden" name="subscription_book" className="form-control" value={book.id}/>

          
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Subscription </button>
                </div>

                </div>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
    book : state.book.book,

  }
};

const mapActionsRoProps = {
  onGetBookDetails : getBookDetails,
  addSubscription : addSubscription
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddSubscription);
