import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCourseDetails, updateCourse } from '../../actions/Courses';
import swal from 'sweetalert';

class EditCourse extends React.Component {
  constructor(props){
    super(props);
    const { course } = this.props;
    this.state = { course : course, course_id : this.props.match.params.course_id, images : { course_icon_path : ''}};
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetCourseDetails(this.state.course_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let course = this.props.course;
    let images = this.state.images;
    course[name] = value;

    if(e.target.name === 'course_icon_path'){
      images['course_icon_path'] = URL.createObjectURL(e.target.files[0]);
      course[name] = '';
    }

    this.setState({ course : course, images : images });
  }

  onUpdateCourseSubmit = (e) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "To update this course?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_course_form'));

        this.props.onUpdateCourse(this.state.course_id, formData);
      }
    });
  }

  render() {

    const { course, base_url } = this.props;
    const {images} = this.state;

    let iconPreview = '';

    if(course && course.course_icon_path){
      iconPreview = <img src={base_url + course.course_icon_path} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.course_icon_path){
      iconPreview = <img src={images.course_icon_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/courses">Courses</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Course</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Course </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/courses/add_course"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit course form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_course_form" onSubmit={this.onUpdateCourseSubmit} autoComplete="off">
              <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Course name : </label>
                      <input type="text" name="course_name" placeholder="Course Name" className="form-control" value={course.course_name ? course.course_name : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Description * : </label>
                      <textarea rows={8} name="course_discription" placeholder="Course Description" className="form-control" value={course.course_discription ? course.course_discription : ''} onChange={this.onChangeHandler} />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Syllabus * : </label>
                      <textarea rows={8} name="course_syllabus" placeholder="Course Syllabus" className="form-control" value={course.course_syllabus ? course.course_syllabus : ''} onChange={this.onChangeHandler} />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Instructions * : </label>
                      <textarea rows={10} name="course_instructions" placeholder="Course Instructions" className="form-control" value={course.course_instructions ? course.course_instructions : ''} onChange={this.onChangeHandler} />
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Duration : </label>
                        <input type="text" name="course_duration" placeholder="Course Duration" className="form-control" value={course.course_duration ? course.course_duration : ''} onChange={this.onChangeHandler} required/>
                      </div>
                      <div className="col">
                        <label className="small_text">Order number : </label>
                        <input type="number" name="order_number" placeholder="Course Order Number" className="form-control" value={course.order_number ? course.order_number : ''} onChange={this.onChangeHandler} required/>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Course Type : </label>
                        <select name="course_type" value={course.course_type ? course.course_type : ''} onChange={this.onChangeHandler} className="form-control">
                          <option value="1"> Online </option>
                          <option value="0"> Offline </option>
                        </select>
                      </div>
                      <div className="col">
                        <label className="small_text">Status : </label>
                        <select name="status" value={course.status ? course.status : ''} onChange={this.onChangeHandler} className="form-control">
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Course Icon : </label>
                        <input type="file" name="course_icon_path" placeholder="Course Icon" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {iconPreview}
                      </div>
                    </div>
                  </div>
                </div>
            
                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Course </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    course : state.course.course,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  onGetCourseDetails : getCourseDetails,
  onUpdateCourse : updateCourse
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditCourse);
