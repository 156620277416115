import React from 'react';
import './Subscription.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSubscriptionDetails, updateSubscription } from '../../actions/Subscription';
import { getBookDetails } from '../../actions/Books';

import swal from 'sweetalert';

class EditSubscription extends React.Component {
  constructor(props){
    super(props);
    this.state = { book_id : this.props.match.params.book_id, subscription_id : this.props.match.params.subscription_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {

    this.props.onGetBookDetails(this.state.book_id);
    this.props.getSubscriptionDetails(this.state.subscription_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let subscription = this.props.subscription;
    subscription[name] = value;

    this.setState({ subscription : subscription});
  }

  onUpdateSubscriptionSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this subscription?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_subscription_form'));
        this.props.updateSubscription(this.state.subscription_id, formData);
      }
    });
  }

  render() {

    const {book_id, subscription_id} = this.state;
    const { book, subscription } = this.props;

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/books">Books</Link> </li>
                  <li className="breadcrumb-item"><Link to={"/subscription/" + book_id}>Subscription</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Subscription</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Subscription </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/books/add_book"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewBook}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit Subscription form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_subscription_form" onSubmit={this.onUpdateSubscriptionSubmit} autoComplete="off">

                <div className="row">
                  <div className="col">

                    <div className="form-group">
                      <div>
                      <label className="small_text">Book name * : </label>
                      </div>
                      <input type="text" name="book_name" placeholder="Book Name *" className="form-control" value={book.book_name ? book.book_name : ''} disabled/>
                  
                    </div>

                     <div className="form-group">
                      <div>
                        <label className="small_text">Book Unit Price in rupees * : </label>

                      <input type="text" name="book_price" placeholder="Book Unit Price *" className="form-control" value={book.book_price ? book.book_price : ''} disabled/>
                       </div>
                    </div>

              

                    <div className="form-group">
                    <div>
                        <label className="small_text">Subscription type * : </label>
                      </div>
                      <select name="subscription_type" id="subscription_type" className="form-control" value={subscription.subscription_type ? subscription.subscription_type : ''} onChange={this.onChangeHandler} required>

                        <option value="0"> Select Subscription type </option>

                        <option value="Week"> Week </option>
                        <option value="Month"> Month </option>
                        <option value="QuarterYear"> Quarter Year </option>
                        <option value="HalfYear"> Half Year </option>
                        <option value="Year"> Year </option>

                      </select>

                    </div>

                     <div className="form-group">
                     <div>
                        <label className="small_text">Subscription duration * : </label>
                      </div>
                      <input type="text" name="subscription_duration" id="subscription_duration" placeholder="Subscription duration *" className="form-control" value={subscription.subscription_duration ? subscription.subscription_duration : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                    <div>
                        <label className="small_text">Subscription actual price * : </label>
                      </div>
                      <input type="text" name="subscription_actual_value" id="subscription_actual_value" placeholder="Subscription actual price *" className="form-control" value={subscription.subscription_duration * book.book_price} onChange={this.onChangeHandler} required/>
                    </div>


                    <div className="form-group">

                    <div>
                        <label className="small_text">Subscription offer price * : </label>
                      </div>
                      <input type="text" name="subscription_value" placeholder="Subscription offer price *" className="form-control" value={subscription.subscription_value ? subscription.subscription_value : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                    <div>
                        <label className="small_text">Subscription status * : </label>
                      </div>
                      <select name="subscription_status" id="subscription_status" className="form-control" value={subscription.subscription_status} onChange={this.onChangeHandler} required>

                        <option value="0"> Select status </option>

                        <option value="Active"> Active </option>
                        <option value="InActive"> In Active </option>
                      </select>

                    </div> 

                  <input type="hidden" name="subscription_book" className="form-control" value={book.id}/>

          



                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Subscription </button>
                </div>

                </div>
                </div>





              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    subscription : state.subscription.subscription,
    book : state.book.book,
  }
};

const mapActionsRoProps = {
  getSubscriptionDetails : getSubscriptionDetails,
  onGetBookDetails : getBookDetails,
  updateSubscription : updateSubscription
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditSubscription);
