import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';

export const getAllUsers = (type, params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllUsers/' + type + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetUsersSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getUser = (body) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/getUser', body, {
            headers: {
              'Content-Type': 'application/json'
            }})
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetUserListSuccess(response.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const checkUserExists = (mobileNumber) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/AdminUserExists?mobile_number=' + mobileNumber)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onDisplayError(response.data.message));
                }
                else {
                    dispatch(onDisplayMessage(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getUserRoles = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllRoles')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetRolesSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message))
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'))
            });
    }
}

export const addNewUser = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewUser', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getUserDetails = (user_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditUser/' + user_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetUserSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateUser = (user_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateUser/' + user_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const changeUserPassword = (user_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdateUserPassword/' + user_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteUser = (type, user_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteUser/' + user_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllUsers(type, ''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetUsersSuccess = (users) => {
    return {
        type : GET_USERS_SUCCESS,
        payload : {
            users : users
        }
    }
}

export const onGetUserListSuccess = (users) => {
    return {
        type : GET_USERLIST_SUCCESS,
        payload : {
            users : users
        }
    }
}

export const onGetUserSuccess = (user) => {
    return {
        type : GET_USER_SUCCESS,
        payload : {
            user : user
        }
    }
}

export const onGetRolesSuccess = (roles) => {
    return {
        type: GET_ROLES_SUCCESS,
        payload: {
            roles: roles
        }
    }
}
