import React from 'react';
import './GalleryCategory.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getGalleryCategoryDetail, updateGalleryCategory } from '../../actions/GalleryCategory';
import swal from 'sweetalert';

class EditGalleryCategory extends React.Component {
  constructor(props){
    super(props);
    this.state = { gallery_category_id : this.props.match.params.gallery_category_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.getGalleryCategoryDetail(this.state.gallery_category_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let galleryCategory = this.props.galleryCategory;
    //let images = this.state.images;
    galleryCategory[name] = value;

    this.setState({ galleryCategory : galleryCategory });
  }

  onUpdateCategorySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this achiever?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_achiever_form'));
        this.props.updateGalleryCategory(this.state.gallery_category_id, formData);
      }
    });
  }

  render() {

    const { galleryCategory, base_url } = this.props;
    
    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/galleryCategory">Galley Category</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Gallery Category</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Gallery Category </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/galleryCategory/add_gallery_category"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewAchiever}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit achiever form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="edit_achiever_form" onSubmit={this.onUpdateCategorySubmit} autoComplete="off">

                <div className="form-group">
                  <label className="small_text">Gallery Category Name * : </label>
                  <input type="text" name="gallery_category_name" placeholder="Gallery Category Name *" value={galleryCategory.gallery_category_name ? galleryCategory.gallery_category_name : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Display Order * : </label>
                  <input type="number" name="gallery_category_order" placeholder="Display order *" value={galleryCategory.gallery_category_order} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Status * : </label>
                  <select name="gallery_category_status" className="form-control" value={galleryCategory.gallery_category_status} onChange={this.onChangeHandler} required>
                    <option value="Active"> Active </option>
                    <option value="InActive"> In Active </option>
                  </select>
                </div>
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Achiever </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {

  console.log("jjjjj",state);
  return {
    isLoading : state.admin.isLoading, 
    galleryCategory : state.galleryCategory.galleryCategory,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  getGalleryCategoryDetail : getGalleryCategoryDetail,
  updateGalleryCategory : updateGalleryCategory
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditGalleryCategory);
