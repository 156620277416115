import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewHostel, getDropDownHostels } from '../../actions/Hostels';
import { onDisplayMessage, onDisplayError, onLoadingTrue, onLoadingFalse } from '../../actions/Login';
import swal from 'sweetalert';
import Server from '../../utils/Server';
import AppConstants from '../../assets/constants/';
import Images from '../../assets/images/';
import moment from 'moment';
import { getUserBookings } from '../../actions/HostelBookings';
import { getAllUsers } from '../../actions/Users';


class AddBooking extends React.Component {
    constructor(props){
        super(props);
        this.state = {months: 1, paymentType: 1, userData: {name: '', email: '', mobile_number: ''}, hostelData: {}, hostelID: '', totalAmount: 0, orderPrice: 0, totalMonths: 0, balanceAmount: 0, error: '', message: '', blockBooking: false };
    }

    componentDidMount() {
        this.props.onGetUsers(1, '');
        this.props.onGetHostels();
    }

    handleCheckExists = (e) => {
        const mobileNumber  = e.target.value;
        this.setState({userData: {name: '', email: '', mobile_number: mobileNumber}, userID: 0});

        if(mobileNumber.length !== 10) {
            this.setState({message: '', error: 'Enter valid 10 digit mobile number'});
        } else {
            this.setState({message: '', error: ''});
            this.props.onLoadingTrue();
            Server.get('/GetUserHostelStatus?mobile_number=' + mobileNumber)
                .then(response => {
                    this.props.onLoadingFalse();
                    const {data, message} = response.data;
                    this.props.onDisplayError(message);
                    this.setState({error: message});

                    if(data && Object.entries(data).length > 0) {
                        this.setState({userData: data, userID: data.id});
                    }
                })
            .catch(error => {
                this.props.onLoadingFalse();
                this.props.onDisplayError('Failed, Try again later');
            });
        }
    }

    onChangeHandler = (type, event) => {
        const {users, hostels} = this.props;
        const {userData, hostelData} = this.state;
        const {name, value} = event.target;
        
        if(type === 0) {
            // if(value.length !== 10) {
            //     this.setState({userData: {name: '', email: '', mobile_number: value}, userID: 0});
            // } else {
                
            
            // }
            // if(value.length !== 10) {
            //     this.setState({userData: {name: '', email: '', mobile_number: value}, userID: 0});
            // } else {
            //     if(users && users.length > 0) {
            //         var userExists = users.findIndex(x => x.mobile_number == value);

            //         // If already user exists else add as new user
            //         if(userExists >= 0) {
            //             this.setState({userData: users[userExists], userID: users[userExists].id});
            //         } else {
            //             userData[name] = value;
            //             this.setState({userData: userData, userID: 0});
            //         }
            //     } else {
            //         userData[name] = value;
            //         this.setState({userData: userData, userID: 0});
            //     }
            // }
        } else if(type === 1) {
            userData[name] = value;
            this.setState({userData: userData, userID: 0});
        } 
        // Get hostel data
        else if(type === 2) {
            if(hostels && hostels.length > 0) {
                var hostelExists = hostels.findIndex(x => x.id == value);

                if(hostelExists >= 0) {
                    this.setState({hostelData: hostels[hostelExists]});
                    // console.log('Hostel exists', hostels[hostelExists]);
                } else {
                    this.setState({hostelData: {}});
                }
            }
            this.setState({hostelID: value});

        } else if(type === 3) {
            if(hostelData && Object.entries(hostelData).length > 0) {
                var totalMonths = parseInt(value);
                var monthlyPrice = parseInt(hostelData.hostel_price);
                var totalAmount = (totalMonths * monthlyPrice);
                this.setState({totalMonths: totalMonths, totalAmount: totalAmount});
            }
        } else if(type === 4) {
            if(this.state.totalAmount > 0 && value > 0) {
                var balanceAmount = (parseInt(this.state.totalAmount) - parseInt(value));
                this.setState({orderPrice: parseInt(value), balanceAmount: balanceAmount});
            }
        } else if(type === 5) {
            this.setState({paymentType: value});
        }
        
    }

    handleGetUserBooking = (mobileNumber) => {
        this.props.onGetUserBookings(mobileNumber);
    }

    onNewBookingSubmit = (e) => {
        e.preventDefault();

        const {paymentType, userData, totalAmount, orderPrice} = this.state;
        var formData = new FormData(document.getElementById('add_hostel_form'));
        formData.append('booking_id', ''); // Attach booking ID

        // Offline payment
        if(paymentType !== '' && parseInt(paymentType) === 0) {
            swal({
                title: "Are you sure?",
                text: "To book this hostel for this new user?",
                buttons: ['Cancel', 'BOOK'],
            })
            .then(willDelete => {
                if (willDelete) {
                    formData.append('payment_type', 1);

                    Server.post('/HostelPaymentSuccess', formData)
                        .then(response => {
                            if(response.data.statuscode === 200) {
                                swal({ title :'Thank you', text : response.data.message, icon : 'success'});
                                setTimeout(() => window.location.hash = '#/hostel_bookings', 2000);
                            } 
                        })
                        .catch(error => {
                            swal('OOPS!...', error.message);
                        });
                }
            });
        } 
        // Online payment through razorpay
        else {
            // Payment gateway if payment type is online payment
            formData.append('total_price', totalAmount); //totalAmount from states should be included
            formData.append('order_price', 1); // //orderPrice from states should be included

            swal({
                title: "Are you sure?",
                text: "To book this hostel for this new user?",
                buttons: ['Cancel', 'BOOK'],
            })
            .then(willDelete => {
                if (willDelete) {
                    Server.post('/AdminSaveCheckout', formData)
                    .then(response => {
                        if(response.data.statuscode === 200) {
                            // Initialize payment
                            var options = {
                                "key": AppConstants.RAZORPAY_API_KEY,
                                // "amount": (totalAmount * 100),
                                "amount": 1,
                                "name": 'Hostel Booking',
                                "description": 'Classic Education Dharwad',
                                "image": Images.logo,
                                "order_id" : response.data.rzpay_order_id,
                                "currency": 'INR',
                                "theme" : {
                                "color": "#44a1a0"
                                },
                                "handler": function (response){
                                    if(response && response.razorpay_payment_id !== '')
                                    {
                                        // Make payment success in server

                                        formData.append('rzpay_order_id', response.razorpay_order_id);
                                        formData.append('rzpay_payment_id', response.razorpay_payment_id);
                                        formData.append('rzpay_signature', response.razorpay_signature);
                                        formData.append('payment_type', 1);

                                        Server.post('/HostelPaymentSuccess', formData)
                                            .then(response => {
                                                if(response.data.statuscode === 200) {
                                                    swal({ title :'Thank you', text : response.data.message, icon : 'success'});
                                                    setTimeout(() => window.location.hash = '#/hostel_bookings', 2000);
                                                } 
                                            })
                                            .catch(error => {
                                                swal('OOPS!...', error.message);
                                            });
                                    }
                                },
                                "prefill" : {
                                "contact" : userData.mobile_number,
                                "email" : userData.email
                                }
                            }
                
                            let rzp = new window.Razorpay(options); rzp.open();
                        }
                    })
                    .catch(error => {
                        swal('OOPS!...', error.message);
                    });
                }
            });
        }
    }

    handleDateChange = (hostel_price) => {
        var startDate = document.getElementById('start_date').value;
        var endDate = document.getElementById('end_date').value;

        startDate = moment(new Date(startDate));
        endDate = moment(new Date(endDate));

        var diffMonths = (endDate.diff(startDate, 'months') + 1);
        var monthlyPrice = parseInt(hostel_price);
        var totalAmount = (diffMonths * monthlyPrice);
        this.setState({totalMonths: diffMonths, totalAmount: totalAmount});
    }

  render() {

    const {months, userData, paymentType, hostelData, hostelID, totalAmount, orderPrice, totalMonths, balanceAmount, error, message, blockBooking} = this.state;
    const {hostels, bookings} = this.props;


    return (
        <div className="row">
            <div className="col">
                {/* Breadcrumbs and add button container */}
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <nav area-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                                <li className="breadcrumb-item"><Link to="/hostel_bookings">Hostel Bookings</Link> </li>
                                <li className="breadcrumb-item active" area-current="page">New Booking</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <h4 className="text-center page_header"> New Booking </h4>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right" />
                </div>
                    
                {/*  Add new hostel form  */}
                <div className="row add_container form_container">
                    <div className="col"></div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">

                        <form id="add_hostel_form" onSubmit={this.onNewBookingSubmit} autoComplete="off">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="small_text">Mobile Number * : </label>
                                            <input type="number" name="mobile_number" placeholder="Mobile Number" className="form-control" onBlur={(e) => this.handleCheckExists(e)} required />
                                            {error !== '' ? <span className="small_text text-danger">{error}</span> : ''}
                                            {message !== '' ? <span className="small_text text-success">{message}</span> : ''}
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">Student Name * : </label>
                                            <input type="text" placeholder="Student Name" className="form-control" disabled={true} value={userData && userData.name  ? userData.name : ''} onChange={(e) => this.onChangeHandler(1, e)} />
                                            <input type="hidden" name="name" value={userData && userData.name  ? userData.name : ''} />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col">
                                                <label className="small_text"> Hostel * : </label>
                                                <select className="form-control" name="id" value={hostelID !== '' ? hostelID : ''} onChange={(e) => this.onChangeHandler(2, e)} required>
                                                    <option value=""> Select hostel </option>

                                                    {
                                                        hostels && hostels.length > 0 && 
                                                            hostels.map((hostel, index) => (
                                                                <option key={index} value={hostel.id}>{hostel.hostel_name}</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="col">
                                                <div className="row form-group">
                                                    <div className="col">
                                                        <label className="small_text">Total Beds : </label>
                                                        <input type="text" placeholder="Total Beds" className="form-control" disabled defaultValue={hostelData ? hostelData.hostel_capacity : 0} required/>
                                                    </div>
                                                    <div className="col">
                                                        <label className="small_text">Available Beds : </label>
                                                        <input type="text" placeholder="Available Beds" className="form-control" disabled defaultValue={hostelData ? hostelData.available_beds : 0} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col">
                                                <label className="small_text">Joining Date : </label>
                                                <input type="date" name="start_date" id="start_date" className="form-control" onChange={() => this.handleDateChange(hostelData ? hostelData.hostel_price : 0)} required />
                                            </div>
                                            <div className="col">
                                                <label className="small_text">Relieving Date * : </label>
                                                <input type="date" name="end_date" id="end_date" className="form-control" onChange={() => this.handleDateChange(hostelData ? hostelData.hostel_price : 0)} required />
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col">
                                                <label className="small_text">Total Months * : </label>
                                                <input type="number" className="form-control" placeholder="Total Months" disabled={true} value={totalMonths > 0 ? totalMonths : 0} required />
                                                <input type="hidden" name="no_of_months" value={totalMonths > 0 ? totalMonths : 0} />
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="small_text">Price Per Month * : </label>
                                                    <input type="number" placeholder="Price Per Month" className="form-control" defaultValue={hostelData ? hostelData.hostel_price : 0} disabled/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group">
                                            <label className="small_text">Total Amount * : </label>
                                            <input type="number" placeholder="Total Amount" className="form-control" disabled value={totalAmount > 0 ? totalAmount : 0} required/>
                                            <input type="hidden" name="total_price" defaultValue={totalAmount} />
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">Payable Amount * : </label>
                                            <input type="number" name="order_price" placeholder="Payable Amount" className="form-control" value={orderPrice > 0 ? orderPrice : 0} onChange={(e) => this.onChangeHandler(4, e)} required/>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">Balance Amount * : </label>
                                            <input type="number" placeholder="Payable Amount" className="form-control" disabled value={balanceAmount > 0 ? balanceAmount : 0} required/>
                                        </div>

                                        {/* <div className="row form-group">
                                            <div className="col">
                                                <label className="small_text">Total Amount * : </label>
                                                <input type="number" name="total_price" placeholder="Total Amount" className="form-control" readOnly value={bookings.length > 0 ? bookings[0].total_price : 0} required/>
                                            </div>
                                            <div className="col">
                                                <label className="small_text">Balance Amount * : </label>
                                                <input type="number" placeholder="Balance Amount" className="form-control" disabled defaultValue={bookings.length > 0 ? bookings[0].balance_amount : 0} required/>
                                            </div>
                                        </div> */}

                                        <div className="form-group">
                                            <label className="small_text"> Payment Type * : </label>
                                            <select name="payment_type" className="form-control" value={paymentType ? paymentType : ''} onChange={(e) => this.onChangeHandler(5, e)} required>
                                                <option value=""> Select Payment Type </option>
                                                <option value="0"> Offline Payment </option>
                                                <option value="1"> Online Payment </option>
                                            </select>
                                        </div>

                                        {
                                            paymentType != '' && paymentType == 0 && 
                                            <div className="form-group">
                                                <label className="small_text"> Receipt Number : </label>
                                                <input type="text" name="receipt_number" placeholder="Receipt Number" className="form-control"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            
                                <div className="form-group text-center mt-3">
                                    <button type="submit" className="btn btn-success" disabled={blockBooking} > Book Hostel </button> 
                                </div>
                            </form>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        isLoading : state.admin.isLoading,
        users: state.user.users,
        hostels: state.hostel.hostels,
        bookings: state.booking.bookings,
    }
};

const mapActionsRoProps = {
    onGetUsers: getAllUsers,
    onGetHostels: getDropDownHostels,
    onAddNewHostel : addNewHostel,
    onGetUserBookings: getUserBookings,
    onDisplayMessage: onDisplayMessage,
    onDisplayError: onDisplayError,
    onLoadingTrue: onLoadingTrue,
    onLoadingFalse: onLoadingFalse
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddBooking);

