import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_WRITTEN_EXAMS_SUCCESS = 'GET_WRITTEN_EXAMS_SUCCESS';
export const GET_WRITTEN_EXAM_SUCCESS = 'GET_WRITTEN_EXAM_SUCCESS';

export const getAlltWrittenExam = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getAlltWrittenExam' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetWrittenExamsSuccess(response.data.data));

                    console.log("testing999", response.data);
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addWrittenExam = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/addWrittenExam', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {

                    console.log("testing111", response);
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {    console.log("testing222", response);
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));

                console.log("testingmessage", error.message());


            });
    };
};



export const getWrittenExamDetail = (achiever_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getWrittenExamDetail/' + achiever_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetWrittenExamSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateWrittenExam = (gallary_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/updateWrittenExam/' + gallary_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteWrittenExam = (gallery_image_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/deleteWrittenExam/' + gallery_image_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAlltWrittenExam(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetWrittenExamsSuccess = (data) => {
    return {
        type : GET_WRITTEN_EXAMS_SUCCESS,
        payload : {
            writtenExam : data
        }
    }
}

export const onGetWrittenExamSuccess = (data) => {
    return {
        type : GET_WRITTEN_EXAM_SUCCESS,
        payload : {
            writtenExam : data
        }
    }
}


