import { GET_VIDEOS_SUCCESS, GET_VIDEO_SUCCESS } from '../actions/Videos';

const initialState = {
    videos : [],
    video : {}
}

export default function Video_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_VIDEO_SUCCESS :
            return Object.assign({}, state, { video : payload.video });

        case GET_VIDEOS_SUCCESS :
            return Object.assign({}, state, { videos : payload.videos });

        default :
            return state;
    }
}
