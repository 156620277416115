import React from 'react';
import './Exams.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getExamDetails, updateExam } from '../../actions/Exams';
import { getDropDownCourses } from '../../actions/Courses';
import swal from 'sweetalert';

class Exams extends React.Component {
  constructor(props){
    super(props);
    this.state = { exam_id : this.props.match.params.exam_id,  };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetDropDownCourses();
    this.props.onGetExamDetails(this.state.exam_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let exam = this.props.exam;
    exam[name] = value;
    this.setState({ exam : exam });
  }

  onUpdateExamSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
	  text: "To update this exam?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_exam_form'));
        this.props.onUpdateExam(this.state.exam_id, formData);
      }
    });
  }

  render() {

    const { exam, courses } = this.props;

    let courses_list = [];

    if(courses && courses !== '' && courses.length > 0)
    {
      courses_list = courses.map((course) => (
        <option key={course.id} value={course.id} > {course.con_name} </option>
      ));
    }
 
    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/exams">Exams</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Exam</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Exam </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/exams/add_exam"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

          {/*  Edit exam form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_exam_form" onSubmit={this.onUpdateExamSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Subject Name * : </label>
                      <input type="text" name="test_name" placeholder="Subject Name *" className="form-control" value={exam.test_name ? exam.test_name : ''} onChange={this.onChangeHandler}  required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course * : </label>
                      <select name="course_id" className="form-control" value={exam.course_id ? exam.course_id : ''} onChange={this.onChangeHandler} required>
                        <option value="0">Select Course </option>
                        {
                          courses && courses.map(course => (
                            <option key={course.id} value={course.id}>{course.course_name} </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Test Type * : </label>
                        <select name="test_type" className="form-control" value={exam.test_type ? exam.test_type : '1'} onChange={this.onChangeHandler} required>
                          <option value="1"> Online Test </option>
                          <option value="2"> Offline Test </option>
                          <option value="3"> Mock Test </option>
                        </select>
                      </div>
                      <div className="col">
                        {exam.test_type == '1' ? 
                          <div>
                            <label className="small_text"> Test Price : </label>
                            <input type="number" name="test_price" placeholder="Test Price" className="form-control"  value={exam.test_price ? exam.test_price : ''} onChange={this.onChangeHandler} required />
                          </div>
                        : <input type="hidden" name="test_price" value={0} />
                        }
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Duration (in mins) : </label>
                        <input type="number" name="test_duration" placeholder="Test Duration *" className="form-control" value={exam.test_duration ? exam.test_duration : ''} onChange={this.onChangeHandler} required />
                      </div>
                      <div className="col">
                        <label className="small_text"> Number of Questions * : </label>
                        <input type="number" name="number_of_questions" placeholder="Number of Questions *" className="form-control" value={exam.number_of_questions ? exam.number_of_questions : ''} onChange={this.onChangeHandler} required />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Date : </label>
                        <input type="date" name="test_date" placeholder="Test Date" className="form-control" value={exam.test_date ? exam.test_date : ''} onChange={this.onChangeHandler} />
                      </div>
                      <div className="col">
                        <label className="small_text"> Status : </label>
                        <select name="status" className="form-control" value={exam.status ? exam.status : ''} onChange={this.onChangeHandler} required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="row form-group">
                      <div className="col">
                      <label className="small_text"> Starts at : </label>
                        <input type="time" name="test_start_time" placeholder="Start Time" className="form-control" value={exam.test_start_time ? exam.test_start_time : ''} onChange={this.onChangeHandler} />
                      </div>
                      <div className="col">
                      <label className="small_text"> Ends at : </label>
                        <input type="time" name="test_end_time" placeholder="End Time" className="form-control" value={exam.test_end_time ? exam.test_end_time : ''} onChange={this.onChangeHandler} />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Test Details / Instructions : </label>
                      <textarea rows={11} name="test_details" placeholder="Test Details / Instructions" className="form-control" value={exam.test_details ? exam.test_details : ''} onChange={this.onChangeHandler}/>
                    </div>
                  </div>
                </div>
                 
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Exam </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    courses : state.course.courses,
    exam : state.exam.exam
  }
};

const mapActionsRoProps = {
  onGetDropDownCourses : getDropDownCourses,
  onGetExamDetails : getExamDetails,
  onUpdateExam : updateExam
};

export default connect(mapStatesToProps, mapActionsRoProps) (Exams);
