import { GET_GALLARY_CATEGORYS_SUCCESS, GET_GALLARY_CATEGORY_SUCCESS } from '../actions/GalleryCategory';

const initialState = {

    galleryCategorys : [],
    galleryCategory : {}

}

export default function GalleryCategory_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_GALLARY_CATEGORY_SUCCESS :
            return Object.assign({}, state, { galleryCategory : payload.galleryCategory });
            
        case GET_GALLARY_CATEGORYS_SUCCESS :
            return Object.assign({}, state, { galleryCategorys : payload.galleryCategory });

        default :
            return state;

    }
}
