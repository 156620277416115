import React from 'react';
import './Exams.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewExam } from '../../actions/Exams';
import { getDropDownCourses } from '../../actions/Courses';
import swal from 'sweetalert';

class AddExam extends React.Component {

  constructor(props) {
    super(props);
    this.state = {examType: '1'};
  }
  
  componentWillMount () {
	  this.props.onGetDropDownCourses();
  }

  handleExamTypeChange = (e) => {
    this.setState({examType: e.target.value});
  }

  onAddExamSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new exam?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_exam_form'));
        this.props.onAddNewExam(formData);
      }
    });
  }

  render() {
    const {courses} = this.props;
    const {examType} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/exams">Exams</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Exam</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Exam </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                
            </div>
          </div>
          
          {/*  Add new exam form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_exam_form" onSubmit={this.onAddExamSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Subject Name * : </label>
                      <input type="text" name="test_name" placeholder="Subject Name *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course * : </label>
                      <select name="course_id" className="form-control" required>
                        <option value="0">Select Course </option>
                        {
                          courses && courses.map(course => (
                            <option key={course.id} value={course.id}>{course.course_name} </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Test Type * : </label>
                        <select name="test_type" value={examType ? examType : "1"} onChange={(e) => this.handleExamTypeChange(e)} className="form-control" required>
                          <option value="1"> Online Test </option>
                          <option value="2"> Offline Test </option>
                          <option value="3"> Mock Test </option>
                        </select>
                      </div>
                      <div className="col">
                        {examType == '1' ? 
                          <div>
                            <label className="small_text"> Test Price : </label>
                            <input type="number" name="test_price" placeholder="Test Price" className="form-control" required />
                          </div>
                        : <input type="hidden" name="test_price" value={0} />
                        }
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Duration (in mins) : </label>
                        <input type="number" name="test_duration" placeholder="Test Duration *" className="form-control" required />
                      </div>
                      <div className="col">
                        <label className="small_text"> Number of Questions * : </label>
                        <input type="number" name="number_of_questions" placeholder="Number of Questions *" className="form-control" required />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Date : </label>
                        <input type="date" name="test_date" placeholder="Test Date *" className="form-control" />
                      </div>
                      <div className="col">
                        <label className="small_text"> Status : </label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="row form-group">
                      <div className="col">
                      <label className="small_text"> Starts at : </label>
                        <input type="time" name="test_start_time" placeholder="Start Time" className="form-control" />
                      </div>
                      <div className="col">
                      <label className="small_text"> Ends at : </label>
                        <input type="time" name="test_end_time" placeholder="End Time" className="form-control" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Test Details / Instructions : </label>
                      <textarea rows={12} name="test_details" placeholder="Test Details / Instructions" className="form-control"/>
                    </div>
                  </div>
                </div>
                 
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Exam </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    courses : state.course.courses
  }
};

const mapActionsRoProps = {
  onGetDropDownCourses : getDropDownCourses,
  onAddNewExam : addNewExam
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddExam);

