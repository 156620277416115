import { GET_CLASSES_SUCCESS, GET_CLASS_SUCCESS, GET_PRESENTER_SUCCESS } from '../actions/Classes';

const initialState = {
    classes: [],
    classe: {},
    presenters: [],
}

export default function Class_reducer (state = initialState, { type, payload } ) {
    switch (type)
    {
        case GET_CLASSES_SUCCESS :
            return Object.assign({}, state, { classes : payload.classes });

        case GET_CLASS_SUCCESS :
            return Object.assign({}, state, { classe : payload.classe });

        case GET_PRESENTER_SUCCESS :
            return Object.assign({}, state, { presenters : payload.presenters });

        default :
            return state;
    }

}
