import React from 'react';
import './Questions.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllQuestions, deleteQuestion } from '../../actions/Questions';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';

class Questions extends React.Component {
  constructor(props){
    super(props);
    this.state = {activePage : 1, testTypes: ["--", "Online", "Offline", "Mock"], exam_id : this.props.match.params.exam_id, exam_name : this.props.match.params.exam_name};
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
	  this.props.onGetQuestions(this.state.exam_id, '');
  }

  deleteQuestion = (question_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this question permanently?",
      dangerMode: true,
	    buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteQuestion(this.state.exam_id, question_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetQuestions(this.state.exam_id, '?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value); 
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetQuestions(this.state.exam_id, '?' + params);
  }


  render() {

    const { questions, total_count, page_count, page_size, base_url } = this.props;
    const {testTypes, exam_id, exam_name} = this.state;
    let questions_display = {};
    let pagination_display = ''

    if(questions && questions !== '' && questions.length > 0)
    {
      questions_display = questions.map((question, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{question.question ? question.question :  question.question_image ? <img src={base_url + question.question_image} height="80" alt="" /> : '' }</td>
          <td>{question.answer}</td>
          <td>{question.status == '1' ? 'Active' : 'Inactive'}</td>
          <td><Link to={'/questions/' + exam_name + '/' + exam_id + '/edit_question/' + question.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
          <td><button className="icon_button" title="Delete" onClick={() => this.deleteQuestion(question.id)}> <i className="fa fa-trash text-danger"></i> </button></td>
        </tr>
      ))
    }
    else
    {
      questions_display = <tr><td colSpan="6" className="text-center"> No questions found.</td></tr>
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} questions. </p> */}
          <p className="small_text d-inline ml-2"> Showing {questions.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} questions. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {questions.length} results. </p>
    } 

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/exams">Exams</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Questions</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Questions </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/questions/" + exam_name + "/" + exam_id + "/add_question"}><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Questions A-Z</option>
                    <option value="4">Questions Z-A</option>
                    <option value="5">Countries A-Z</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, code or country" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all questions table */}
          <table className="table table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No</th>
                <th className="bg-blue text-light" width="50%">Question</th>
                <th className="bg-blue text-light" width="25%">Correct Option</th>
                <th className="bg-blue text-light" width="10%">Status</th>
                <th className="bg-blue text-light" width="10%">Edit</th>
                <th className="bg-blue text-light" width="5%">Delete</th>
              </tr>
            </thead>
            <tbody>

            {/* Display all the questions */}

            {questions_display}

            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    questions : state.question.questions,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url : state.admin.base_url

  }
};

const mapActionsRoProps = {
  onGetQuestions : getAllQuestions,
  onDeleteQuestion : deleteQuestion
};

export default connect(mapStatesToProps, mapActionsRoProps) (Questions);
