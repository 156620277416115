import React from 'react';
import './Faculties.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewFaculty } from '../../actions/Faculties';
import swal from 'sweetalert';

class Faculties extends React.Component {
  constructor(props){
    super(props);
    this.state = { images : { faculty_image : ''} };
    this.onAddFacultySubmit = this.onAddFacultySubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    let images = this.state.images;
    images[e.target.name] = URL.createObjectURL(e.target.files[0]);
    this.setState({ images : images });
  }

  onAddFacultySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new faculty?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_faculty_form'));
        this.props.onAddNewFaculty(formData);
      }
    });
  }

  render() {

    const { images } = this.state;
    let image_preview = '';

    if(images.faculty_image){
      image_preview = <img src={images.faculty_image} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/faculties">Faculties</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Faculty</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Faculty </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new faculty form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
            <br/>

              <form id="add_faculty_form" onSubmit={this.onAddFacultySubmit} autoComplete="off">

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Full Name * : </label>
                        <input type="text" name="faculty_name" placeholder="Faculty Name *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Mobile Number * : </label>
                      <input type="text" name="faculty_mobile" placeholder="Mobile Number *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Email ID * : </label>
                      <input type="email" name="faculty_email" placeholder="Email ID *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Password * : </label>
                      <input type="text" name="faculty_password" placeholder="Password *" className="form-control" required/>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                      <label className="small_text">Faculty Photo * : </label>
                        <input type="file" name="faculty_image" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                          {image_preview}
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Faculty Details : </label>
                      <textarea rows={5} name="faculty_details" placeholder="Faculty Details *" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Status * : </label>
                      <select name="status" className="form-control" required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr/>

                <div className="form-group text-center mt-2">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Faculty </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewFaculty : addNewFaculty
};

export default connect(mapStatesToProps, mapActionsRoProps) (Faculties);
