import { GET_BOOKS_SUCCESS, GET_BOOK_SUCCESS } from '../actions/Books';

const initialState = {
    books : [],
    book : {}
}

export default function Book_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_BOOK_SUCCESS :
            return Object.assign({}, state, { book : payload.book });

        case GET_BOOKS_SUCCESS :
            return Object.assign({}, state, { books : payload.books });

        default :
            return state;
    }
}
