import React from 'react';
import './AdminUsers.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserDetails, getUserRoles, updateUser, changeUserPassword } from '../../actions/Users';
import { onDisplayError } from '../../actions/Login';
import swal from 'sweetalert';
import $ from 'jquery';

class EditAdmin extends React.Component {
  constructor(props){
    super(props);
    this.state = { user_id : this.props.match.params.admin_id, profile_pic: '' };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetUserDetails(this.state.user_id);
    this.props.onGetUserRoles();
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let user = this.props.user;
    let profile_pic = this.state.profile_pic;
    
    if(e.target.name === 'profile_pic'){
      profile_pic = URL.createObjectURL(e.target.files[0]);
    } else {
      user[name] = value;
    }

    this.setState({ user : user, profile_pic: profile_pic });
  }

  onUpdateUserSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this admin?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_user_form'));
        this.props.onUpdateUser(this.state.user_id, formData);
      }
    });
  }

  onChangePasswordSubmit = (e) => {
    e.preventDefault();
    if($('#new_password').val().length < 8)
    {
      this.props.onDisplayErroMessage('Password must have atleast 6 characters');
    }
    else if($('#new_password').val() !== $('#confirm_password').val())
    {
      this.props.onDisplayErroMessage('Confirm password must be same as password');
    }
    else
    {
      swal({
        title: "Are you sure?",
        text: "To update new password?",
        buttons: ['Cancel', 'Update'],
      })
      .then(willDelete => {
        if (willDelete) {
          var formData = new FormData(document.getElementById('change_password_form'));
          this.props.onChangePassword(this.state.user_id, formData);
        }
      });
    }
  }

  render() {

    const { user, base_url, roles } = this.props;
    const { profile_pic } = this.state;
    let image_preview = '';

    if(user && user.profile_pic){
      image_preview = <img src={base_url + user.profile_pic} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(profile_pic){
      image_preview = <img src={profile_pic} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/admins">Admins</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Admin</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Admin </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/admins/add_admin"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
    
          {/*  Edit user form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="edit_user_form" onSubmit={this.onUpdateUserSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Full Name : </label>
                      <input type="text" name="name" placeholder="Full Name *" value={user.name ? user.name : ''} onChange={this.onChangeHandler} className="form-control" autoFocus required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID * : </label>
                      <input type="email" name="email" placeholder="Email ID *" value={user.email ? user.email : ''} onChange={this.onChangeHandler} className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Mobile Number * : </label>
                      <input type="number" name="mobile_number" placeholder="Mobile Number *" value={user.mobile_number ? user.mobile_number : ''} onChange={this.onChangeHandler} className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Date of Birth : </label>
                      <input type="date" name="dob" placeholder="Date of Birth" value={user.dob ? user.dob : ''} onChange={this.onChangeHandler} className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Select Role * : </label>
                      <select name="user_role" className="form-control" value={user.user_role ? user.user_role : ''} onChange={this.onChangeHandler} required>
                        <option value="">Select Role</option>
                        {
                          roles && roles.length > 0 && roles.map((role) => (
                            <option key={role.id} value={role.id}> {role.role_name} </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Gender : </label>
                        <select name="gender" value={user.gender ? user.gender : ''} onChange={this.onChangeHandler} className="form-control" required>
                          <option value=""> Select Gender </option>
                          <option value="Male"> Male </option>
                          <option value="Female"> Female </option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status : </label>
                        <select name="status" value={user.status ? user.status : ''} onChange={this.onChangeHandler} className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Profile Photo: </label>
                        <input type="file" name="profile_pic" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {image_preview}
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <label className="small_text">Communication Address : </label>
                      <textarea rows={5} name="communication_address" placeholder="Communication Address" value={user.communication_address ? user.communication_address : ''} onChange={this.onChangeHandler} className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Permanent Address : </label>
                      <textarea rows={5} name="permanent_address" placeholder="Permanent Address" value={user.permanent_address ? user.permanent_address : ''} onChange={this.onChangeHandler} className="form-control"/>
                    </div>

                    {/* <input type="hidden" name="user_role" value={1} /> */}
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Admin </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

           {/*  Change user password form  */}
           <div className="row add_container form_container mt-4">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <h5 className="text-center page_header text-uppercase"> Change Password </h5>

              <form id="change_password_form" onSubmit={this.onChangePasswordSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> New Password : </label>
                    <input type="password" id="new_password" name="new_password" placeholder="New Password" className="form-control" required/>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> Confirm Password : </label>
                    <input type="password" id="confirm_password" name="confirm_password" placeholder="Confirm Password" className="form-control" required/>
                  </div>
                </div>

                <div className="form-group text-center mt-2">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Password </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading : state.admin.isLoading, 
        base_url: state.admin.base_url,
        user : state.user.user,
        roles : state.user.roles
    }
};

const mapActionsRoProps = {
    onGetUserDetails : getUserDetails,
    onGetUserRoles : getUserRoles,
    onDisplayErroMessage : onDisplayError,
    onUpdateUser : updateUser,
    onChangePassword : changeUserPassword
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditAdmin);