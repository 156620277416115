import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewHostel } from '../../actions/Hostels';
import swal from 'sweetalert';

class AddHostel extends React.Component {
    constructor(props){
        super(props);
        this.state = { images : { hostel_icon : ''}, totalBeds: 0 };
    }

    onAddHostelSubmit = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "To add this new hostel?",
            buttons: ['Cancel', 'Add'],
        })
        .then(willDelete => {
            if (willDelete) {
                var formData = new FormData(document.getElementById('add_hostel_form'));
                var hostel_levels = document.getElementsByClassName('hostel_levels');
                var levels = [];

                for(let i=0;i<hostel_levels.length;i++) {
                    if(hostel_levels[i].checked) {
                        levels.push(hostel_levels[i].value);
                    }
                }

                formData.append('hostel_levels', levels.toString());
                this.props.onAddNewHostel(formData);
            }
        });
    }

  handleImageChange = (e) => {
    const { name } = e.target;
    let images = this.state.images;

    if(name === 'hostel_icon_path'){
      images['hostel_icon'] = URL.createObjectURL(e.target.files[0]);
    }

    this.setState({ images : images });
  }

  render() {

    const {images, totalBeds} = this.state;

    return (
        <div className="row">
            <div className="col">
                {/* Breadcrumbs and add button container */}
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <nav area-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                                <li className="breadcrumb-item"><Link to="/hostels">Hostels</Link> </li>
                                <li className="breadcrumb-item active" area-current="page">Add Hostel</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <h4 className="text-center page_header"> Add Hostel </h4>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right" />
                </div>
                    
                {/*  Add new hostel form  */}
                <div className="row add_container form_container">
                    <div className="col"></div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
                        <br/>

                        <form id="add_hostel_form" onSubmit={this.onAddHostelSubmit} autoComplete="off">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="small_text">Hostel Name * : </label>
                                        <input type="text" name="hostel_name" placeholder="Hostel Name" className="form-control" required/>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col">
                                            <label className="small_text">Hostel Type * : </label>
                                            <select name="hostel_type" className="form-control" required>
                                            <option value=""> Select hostel type </option>
                                            <option value="1"> Boys hostel </option>
                                            <option value="0"> Girls hostel </option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label className="small_text">Status : </label>
                                            <select name="status" className="form-control">
                                            <option value="1"> Active </option>
                                            <option value="0"> Inactive </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="small_text">Hostel Price Per Month * : </label>
                                        <input type="number" name="hostel_price" placeholder="Hostel Price Per Month" className="form-control" required/>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col">
                                            <label className="small_text">Hostel Capacity * : </label>
                                            <input type="number" name="hostel_capacity" id="hostel_capacity" placeholder="Total Beds" className="form-control" value={totalBeds} onChange={(e) => this.setState({totalBeds: e.target.value})} required/>
                                        </div>
                                        <div className="col">
                                            <label className="small_text">Available Beds * : </label>
                                            <input type="number" placeholder="Available Beds" disabled={true} className="form-control" value={totalBeds} />
                                            <input type="hidden" name="available_beds" value={totalBeds} />
                                        </div>
                                    </div>
                                </div>

                                    <div className="col">
                                        <div className="form-group">
                                            <label className="small_text">Contact Number : </label>
                                            <input type="number" name="hostel_contact_number" placeholder="Contact Number" className="form-control" />
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text">Address : </label>
                                            <textarea rows={6} name="hostel_address" placeholder="Hostel Address" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="form-group text-center mt-3">
                                    <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Hostel </button>
                                </div>
                            </form>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        isLoading : state.admin.isLoading,
    }
};

const mapActionsRoProps = {
    onAddNewHostel : addNewHostel,
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddHostel);
