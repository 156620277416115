
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getClassDetails, getBatchFaculty, updateClass } from '../../actions/Classes';
import swal from 'sweetalert';
import moment from 'moment';
class EditClass extends React.Component {
  constructor(props){
    super(props);
    const {params} = props.match;
    this.state = { batch_id : params.batch_id, class_id: params.class_id, presenterData: {}};
    //this.onChangeHandler = this.onChangeHandler.bind(this);

  }

  componentWillMount() {
    this.props.onGetClassDetails(this.state.class_id);
    this.props.onGetAllPresenters( this.state.batch_id );
  }

  onChangeHandler = (e) => {

    //alert("hello");
    /*if(type == 1) {
      var startDateTime = moment(value).format('YYYY-MM-DD HH:mm:ss');
      this.setState({startDate: startDateTime})
    } else if(type == 2) {
      var endDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
      this.setState({endDate: endDate})
    } else if(type == 3) {
      this.setState({presenterData: this.props.presenters[value]})
    }*/
    
    const { name, value } = e.target;
    let classe = this.props.classe;
    classe[name] = value;
    this.setState({ classe : classe});

  }

  onEditClassSubmit = (e) => {

    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new class?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_class_form'));
        /*var class_levels = document.getElementsByClassName('class_levels');
        var levels = new Array();

        for(let i=0;i<class_levels.length;i++)
        {
          if(class_levels[i].checked)
          {
            levels.push(class_levels[i].value);
          }
        }

        formData.append('class_levels', levels.toString());
        */
        this.props.onUpdateClass(this.state.class_id, formData);

      }
    });
  }

  render() {

    const {batch_id, presenterData} = this.state;

    const { presenters, classe } = this.props;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/batches">Batches</Link> </li>
                  <li className="breadcrumb-item"><Link to={"/classes/" + batch_id}>Classes</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Class</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Class </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new class form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_class_form" onSubmit={this.onEditClassSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Class TItle * : </label>
                      <input type="text" name="class_title" placeholder="Class Title *" className="form-control" value={classe.class_title ? classe.class_title : ''} onChange={this.onChangeHandler} required/>

                    </div>

                    <div className="form-group">
                      <label className="small_text">Start Date * : </label>
                      <input type="date" name="start_date" placeholder="Start Date" value={classe.start_date ? classe.start_date : ''} className="form-control" onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> End Date * : </label>
                      <input type="date" name="end_date" placeholder="End Date" value={classe.end_date ? classe.end_date : ''} className="form-control" onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Start Time * : </label>
                      <input type="time" name="start_time" placeholder="Start Time" value={classe.start_time ? classe.start_time : ''} className="form-control" onChange={this.onChangeHandler} required/>
                    </div>


                    <div className="form-group">
                      <label className="small_text">End Time * : </label>
                      <input type="time" name="end_time" placeholder="End Time" value={classe.end_time ? classe.end_time : ''} className="form-control" onChange={this.onChangeHandler} required/>
                    </div>

                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Presenter * : </label>
                      <select className="form-control" name="faculty_id" onChange={this.onChangeHandler} value={classe.faculty_id ? classe.faculty_id : ''} required>
                        <option value=""> Select Presenter </option>
                        {
                          presenters && presenters.length > 0 && 
                            presenters.map((presenter, index) => (
                              <option key={index} value={presenter.id}>{presenter.faculty_name}</option>
                            ))
                        }
                      </select>
                    </div>


                    <div className="form-group">
                      <label className="small_text"> Class Repeat Type * : </label>
                      <select name="class_repeat_type" className="form-control" value={classe.class_repeat_type ? classe.class_repeat_type : ''} required>
                        <option value=""> Select Class Repeat Type </option>
                        <option value="1"> Daily (All 7 Days) </option>
                        <option value="2"> Monday to Saturday </option>
                        <option value="3"> Monday to Friday </option>
                        <option value="4"> Weekly Once </option>
                        <option value="5"> Monthly Once </option>
                      </select>
                    </div>

                     <div className="form-group">
                      <label className="small_text"> Class Status * : </label>
                      <select name="class_status" className="form-control" value={classe.status ? classe.status : ''} required>
                        <option value="scheduled">Scheduled</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>


                  </div>
                </div>
              
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Class </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading,
    presenters: state.classe.presenters,
    classe: state.classe.classe,
  }
};

const mapActionsRoProps = {
  onGetClassDetails: getClassDetails,
  onGetAllPresenters: getBatchFaculty,
  onUpdateClass: updateClass,
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditClass);
