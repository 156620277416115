import React from 'react';
import './Videos.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVideoDetails, updateVideo } from '../../actions/Videos';
import swal from 'sweetalert';

class Videos extends React.Component {
  constructor(props){
    super(props);
    this.state = { video_id : this.props.match.params.video_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetVideoDetails(this.state.video_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let video = this.props.video;
    video[name] = value;
    this.setState({ video : video });
  }

  onUpdateVideoSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this video?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_video_form'));
        this.props.onUpdateVideo(this.state.video_id, formData);
      }
    });
  }

  render() {

    const { video } = this.props;

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/videos">Videos</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Video</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Video </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/videos/add_video"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewVideo}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit video form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_video_form" onSubmit={this.onUpdateVideoSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Subject Name : </label>
                        <input type="text" name="video_subject" placeholder="Subject Name" className="form-control" value={video.video_subject ? video.video_subject : ''} onChange={this.onChangeHandler} />
                      </div>
                      <div className="col">
                        <label className="small_text"> Chapter Name : </label>
                        <input type="text" name="video_chapter" placeholder="Chapter Name" className="form-control" value={video.video_chapter ? video.video_chapter : ''} onChange={this.onChangeHandler} />
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <label className="small_text"> Video Youtube ID * : </label>
                        <input type="text" name="video_path" placeholder="Video Youtube ID *" className="form-control" value={video.video_path ? video.video_path : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Status : </label>
                      <select name="status" className="form-control" value={video.status ? video.status : ''} onChange={this.onChangeHandler} required >
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Video Description : </label>
                      <textarea rows={8} name="video_description" placeholder="Video Description" className="form-control" value={video.video_description ? video.video_description : ''} onChange={this.onChangeHandler} />
                    </div>
                  </div>
                </div>
  
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Video </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    video : state.video.video,
  }
};

const mapActionsRoProps = {
  onGetVideoDetails : getVideoDetails,
  onUpdateVideo : updateVideo
};

export default connect(mapStatesToProps, mapActionsRoProps) (Videos);
