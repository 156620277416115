import React from 'react';
import './Batches.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBatchDetails, updateBatch } from '../../actions/Batches';
import {getCoursesWithBatches} from '../../actions/Courses';
import {getActiveFaculties} from '../../actions/Faculties';
import swal from 'sweetalert';

class Batches extends React.Component {
  constructor(props){
    super(props);
    const {params} = props.match;
    this.state = { batch_id : params.batch_id, type: params.type, images : { batch_image_path : '' }, selectedFaculties: [] };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetBatchDetails(this.state.batch_id);
    this.props.onGetActiveFaculties();
    this.props.onGetCoursesWithBatches(this.state.type);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.batch !== this.props.batch) {
      this.setState({selectedFaculties: this.props.batch.faculty_details ? JSON.parse(this.props.batch.faculty_details) : []});
    }
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let batch = this.props.batch;
    batch[name] = value;

    if(name == 'batch_type') {
      this.props.onGetCoursesWithBatches(value);
    } else if(name == 'admission_fees' || name == 'tution_fees' || name == 'books_fees' || name == 'hostel_fees' || name == 'meal_fees' || name == 'transportation_fees' || name == 'online_fees' || name == 'other_fees') {
      var admissionFees = document.getElementById('admission_fees').value;
      var tutionFees = document.getElementById('tution_fees').value;
      var booksFees = document.getElementById('books_fees').value;
      var hostelFees = document.getElementById('hostel_fees').value;
      var mealFees = document.getElementById('meal_fees').value;
      var transportFees = document.getElementById('transportation_fees').value;
      var onlineFees = document.getElementById('online_fees').value;
      var otherFees = document.getElementById('other_fees').value;

      batch['total_fees'] = ((admissionFees ? parseInt(admissionFees) : 0) + (tutionFees ? parseInt(tutionFees) : 0) + (booksFees ? parseInt(booksFees) : 0) + (hostelFees ? parseInt(hostelFees) : 0) + (mealFees ? parseInt(mealFees) : 0) + (transportFees ? parseInt(transportFees) : 0) + (onlineFees ? parseInt(onlineFees) : 0) + (otherFees ? parseInt(otherFees) : 0));
    }

    this.setState({ batch : batch});
  }

  handleFacultyChange = (e, faculty_id) => {
    let {selectedFaculties} = this.state;

    if(selectedFaculties.includes(faculty_id)){
      selectedFaculties.splice(selectedFaculties.indexOf(faculty_id), 1);
    } else {
      selectedFaculties.push(faculty_id);
    }

    this.setState({ selectedFaculties : selectedFaculties});    
    console.log('Selected values', selectedFaculties);
  }

  onUpdateBatchSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this batch?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_batch_form'));
        formData.append('faculty_details', JSON.stringify(this.state.selectedFaculties));
        this.props.onUpdateBatch(this.state.batch_id, formData);
      }
    });
  }

  render() {
    const { batch, base_url, courses, faculties } = this.props;
    const { images, selectedFaculties } = this.state;
    let image_preview = '';

    if(batch && batch.batch_image_path){
      image_preview = <img src={base_url + batch.batch_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.batch_image_path){
      image_preview = <img src={images.batch_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/batches">Batches</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Batch</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Batch </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/batches/add_batch"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewBatch}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit batch form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">

              <form id="edit_batch_form" onSubmit={this.onUpdateBatchSubmit} autoComplete="off">

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Batch Name * : </label>
                      <input type="text" name="batch_name" placeholder="Batch Name *" className="form-control" value={batch.batch_name ? batch.batch_name : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Batch Type * : </label>
                      <select name="batch_type" className="form-control" value={batch.batch_type ? batch.batch_type : ''} onChange={this.onChangeHandler} required>
                        <option value="1"> Online Batch </option>
                        <option value="0"> Offline Batch </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Select Course * : </label>
                      <select name="course_id" className="form-control" value={batch.course_id ? batch.course_id : ''} onChange={this.onChangeHandler} required>
                        <option value="0">Select Course </option>
                        {
                          courses && courses.map(course => (
                            <option key={course.id} value={course.id}>{course.course_name} </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                      <label className="small_text"> Start Date * : </label>
                        <input type="date" name="batch_start_date" placeholder="Start Date *" className="form-control" value={batch.batch_start_date ? batch.batch_start_date : ''} onChange={this.onChangeHandler} required />
                      </div>
                      <div className="col">
                      <label className="small_text"> Start Time * : </label>
                        <input type="time" name="batch_start_time" placeholder="Start Time *" className="form-control" value={batch.batch_start_time ? batch.batch_start_time : ''} onChange={this.onChangeHandler} required />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Batch Duration * : </label>
                        <input type="text" name="batch_duration" placeholder="Batch Duration *" className="form-control" value={batch.batch_duration ? batch.batch_duration : ''} onChange={this.onChangeHandler} required />
                      </div>
                      <div className="col">
                        <label className="small_text">Status : </label>
                        <select name="status" className="form-control" value={batch.status ? batch.status : ''} onChange={this.onChangeHandler} required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Batch Details : </label>
                      <textarea rows={8} name="batch_details" placeholder="Batch Details *" className="form-control" value={batch.batch_details ? batch.batch_details : ''} onChange={this.onChangeHandler} required/>
                    </div>
                  </div>
                </div>

                <hr />
                <h5 className="text-center text-blue">SELECT FACULTIES</h5>
                <div className="row form-group mt-3">
                  {
                    faculties && faculties.map(faculty => (
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" key={faculty.id}>
                        <input type="checkbox" checked={selectedFaculties.includes(faculty.id)} value={faculty.id} onChange={(e) => this.handleFacultyChange(e, faculty.id)} />
                        <label className="medium_text text-blue ml-2">{faculty.faculty_name}</label><br />
                      </div>
                    ))
                  }
                </div>

                <hr />
                <h5 className="text-center text-blue">FEES DETAILS</h5>
                <div className="row form-group mt-3">
                  <div className="col">
                    <label className="small_text">Registration : </label>
                    <input type="number" name="admission_fees" id="admission_fees" placeholder="Admission Fees" className="form-control" value={batch.admission_fees ? batch.admission_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                  <div className="col">
                    <label className="small_text">Admission : </label>
                    <input type="number" name="tution_fees" id="tution_fees" placeholder="Tuition Fees" className="form-control" value={batch.tution_fees ? batch.tution_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                  <div className="col">
                    <label className="small_text">Books : </label>
                    <input type="number" name="books_fees" id="books_fees" placeholder="Books Fees" className="form-control" value={batch.books_fees ? batch.books_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                  <div className="col">
                    <label className="small_text">Hostel : </label>
                    <input type="number" name="hostel_fees" id="hostel_fees" placeholder="Hostel Fees" className="form-control" value={batch.hostel_fees ? batch.hostel_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col">
                    <label className="small_text">Meals : </label>
                    <input type="number" name="meal_fees" id="meal_fees" placeholder="Meals Fees" className="form-control" value={batch.meal_fees ? batch.meal_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                  <div className="col">
                    <label className="small_text">Transportation : </label>
                    <input type="number" name="transportation_fees" id="transportation_fees" placeholder="Transportation Fees" className="form-control" value={batch.transportation_fees ? batch.transportation_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                  <div className="col">
                    <label className="small_text">Online : </label>
                    <input type="number" name="online_fees" id="online_fees" placeholder="Online Fees" className="form-control" value={batch.online_fees ? batch.online_fees : ''} onChange={this.onChangeHandler}/>
                  </div>
                  <div className="col">
                    <label className="small_text">Others : </label>
                    <input type="number" name="other_fees" id="other_fees" placeholder="Other Fees" className="form-control" value={batch.other_fees ? batch.other_fees : ''} onChange={this.onChangeHandler} />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col">
                    <label className="small_text">Total Fees * : </label>
                    <input type="number" name="total_fees" id="total_fees" placeholder="Total Fees" className="form-control" value={batch.total_fees ? batch.total_fees : 0} onChange={this.onChangeHandler} required />
                  </div>
                </div>
                
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Batch </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    batch : state.batch.batch,
    base_url : state.admin.base_url,
    courses: state.course.courses,
    faculties: state.faculty.faculties
  }
};

const mapActionsRoProps = {
  onGetBatchDetails : getBatchDetails,
  onGetActiveFaculties: getActiveFaculties,
  onGetCoursesWithBatches: getCoursesWithBatches,
  onUpdateBatch : updateBatch
};

export default connect(mapStatesToProps, mapActionsRoProps) (Batches);
