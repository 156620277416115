import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_GALLARY_CATEGORYS_SUCCESS = 'GET_GALLARY_CATEGORYS_SUCCESS';
export const GET_GALLARY_CATEGORY_SUCCESS = 'GET_GALLARY_CATEGORY_SUCCESS';

export const getAllGalleryCategory = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getAllGalleryCategory' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetGallaryCategorysSuccess(response.data.data));

                    //console.log("testing", response.data);
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}


export const getGalleryCategory = (params) => {

    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getGalleryCategory' + params)
            .then(response => {

                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {

                    dispatch(onGetGallaryCategorysSuccess(response.data.data));
                    console.log("testing****", response.data);
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    console.log("testing****11111");
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                console.log("testing****3333"+error);
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}



export const addGalleryCategory = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/addGalleryCategory', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {

                    //console.log("testing111", response);
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {    //console.log("testing222", response);
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getGalleryCategoryDetail = (gallery_category_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getGalleryCategoryDetail/' + gallery_category_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){

                    console.log("hhhhhh",response);
                    dispatch(onGetGalleryCategorySuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateGalleryCategory = (gallery_category_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/updateGalleryCategory/' + gallery_category_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const deleteGalleryCategory = (gallery_category_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/deleteGalleryCategory/' + gallery_category_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllGalleryCategory(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};




export const onGetGallaryCategorysSuccess = (data) => {
    return {
        type : GET_GALLARY_CATEGORYS_SUCCESS,
        payload : {
            galleryCategory : data
        }
    }
}

export const onGetGalleryCategorySuccess = (data) => {
    return {
        type : GET_GALLARY_CATEGORY_SUCCESS,
        payload : {
            galleryCategory : data
        }
    }
}




