import { GET_QUESTIONS_SUCCESS, GET_QUESTION_SUCCESS } from '../actions/Questions';

const initialState = {
    questions : [],
    question : {}
}

export default function Question_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_QUESTION_SUCCESS :
            return Object.assign({}, state, { question : payload.question });

        case GET_QUESTIONS_SUCCESS :
            return Object.assign({}, state, { questions : payload.questions });

        default :
            return state;
    }
}
