import React from 'react';
import './DeliveryCharge.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDeliveryChargeDetail, updateDeliveryCharge } from '../../actions/DeliveryCharge';
import swal from 'sweetalert';

class EditDeliveryCharge extends React.Component {
  constructor(props){
    super(props);
    this.state = { delivery_charge_id : this.props.match.params.delivery_charge_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.getDeliveryChargeDetail(this.state.delivery_charge_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let deliveryCharge = this.props.deliveryCharge;
    deliveryCharge[name] = value;

    this.setState({ deliveryCharge : deliveryCharge });
  }

  onUpdateCategorySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this achiever?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_achiever_form'));
        this.props.updateDeliveryCharge(this.state.delivery_charge_id, formData);
      }
    });
  }

  render() {

    const { deliveryCharge, base_url } = this.props;
   

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/deliveryCharge">Delivery Charge</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Delivery Charge</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Delivery Charge </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/deliveryCharge/add_delivery_charge"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewAchiever}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit achiever form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="edit_achiever_form" onSubmit={this.onUpdateCategorySubmit} autoComplete="off">

                <div className="form-group">
                  <label className="small_text">order_value * : </label>
                  <input type="text" name="order_value" placeholder="Order Value *" value={deliveryCharge.order_value ? deliveryCharge.order_value : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Display Order * : </label>
                  <input type="number" name="delivery_charge_amount" placeholder="Delivery Charge *" value={deliveryCharge.delivery_charge_amount} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Status * : </label>
                  <select name="status" className="form-control" value={deliveryCharge.status} onChange={this.onChangeHandler} required>
                    <option value="Active"> Active </option>
                    <option value="InActive"> In Active </option>
                  </select>
                </div>
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Achiever </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {

  return {
    isLoading : state.admin.isLoading, 
    deliveryCharge : state.deliveryCharge.deliveryCharge,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  getDeliveryChargeDetail : getDeliveryChargeDetail,
  updateDeliveryCharge : updateDeliveryCharge
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditDeliveryCharge);
