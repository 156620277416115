import React from 'react';
import './Gallery.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getGalleryImageDetail, updateGalleryImage } from '../../actions/Gallery';
import { getGalleryCategory } from '../../actions/GalleryCategory';

import swal from 'sweetalert';

class EditGallery extends React.Component {
  constructor(props){
    super(props);
    this.state = { gallery_id : this.props.match.params.gallery_id, images : { achiever_image_path : '' } };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {

    this.props.getGalleryImageDetail(this.state.gallery_id);
    this.props.getGalleryCategory("");   

  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let gallery = this.props.gallery;
    let images = this.state.images;
    gallery[name] = value;

    if(e.target.name === 'achiever_image_path'){
      images[name] = URL.createObjectURL(e.target.files[0]);
      gallery[name] = '';
    }

    this.setState({ gallery : gallery, images : images });
  }

  onUpdateGallerySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this achiever?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_achiever_form'));

        console.log("testtkjjj", this.state.gallery_id);
        this.props.onUpdateGallery(this.state.gallery_id, formData);
      }
    });
  }

  render() {

    const { gallery, base_url } = this.props;
    const {galleryCategorys} = this.props;

    console.log("yyyy",gallery.gallery_category);

    const { images } = this.state;
    let image_preview = '';

    if(gallery && gallery.gallery_image){
      image_preview = <img src={base_url + gallery.gallery_image} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.gallery_image){
      image_preview = <img src={images.gallery_image} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/gallery">Gallery</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Gallery</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Gallery </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/gallery/add_gallery"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewAchiever}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit achiever form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="edit_achiever_form" onSubmit={this.onUpdateGallerySubmit} autoComplete="off">

               <div className="form-group">
                    <label className="small_text"> Category * : </label>
                    <select name="gallery_category" className="form-control" value={gallery.gallery_category ? gallery.gallery_category : ''} onChange={this.onChangeHandler} className="form-control" required>
                        <option value="0">Select Category </option>
                        {
                          galleryCategorys && galleryCategorys.map(galleryCategory => (
                            <option key={galleryCategory.id} value={galleryCategory.id}>{galleryCategory.gallery_category_name} </option>
                        ))
                        }
                    </select>
                </div>

                <div className="form-group">
                  <label className="small_text">Gallery Name * : </label>
                  <input type="text" name="gallery_title" placeholder="Gallery image title *" value={gallery.gallery_title ? gallery.gallery_title : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Gallery Order * : </label>
                  <input type="text" name="gallery_order" placeholder="Display order *" value={gallery.gallery_order ? gallery.gallery_order : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Status * : </label>
                  <select name="gallery_status" className="form-control" value={gallery.gallery_status} onChange={this.onChangeHandler} required>
                    <option value="Active"> Active </option>
                    <option value="InActive"> In Active </option>
                  </select>
                </div>


                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <label className="small_text">Gallery Image * : </label>
                    <input type="file" name="gallery_image" onChange={this.onChangeHandler} className="form-control"/>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                    {image_preview}
                  </div>
                </div>
      
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Gallery </button>
                </div>


              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    gallery : state.gallery.gallery,
    base_url : state.admin.base_url,
    galleryCategorys : state.galleryCategory.galleryCategorys
  }
};

const mapActionsRoProps = {
  getGalleryImageDetail : getGalleryImageDetail,
  getGalleryCategory : getGalleryCategory,
  onUpdateGallery : updateGalleryImage
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditGallery);
