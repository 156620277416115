import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';

export const getAllSubscriptions = (bookID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getAllSubscription/' + bookID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetSubscriptionsSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addSubscription = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/addSubscription', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getSubscriptionDetails = (book_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getSubscriptionDetail/' + book_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetSubscriptionSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateSubscription = (book_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/updateSubscription/' + book_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteSubscription = (book_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/deleteSubscription/' + book_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllSubscriptions(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetSubscriptionsSuccess = (data) => {
    return {
        type : GET_SUBSCRIPTIONS_SUCCESS,
        payload : {
            books : data
        }
    }
}

export const onGetSubscriptionSuccess = (data) => {
    return {
        type : GET_SUBSCRIPTION_SUCCESS,
        payload : {
            book : data
        }
    }
}