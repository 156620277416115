import React from 'react';
import './Dashboard.css';
import PieChart from 'react-simple-pie-chart';
import { Link } from 'react-router-dom';
import { getAdminDashboard } from '../../actions/Dashboard';
import { connect } from 'react-redux';
import DateFormat from '../../utils/DateFormat';
import TimeFormat from '../../utils/TimeFormat';
class Dashboard extends React.Component {

	constructor (props) {
		super(props);
		this.state = { time: new Date() };
	}

	componentWillMount () {
		if(this.props.isAuth)
		{
			setTimeout( function () { this.props.onGetAdminDashboard() }.bind(this), 1);
		}
		this.timerID = setInterval(() => this.changeTiming(), 1000);
	}

	changeTiming() {
		this.setState({ time : new Date()});
	}

	render() {

		const { adminInfo, dashboardData, users, week_users, month_users, month_callbacks, plans, consultants, callbacks } =  this.props;

		const user_graph = [
			{
				color: '#386FA4',
				value: users ? users.paid : 0,
			},
			{
				color: '#88CCF1',
				value: users ? users.unpaid : 0,
			},
			{
				color: '#EF626C',
				value: users ? users.inactive : 0,
			}
		]

		const week_users_graph = [
			{
				color: '#386FA4',
				value: week_users ? week_users.paid : 0,
			},
			{
				color: '#88CCF1',
				value: week_users ? week_users.unpaid : 0,
			},
			{
				color: '#EF626C',
				value: week_users ? week_users.inactive : 0,
			}
		]

		const month_users_graph = [
			{
				color: '#386FA4',
				value: month_users ? month_users.paid : 0,
			},
			{
				color: '#88CCF1',
				value: month_users ? month_users.unpaid : 0,
			},
			{
				color: '#EF626C',
				value: month_users ? month_users.inactive : 0,
			}
		]

		let classRepeatTypes = ['', 'Daily (All 7 Days)', 'Monday to Saturday', 'Monday to Friday', 'Weekly Once', 'Monthly Once'];
    
		return (
			<div className="row">
				<div className="col">
				
					{/* Breadcrumbs and add button container */}
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<nav area-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active" area-current="page">Dashboard</li>
								</ol>
							</nav>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<h4 className="text-center page_header"> Dashboard </h4>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
							<h2 className="text-orange"> {this.state.time.toLocaleTimeString()} </h2>
						</div>
					</div>
				</div>


				{adminInfo && ((adminInfo.user_role != 6) && (adminInfo.user_role != 11) )? (
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
						<div className="row">

							{/* Users graph card */}
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
								<div className="card p-3">
									<h5 className="text-blue"><b> Total Users </b></h5>
									
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" style={{opacity:'1'}}>
											<PieChart slices={user_graph} />
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
											<h6 className="small_text mt-3"> <i className="fa fa-square mr-1"></i> Registered Users - {users} </h6>
											<h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#386FA4'}}></i> Active Users - {users} </h6>
											{/* <h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#88CCF1'}}></i> Unpaid Users - {users.unpaid} </h6> */}
											<h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#EF626C'}}></i> Inactive Users - {users} </h6>
											<Link to="/users"><button className="btn btn-info btn-sm ml-0"><b> Users</b></button></Link>
										</div>
									</div>

								</div>
							</div>


							{/* Last 7 days users graph card */}
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
								<div className="card p-3">
									<h5 className="text-blue"><b> Users <span className="small_text"><b> (Last 7 days) </b></span> </b></h5>
									
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" style={{opacity:'1'}}>
											<PieChart slices={week_users_graph} />
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
											<h6 className="small_text mt-3"> <i className="fa fa-square mr-1"></i> Registered Users - {week_users} </h6>
											<h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#386FA4'}}></i> Active Users - {week_users} </h6>
											{/* <h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#88CCF1'}}></i> Unpaid Users - {week_users.unpaid} </h6> */}
											<h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#EF626C'}}></i> Inactive Users - {week_users} </h6>
											<Link to="/users"><button className="btn btn-info btn-sm ml-0"><b> Users</b></button></Link>
										</div>
									</div>

								</div>
							</div>

							{/* Last 30 days users graph card */}
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
								<div className="card p-3">
									<h5 className="text-blue"><b> Users <span className="small_text"><b> (Last 30 days) </b></span> </b></h5>

									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" style={{opacity:'1'}}>
											<PieChart slices={month_users_graph} />
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
											<h6 className="small_text mt-3"> <i className="fa fa-square mr-1"></i> Registered Users - {month_users} </h6>
											<h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#386FA4'}}></i> Active Users - {month_users} </h6>
											{/* <h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#88CCF1'}}></i> Unpaid Users - {month_users.unpaid} </h6> */}
											<h6 className="small_text mt-1"> <i className="fa fa-square mr-1" style={{color : '#EF626C'}}></i> Inactive Users - {month_users} </h6>
											<Link to="/users"><button className="btn btn-info btn-sm ml-0"><b> Users</b></button></Link>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				)
				: (
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
						<div className="row justify-content-center">
							{(dashboardData && dashboardData.classes && dashboardData.classes.length > 0) && (
								dashboardData.classes.map((classe, index) => (
									<div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3 px-4">
										<div className="card p-4 text-left">
											<h5 className="text-orange"><b>{classe.class_title}</b></h5>
											<h6 className="text-orange"><b>{'Batch: ' + classe.batch_name}</b></h6>
											<h6 className="text-blue"><b>{'Duration: ' + classe.duration + ' minutes'}</b></h6>
											<h6 className="text-blue"><b>{'Days: ' + classRepeatTypes[classe.class_repeat_type]}</b></h6>
											<h6 className="text-blue"><b>{'Start Date: ' + DateFormat(classe.start_time, 2)}</b></h6>
											<h6 className="text-blue"><b>{'Start Time: ' + TimeFormat(classe.start_time.slice(11, 19))}</b></h6>
											<h6 className="text-blue"><b>{'End Date: ' + DateFormat(classe.end_time, 2)}</b></h6>

											{(classe.presenter_url && classe.presenter_url !== '') && 
												<div className="text-center mt-2">
													<a href={classe.presenter_url} target="_blank">
														<button type="button" className="btn btn-primary btn-lg" title="Edit"><b>START PRESENTING</b></button>
													</a>
												</div>
											}
										</div>
									</div>
								))
							)}
						</div>
					</div>
				)}
			</div>
		);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}
}

const mapStatesToProps = (state) => {
	return {
		isAuth: state.admin.isAuth,
		adminInfo: state.admin.adminInfo,
		dashboardData: state.dashboard.dashboardData,
	}
}

const mapActionsToProps = {
	onGetAdminDashboard: getAdminDashboard
}

export default connect(mapStatesToProps, mapActionsToProps) (Dashboard);