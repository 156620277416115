import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';

export const getAllQuestions = (exam_id, params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllQuestions/' + exam_id + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetQuestionsSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewQuestion = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/AddNewQuestion', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getQuestionDetails = (question_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditQuestion/' + question_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetQuestionSuccess(response.data.data));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateQuestion = (question_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateQuestion/' + question_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteQuestion = (test_id, question_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/DeleteQuestion/' + question_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllQuestions(test_id, ''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetQuestionsSuccess = (data) => {
    return {
        type : GET_QUESTIONS_SUCCESS,
        payload : {
            questions : data
        }
    }
}

export const onGetQuestionSuccess = (question) => {
    return {
        type : GET_QUESTION_SUCCESS,
        payload : {
            question : question
        }
    }
}