import { GET_BATCHES_SUCCESS, GET_BATCH_SUCCESS } from '../actions/Batches';

const initialState = {
    batches : [],
    batch : {}
}

export default function Batch_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_BATCH_SUCCESS :
            return Object.assign({}, state, { batch : payload.batch });

        case GET_BATCHES_SUCCESS :
            return Object.assign({}, state, { batches : payload.batches });

        default :
            return state;
    }
}
