import React from 'react';
import './Achievers.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAchieverDetails, updateAchiever } from '../../actions/Achievers';
import swal from 'sweetalert';

class Achievers extends React.Component {
  constructor(props){
    super(props);
    this.state = { achiever_id : this.props.match.params.achiever_id, images : { achiever_image_path : '' } };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetAchieverDetails(this.state.achiever_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let achiever = this.props.achiever;
    let images = this.state.images;
    achiever[name] = value;

    if(e.target.name === 'achiever_image_path'){
      images[name] = URL.createObjectURL(e.target.files[0]);
      achiever[name] = '';
    }

    this.setState({ achiever : achiever, images : images });
  }

  onUpdateAchieverSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this achiever?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_achiever_form'));
        this.props.onUpdateAchiever(this.state.achiever_id, formData);
      }
    });
  }

  render() {

    const { achiever, base_url } = this.props;
    const { images } = this.state;
    let image_preview = '';

    if(achiever && achiever.achiever_image_path){
      image_preview = <img src={base_url + achiever.achiever_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.achiever_image_path){
      image_preview = <img src={images.achiever_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/achievers">Achievers</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Achiever</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Achiever </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/achievers/add_achiever"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewAchiever}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit achiever form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="edit_achiever_form" onSubmit={this.onUpdateAchieverSubmit} autoComplete="off">

                <div className="form-group">
                  <label className="small_text">Achiever Name * : </label>
                  <input type="text" name="achiever_name" placeholder="Achiever Name *" value={achiever.achiever_name ? achiever.achiever_name : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text">Achiever Batch * : </label>
                  <input type="text" name="batch_number" placeholder="Achiever Batch *" value={achiever.batch_number ? achiever.batch_number : ''} onChange={this.onChangeHandler} className="form-control"/>
                </div>

                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <label className="small_text">Profile Pic * : </label>
                    <input type="file" name="achiever_image_path" onChange={this.onChangeHandler} className="form-control"/>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                    {image_preview}
                  </div>
                </div>

                <div className="form-group">
                  <label className="small_text">Achiever Video Path * : </label>
                  <input type="text" name="achiever_video_path" placeholder="Achiever Video Path *" value={achiever.achiever_video_path ? achiever.achiever_video_path : ''} onChange={this.onChangeHandler} className="form-control"/>
                </div>

                <div className="form-group">
                  <label className="small_text">Achiever Message * : </label>
                  <textarea rows={5} name="achiever_message" placeholder="Achiever Message *" value={achiever.achiever_message ? achiever.achiever_message : ''} onChange={this.onChangeHandler} className="form-control"/>
                </div>

                <div className="form-group">
                  <label className="small_text">Status * : </label>
                  <select name="status" className="form-control" value={achiever.status ? achiever.status : 1} onChange={this.onChangeHandler} required>
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Achiever </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    achiever : state.achiever.achiever,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  onGetAchieverDetails : getAchieverDetails,
  onUpdateAchiever : updateAchiever
};

export default connect(mapStatesToProps, mapActionsRoProps) (Achievers);
