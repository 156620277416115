import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_DELIVERY_CHARGES_SUCCESS = 'GET_DELIVERY_CHARGES_SUCCESS';
export const GET_DELIVERY_CHARGE_SUCCESS = 'GET_DELIVERY_CHARGE_SUCCESS';

export const getAllDeliveryCharge = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getAllDeliveryCharge' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetDeliveryChargesSuccess(response.data.data));

                    //console.log("testing", response.data);
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}


export const getDeliveryCharge = (params) => {

    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getDeliveryCharge' + params)
            .then(response => {

                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {

                    dispatch(onGetDeliveryChargesSuccess(response.data.data));
                    console.log("testing****", response.data);
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    console.log("testing****11111");
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                console.log("testing****3333"+error);
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}



export const addDeliveryCharge = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/addDeliveryCharge', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {

                    //console.log("testing111", response);
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {    //console.log("testing222", response);
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getDeliveryChargeDetail = (delivery_charge_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getDeliveryChargeDetail/' + delivery_charge_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){

                    console.log("hhhhhh",response);
                    dispatch(onGetDeliveryChargeSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateDeliveryCharge = (delivery_charge_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/updateDeliveryCharge/' + delivery_charge_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const deleteDeliveryCharge = (delivery_charge_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/deleteDeliveryCharge/' + delivery_charge_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllDeliveryCharge(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};




export const onGetDeliveryChargesSuccess = (data) => {
    return {
        type : GET_DELIVERY_CHARGES_SUCCESS,
        payload : {
            deliveryCharge : data
        }
    }
}

export const onGetDeliveryChargeSuccess = (data) => {
    return {
        type : GET_DELIVERY_CHARGE_SUCCESS,
        payload : {
            deliveryCharge : data
        }
    }
}




