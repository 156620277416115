import React from 'react';
import './FailedTransaction.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFailedOrdersDetail, addFaildOrder } from '../../actions/FailedTransaction';
import { onDisplayMessage, onDisplayError, onLoadingTrue, onLoadingFalse } from '../../actions/Login';

import swal from 'sweetalert';
import $ from 'jquery';
import {getCoursesWithBatches} from '../../actions/Courses';
import Server from '../../utils/Server';


class EditAdmission extends React.Component {
  constructor(props){
    super(props);
    this.state = {transaction_id : this.props.match.params.transaction_id, courseType: '', courseID: 0, userID: 0, error: '', courses: [], batches: [], batchID: 0, batchData: {}, totalAmount: 0, balanceAmount: 0, orderPrice: 0, paymentType: '', activePayment: {}};

    //this.state = { transaction_id : this.props.match.params.transaction_id };
    //this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetFailedOrderDetail(this.state.transaction_id);
  }

  /*onChangeHandler = (e) => {
    const { name, value } = e.target;
    let admission = this.props.admission;
    let profile_pic = this.state.profile_pic;
    
    if(e.target.name === 'profile_pic'){
      profile_pic = URL.createObjectURL(e.target.files[0]);
    } else {
      admission[name] = value;
    }

    this.setState({ admission : admission, profile_pic: profile_pic });
  }*/



  onChangeHandler = (type, event) => {
    const {courses, batches, userData} = this.state;
    const {name, value} = event.target;

    console.log("testinggg",type);
    
    if(type === 0) {

      if(value.length !== 10) {
        this.setState({userData: {name: '', email: '', mobile_number: value, dob: '', gender: 'Male', referral: '', communication_address: '', permanent_address: ''}, userID: 0});
      } else {
        // this.props.onGetBatchPayments(value);
        // if(users && users.length > 0) {
        //   var userExists = users.findIndex(x => x.mobile_number == value);

        //   // If already user exists else add as new user
        //   if(userExists >= 0) {
        //     this.setState({userData: users[userExists], userID: users[userExists].id});
        //   } else {
        //     userData[name] = value;
        //     this.setState({userData: userData, userID: 0});
        //   }
        // } else {
        //   userData[name] = value;
        //   this.setState({userData: userData, userID: 0});
        // }
      }
    } else if(type === 1) {
      userData[name] = value;
      this.setState({userData: userData, userID: 0});
    } else if(type === 2) {
      this.handleGetCourseBatches(value, 0, 0, {});
    } else if(type === 3) {
      var course_index = courses.findIndex(x => x.id == value);
      this.setState({courseID: value, batches: courses[course_index].batches, batchID: 0, batchData: {}, totalAmount: 0});
    } else if(type === 4) {
      var batch_index = batches.findIndex(x => x.id == value);
      this.setState({batchID: value, batchData: batches[batch_index], totalAmount: batches[batch_index].total_fees});
    } else if(type === 5) {
      this.setState({paymentType: value});
    } else if(type === 6) {
      this.setState({orderPrice: value});
    }
  }

  handleGetCourseBatches = async (courseType, courseID, batchID, activePayment) => {
    this.setState({courseType: courseType, courseID: 0, batches: [], batchID: 0, batchData: {}, totalAmount: 0, activePayment: activePayment});

    this.props.onLoadingTrue();
    await Server.get('/GetCourseWithBatches/' + courseType)
      .then(response => {
          this.props.onLoadingFalse();
          if(response.data.statuscode === 200){
              const coursesData = response.data.data;

              console.log("testing courrse", coursesData);
              // this.props.onGetCoursesSuccess(response.data.data);
              this.setState({courses: coursesData}, () => {
                window.scrollTo(0, 0) // Move to top

                // Show courses, batch, fees based on selection in payment history
                if(courseID !== '') {
                  var course_index = coursesData.findIndex(x => x.id == courseID);

                  if(course_index >= 0) {
                    var batchesData = coursesData[course_index].batches;
                    this.setState({courseID: courseID, batches: coursesData[course_index].batches, batchID: 0, batchData: {}, totalAmount: 0});

                    var batch_index = batchesData.findIndex(x => x.id == batchID);
                    if(batch_index >= 0) {
                      var batchData = batchesData[batch_index];
                      this.setState({batchID: batchID, batchData: batchData, totalAmount: batchData.total_fees});
                    }
                  }

                }
              });
          }
          else{
              this.props.onDisplayError(response.data.message);
          }
      })
      .catch(err => {
        this.props.onLoadingFalse();
        this.props.onDisplayError('Failed, try again later');
      });
  }


  onUpdateOrderSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this transaction?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_order_form'));
        this.props.onAddFaildOrder( formData);
      }
    });
  }

  render() {

    const { failedOrderDetail } = this.props;
    const { userID, userData, courses, batches, batchData, batchID, error, totalAmount, balanceAmount, paymentType, orderPrice, userPayments, userBatches, courseType, courseID, activePayment} = this.state;

    //const {userID, userData, courses, batches, batchData, , error, totalAmount, balanceAmount, paymentType, orderPrice, userPayments, userBatches, courseType, , activePayment} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/failedTransaction">Failed Transaction</Link> </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Faile Transaction Detail </h4>
            </div>
            
          </div>
    
          {/*  Faile Transaction Detail */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="edit_order_form" onSubmit={this.onUpdateOrderSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Order ID : </label>
                      <input type="text" name="name" value={failedOrderDetail.rzpay_order_id ? failedOrderDetail.rzpay_order_id : ''}  className="form-control" autoFocus disabled/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Order Value : </label>
                      <input type="text" name="name" value={failedOrderDetail.order_price ? failedOrderDetail.order_price : ''}  className="form-control" autoFocus disabled/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Order User Nname : </label>
                      <input type="text" name="name" value={failedOrderDetail.user_name ? failedOrderDetail.user_name : ''}  className="form-control" autoFocus disabled/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Order User Mmobile : </label>
                      <input type="text" name="name" value={failedOrderDetail.mobile_number ? failedOrderDetail.mobile_number : ''}  className="form-control" autoFocus disabled/>
                    </div>


                    <div className="form-group">
                      <label className="small_text"> Course Type * : </label>
                      <select className="form-control" value={courseType} onChange={(e) => this.onChangeHandler(2, e)} required>
                        <option value=""> Select Course Type </option>
                        <option value="0"> Offline Course </option>
                        <option value="1"> Online Course </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course * : </label>
                      <select className="form-control" value={courseID} onChange={(e) => this.onChangeHandler(3, e)} required>
                        <option value=""> Select Course </option>

                        {
                          courses && courses.length > 0 && 
                            courses.map((course, index) => (
                              <option key={index} value={course.id}>{course.course_name}</option>
                            ))
                        }
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Batches * : </label>
                      <select className="form-control" value={batchID} onChange={(e) => this.onChangeHandler(4, e)} required>
                        <option value=""> Select Batch * </option>

                        {
                          batches && batches.length > 0 && 
                            batches.map((batch, index) => (
                              <option key={index} value={batch.id}>{batch.batch_name}</option>
                            ))
                        }
                      </select>
                    </div>

                  <input type="hidden" name="rzpay_order_id" value={failedOrderDetail.rzpay_order_id ? failedOrderDetail.rzpay_order_id : ''} />
                  <input type="hidden" name="order_price" value={failedOrderDetail.order_price ? failedOrderDetail.order_price : ''} />
                  <input type="hidden" name="order_by" value={failedOrderDetail.ordered_by ? failedOrderDetail.ordered_by : ''} />
                 <input type="hidden" name="batch_id" value={batchID} />


                    </div>
                  </div>

            
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Admission </button>
                </div>
              </form>

            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading : state.admin.isLoading, 
        base_url: state.admin.base_url,
        courses: state.course.courses,
        failedOrderDetail : state.failedTransaction.failedOrderDetail
    }
};

const mapActionsRoProps = {
    onGetFailedOrderDetail : getFailedOrdersDetail,
    onGetCoursesWithBatches: getCoursesWithBatches,
    onDisplayMessage: onDisplayMessage,
    onDisplayError: onDisplayError,
    onLoadingTrue: onLoadingTrue,
    onLoadingFalse: onLoadingFalse,
    onAddFaildOrder : addFaildOrder,


};

export default connect(mapStatesToProps, mapActionsRoProps) (EditAdmission);