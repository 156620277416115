import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllClasses, deleteClass } from '../../actions/Classes';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';
import * as moment from 'moment'

moment.locale();  // en

class Classes extends React.Component {
  constructor(props){
    super(props);
    const {params} = props.match;
    this.state = { activePage : 1, batch_id : params.batch_id };
    this.deleteClass = this.deleteClass.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
    this.props.onGetClasses(this.state.batch_id);
  }

  deleteClass = (class_id) => {
    swal({
      title: "Are you sure?",
      text: "To cancel and delete this class?",
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData();
        formData.append('class_id', class_id);

        this.props.onDeleteClass(formData);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetClasses(this.state.batch_id, '?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value);
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetClasses(this.state.batch_id, '?' + params);
  }

  render() {

    const { classes, total_count, page_count, page_size, base_url , adminInfo} = this.props;
    const { batch_id } = this.state;

    let classes_display = {};
    let pagination_display = '';

    let classRepeatTypes = ['', 'Daily (All 7 Days)', 'Monday to Saturday', 'Monday to Friday', 'Weekly Once', 'Monthly Once'];
    
    if((classes && classes.length > 0 ))
    {
      //classes_display = classes.map((classe, index) => (
        var index = 0;
        classes_display =  classes.map((classe) => {

          index++;

        const startTime =  classe.start_time;
        const endTime =  classe.end_time;

        const date11 = new Date();
        var dateTemp = moment(date11).format("YYYY-MM-DD");


        const date1 = dateTemp + " "+ startTime;
        const date2 = dateTemp + " "+ endTime;

        var start = moment(date1);
        var end = moment(date2);
        var duration = moment.duration(end.diff(start));

        duration = duration/1000;
        var minutes = Math.round(duration/60);


        var curentDate = moment(date11).format("YYYY-MM-DD HH:mm:ss");
        var start = moment(curentDate);
        var end = moment(date1);
        var startDifference = moment.duration(end.diff(start));

        startDifference = startDifference / 1000;
        var startDifferenceMinute = startDifference / 60;

        const end2 = moment(dateTemp+" "+endTime);
        const duration2 = moment.duration(end2.diff(start))
        //var classEndDuration = duration2.asMinutes()

        var classEndDuration = duration2 / 1000;
        classEndDuration = classEndDuration / 60;


        if((startDifferenceMinute < 5 ) && (classEndDuration > 0 )) {

          return (
            <tr key={index}>
              <td>{index}</td>
              <td>{classe.course_name}</td>
              <td>{classe.batch_name}</td>
              <td>{classe.class_title}</td>
              <td>{classe.faculty_name}</td>

                <td>{ moment( classe.start_date ).format('DD-MM-YYYY') }</td>
              <td>{ moment( classe.end_date ).format('DD-MM-YYYY') }</td>
              <td>{ moment( dateTemp+" "+classe.start_time ).format('h:mm a') }</td>
              <td>{ moment( dateTemp+" "+classe.end_time ).format('h:mm a') }</td>

              <td>{minutes + ' minutes'}</td>
              <td>{classRepeatTypes[classe.class_repeat_type]}</td>
              <td><a href={classe.attendee_url} target="_blank"><button type="button" className="btn btn-primary btn-sm" title="Start"><b>Start</b></button></a></td> 
              <td><Link to={'/classes/' + batch_id + '/edit_class/' + classe.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
              {/*<td><button className="icon_button" title="Delete" onClick={() => this.deleteClass(classe.class_id)}> <i className="fa fa-close text-danger"></i> </button></td>*/}
            </tr>
          )


        }else {

          return (
            <tr key={index}>
              <td>{index}</td>
              <td>{classe.course_name}</td>
              <td>{classe.batch_name}</td>
              <td>{classe.class_title}</td>
              <td>{classe.faculty_name}</td>

               <td>{ moment( classe.start_date ).format('DD-MM-YYYY') }</td>
              <td>{ moment( classe.end_date ).format('DD-MM-YYYY') }</td>
              <td>{ moment( dateTemp+" "+classe.start_time ).format('h:mm a') }</td>
              <td>{ moment( dateTemp+" "+classe.end_time ).format('h:mm a') }</td>


              <td>{minutes + ' minutes'}</td>
              <td>{classRepeatTypes[classe.class_repeat_type]}</td>
              <td>***</td>
              <td><Link to={'/classes/' + batch_id + '/edit_class/' + classe.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
              {/*<td><button className="icon_button" title="Delete" onClick={() => this.deleteClass(classe.class_id)}> <i className="fa fa-close text-danger"></i> </button></td>*/}
            </tr>
          )

        }  

       

      })

    }
    else
    {
      classes_display = <tr><td colSpan="13" className="text-center"> No classes found.</td></tr>
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} classes. </p> */}
          <p className="small_text d-inline ml-2"> Showing {classes.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} classes. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {classes.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/batches">Batches</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Classes</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Classes </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/classes/" + batch_id + "/add_class"}><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

           {/* filters, sort and search container */}
           {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Classes A-Z</option>
                    <option value="2">Classes Z-A</option>
                    <option value="3">Newest First</option>
                    <option value="4">Oldest First</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input type="hidden" name="page_size" id="page_size" value={page_size}/>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by class name" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all classes table */}
          <table className="table table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No.</th>
                <th className="bg-blue text-light" width="10%">Course</th>
                <th className="bg-blue text-light" width="10%">Batch</th>
                <th className="bg-blue text-light" width="10%">Class</th>
                <th className="bg-blue text-light" width="10%">Presenter</th>

                <th className="bg-blue text-light" width="15%">Start Date </th>
                <th className="bg-blue text-light" width="15%">End Date </th>
                <th className="bg-blue text-light" width="15%">Start Time </th>
                <th className="bg-blue text-light" width="15%">End Time </th>
                <th className="bg-blue text-light" width="5%">Duration</th>

                <th className="bg-blue text-light" width="10%">Repeat Type</th>
                 <th className="bg-blue text-light" width="15%">Start</th> 
                <th className="bg-blue text-light" width="10%">Edit</th> 

              </tr>
            </thead>
            <tbody>

              {/* Display all the classes */}
              {classes_display}

            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    classes : state.classe.classes,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url: state.admin.base_url,
    adminInfo: state.admin.adminInfo,

  }
};

const mapActionsRoProps = {
  onGetClasses : getAllClasses,
  onDeleteClass : deleteClass
};

export default connect(mapStatesToProps, mapActionsRoProps) (Classes);
