// Items -> Array of items,
// Item -> Based on sorting should work
// Data type -> 1 is for String and 2 is for number
// Type -> 1 is for Ascending and 2 id for descending order

export const SortItems = (items, item = '', dataType = 1, type = 1) => {
    // Type 1 is for String
    if (dataType === 1) {
        items.sort(function(a, b){
            var x = a[item].toLowerCase();
            var y = b[item].toLowerCase();
            if (x < y) {
                return type === 1 ? 1 : -1;
            }
            if (x > y) {
                return type === 1 ? -1 : 1;
            }
            return 0;
        });
    } else {
        // Type 2 is for number
        items.sort(function(a, b){
            return type === 1 ? (a[item] - b[item]) : (b[item] - a[item]);
        });
    }
}

// Items -> Array of items,
// Search Items -> Array of columns to be searched
// Key -> Key to be searched
export const SearchItems = (items, searchItems = [], key = '') => {
    var searchKey = key.toLowerCase();
    // var searchConditions = [];

    // if(searchItems.length > 0) {
    //     searchItems.map((item, index) => {
    //         // if(index === 0) {
    //         //     searchConditions = item[searchItems[index]].isNaN() ? item[searchItems[index]].toString().includes(searchKey) : item[searchItems[1]].toLowerCase().includes(searchKey.toLowerCase());
    //         // } else {
    //             searchConditions.push(item[searchItems[index]].isNaN() ? item[searchItems[index]].toString().includes(searchKey) : item[searchItems[1]].toLowerCase().includes(searchKey.toLowerCase()));
    //         // }
    //     });
    // }

    // searchConditions.toString();

    return items.filter(item => (
        item[searchItems[0]].toLowerCase().includes(searchKey)
        ||
        item[searchItems[1]].toLowerCase().includes(searchKey)
        ||
        item[searchItems[2]].toString().includes(searchKey)
        // searchConditions.map(() =>)
        // searchConditions.replace(/,/g, ' || ')
    ));
}