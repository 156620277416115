import React from 'react';
import './Exams.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllExams, deleteExam } from '../../actions/Exams';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';
import * as moment from 'moment'
moment.locale();  // en

class Exams extends React.Component {
  constructor(props){
    super(props);
    this.state = {activePage : 1, testTypes: ["--", "Online", "Offline", "Mock"]};
    this.deleteExam = this.deleteExam.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
	  this.props.onGetExams('');
  }

  deleteExam = (exam_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this exam permanently?",
      dangerMode: true,
	    buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteExam(exam_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetExams('?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value); 
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetExams('?' + params);
  }


  render() {

    const { exams, total_count, page_count, page_size } = this.props;
    const {testTypes} = this.state;
    let exams_display = {};
    let pagination_display = ''

    if(exams && exams !== '' && exams.length > 0)
    {
      exams_display = exams.map((exam, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{exam.test_name}</td>
          <td>{exam.course_name}</td>
          <td>{testTypes[exam.test_type]}</td>
          <td>{ moment( exam.test_date ).format('DD-MM-YYYY')}</td>
          <td>{ exam.test_duration} Minutes</td>
          <td>{exam.status == '1' ? 'Active' : 'Inactive'}</td>
          <td><Link to={'/questions/' + (exam.test_name + ' - ' + exam.course_name) + '/' + exam.id}><button className="icon_button btn btn-primary btn-sm m-0 px-2" title="View"> View </button></Link></td>
          <td><Link to={'/exams/edit_exam/' + exam.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
          {/* <td><button className="icon_button" title="Delete" onClick={() => this.deleteExam(exam.id)}> <i className="fa fa-trash text-danger"></i> </button></td> */}
        </tr>
      ))
    }
    else
    {
      exams_display = <tr><td colSpan="9" className="text-center"> No exams found.</td></tr>
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} exams. </p> */}
          <p className="small_text d-inline ml-2"> Showing {exams.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} exams. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {exams.length} results. </p>
    } 

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Exams</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Exams </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/exams/add_exam"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Exams A-Z</option>
                    <option value="4">Exams Z-A</option>
                    <option value="5">Countries A-Z</option>
                  </select>
                </div>
              </div> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Filter by : </label>
                    <select name="test_type_id" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="0">All Exams</option>
                    <option value="1">Online Exams</option>
                    <option value="2">Offline Exams</option>
                    <option value="3">Mock Exams</option>
                  </select>
                </div>
              </div>
              
              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, code or country" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div> */}
            </div>
          </form>

          {/* Display all exams table */}
          <table className="table table-responsive table-bordered table-striped mt-3">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No</th>
                <th className="bg-blue text-light" width="35%">Subject</th>
                <th className="bg-blue text-light" width="15%">Course</th>
                <th className="bg-blue text-light" width="12%">Type</th>
                <th className="bg-blue text-light" width="15%">Starts</th>
                <th className="bg-blue text-light" width="15%">Duration</th>
                <th className="bg-blue text-light" width="8%">Status</th>
                <th className="bg-blue text-light" width="10%">Questions</th>
                <th className="bg-blue text-light" width="10%">Edit</th>
                {/* <th className="bg-blue text-light" width="5%">Delete</th> */}
              </tr>
            </thead>
            <tbody>

              {/* Display all the exams */}

              {exams_display}

            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    exams : state.exam.exams,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size
  }
};

const mapActionsRoProps = {
  onGetExams : getAllExams,
  onDeleteExam : deleteExam
};

export default connect(mapStatesToProps, mapActionsRoProps) (Exams);
