import { GET_BOOKINGS_SUCCESS, GET_BOOKING_SUCCESS,GET_HOSTEL_PDF_SUCCESS } from '../actions/HostelBookings';

const initialState = {
    bookings : [],
    booking : {},
    hostelpdfData : {}
}

export default function Booking_reducer (state = initialState, { type, payload } ) {
    switch (type)
    {
        case GET_BOOKING_SUCCESS :
            return Object.assign({}, state, { booking : payload.booking });

        case GET_BOOKINGS_SUCCESS :
            return Object.assign({}, state, { bookings : payload.bookings });
            
        case GET_HOSTEL_PDF_SUCCESS:
            return Object.assign({}, state, { hostelpdfData : payload.hostelpdfData })

        default :
            return state;
    }

}
