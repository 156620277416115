import React from 'react';
import './Subscription.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllSubscriptions, deleteSubscription } from '../../actions/Subscription';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';

class Subscription extends React.Component {

  constructor(props){
    super(props);
    const {params} = props.match;
    this.state = { activePage : 1, book_id : params.book_id };
    this.deleteBookSubscription = this.deleteBookSubscription.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
    this.props.getSubscriptions( this.state.book_id );
  }

  deleteBookSubscription = (subscription_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this book permanently?",
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.deleteSubscription(subscription_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.getSubscriptions(this.state.book_id,'?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value); 
    }
    var params = $('#search_filter_form').serialize();
    this.props.getSubscriptions(this.state.book_id,'?' + params);
  }

  render() {

    const { subscriptions, total_count, page_count, page_size, base_url } = this.props;
    const { book_id } = this.state;

    let books_display = {};
    let pagination_display = '';

    if(subscriptions && subscriptions !== '' && subscriptions.length > 0)
    {
      books_display = subscriptions.map((subscription, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{subscription.book_name}</td>
          <td>{subscription.book_type == '0' ? 'E-Book' : subscription.book_type == '1' ? 'Publication': 'Subscription'}</td>
          <td>{subscription.book_price == '0' ? 'Free' : 'Rs. ' + subscription.book_price}</td>
          <td>{subscription.subscription_type}</td>
          <td>{subscription.subscription_duration + ' '+subscription.subscription_type}</td>
          <td>{subscription.subscription_duration * subscription.book_price }</td>
          <td>{subscription.subscription_value }</td>

          <td>{subscription.subscription_status == '1' ? 'Active' : 'Inactive'}</td>
          <td><Link to={'/subscription/' + book_id + '/edit_subscription/' + subscription.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>

          <td><button className="icon_button" title="Delete" onClick={() => this.deleteBookSubscription(subscription.id)}> <i className="fa fa-trash text-danger"></i> </button></td>
        </tr>
      ))
    }
    else
    {
      books_display = <tr><td colSpan="8" className="text-center"> No subscription found.</td></tr>
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} books. </p> */}
          <p className="small_text d-inline ml-2"> Showing {subscriptions.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} books. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {subscriptions.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/books">Book</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Classes</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Subscription </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to={"/subscription/" + book_id + "/add_subscription"}><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>

            </div>
          </div>

          {/* filters, sort and search container */}
          {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Books A-Z</option>
                    <option value="4">Books Z-A</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input type="hidden" name="page_size" id="page_size" value={page_size}/>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by book, code or currency" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all books table */}
          <table className="table table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl. No</th>
                <th className="bg-blue text-light" width="15%">Book</th>
                <th className="bg-blue text-light" width="10%">Book Type</th>
                <th className="bg-blue text-light" width="10%">Unit Price</th>
                <th className="bg-blue text-light" width="10%">Subscription Type</th>
                <th className="bg-blue text-light" width="10%">Subscription Duration</th>
                <th className="bg-blue text-light" width="10%">Actual Price</th>
                <th className="bg-blue text-light" width="10%">Offer Price</th>
                <th className="bg-blue text-light" width="10%">Status</th>
                <th className="bg-blue text-light" width="10%">Edit</th>
                {<th className="bg-blue text-light" width="5%">Delete</th>}
              </tr>
            </thead>
            <tbody>

              {/* Display all the books */}

              {books_display}

            </tbody>
          </table>
          
          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
    subscriptions : state.subscription.subscriptions,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  getSubscriptions : getAllSubscriptions,
  deleteSubscription : deleteSubscription
};

export default connect(mapStatesToProps, mapActionsRoProps) (Subscription);
