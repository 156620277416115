import { ADMIN_LOGIN_FAILURE, ADMIN_LOGIN_SUCCESS, SET_CURRENT_USER, HIDE_MESSAGE, LOADING_TRUE, LOADING_FALSE, DISPLAY_MESSAGE, DISPLAY_ERROR, ADMIN_LOGOUT_SUCCESS, ADMIN_LOGOUT_FAILURE } from '../actions/Login';

const auth = localStorage.getItem('token') ? true : false;

const initialState = {
    isAuth : auth,
    adminInfo : {},
    message : '',
    isLoading : false,
    error : '',
    api_headers : {},
    base_url : 'https://api.classicedu.org/public/'
};

export default function Admin_login_reducer (state = initialState, { type, payload } ) {

    switch(type)
    {
        case LOADING_TRUE :
            return Object.assign({}, state, { isLoading : true });

        case LOADING_FALSE :
            return Object.assign({}, state, { isLoading : false });
        
        case DISPLAY_MESSAGE :
            return Object.assign({}, state, { message : payload.message });

        case DISPLAY_ERROR :
            return Object.assign({}, state, { error : payload.error });

        case SET_CURRENT_USER :
            return Object.assign({}, state, { isAuth : true, adminInfo : payload.adminInfo });

        case ADMIN_LOGIN_SUCCESS :
            return Object.assign({}, state, { isAuth : false, adminInfo : {}, message : payload.message });

        case ADMIN_LOGIN_FAILURE :
            return Object.assign({}, state, { error : payload.error, adminInfo : {} });

        case ADMIN_LOGOUT_SUCCESS :
            return Object.assign({}, state, { isAuth : false, message : payload.message, adminInfo : {} });
        
        case ADMIN_LOGOUT_FAILURE :
            return Object.assign({}, state, { error : payload.error });

        case HIDE_MESSAGE :
            return Object.assign({}, state, { message : '', error : '' });

        default :
            return state;
    }
    
}