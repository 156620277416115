import React from 'react';
import './Users.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewUser } from '../../actions/Users';
import { onDisplayMessage, onDisplayError, onLoadingTrue, onLoadingFalse } from '../../actions/Login';
import swal from 'sweetalert';
import $ from 'jquery';
import server from '../../utils/Server';

class AddUser extends React.Component {

  constructor(props){
    super(props);
    this.state = { profile_pic: '', error: '', message: '' };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    this.setState({ profile_pic: URL.createObjectURL(e.target.files[0]) });
  }

  handleChangeMobileNumber = (e) => {
    e.preventDefault();

    if(e.target.value === '' || e.target.value.length !== 10) {
      this.props.onDisplayError('Enter valid 10 digit mobile number');
    } else {
      this.props.onLoadingTrue();
      server.get('/AdminUserExists?mobile_number=' + e.target.value)
        .then(response => {
          this.props.onLoadingFalse();
          this.props.onDisplayError(response.data.message);
          if(response.data.statuscode === 200)
            this.setState({message: '', error: response.data.message});
          else
            this.setState({message: 'User not yet registered', error: ''});
        })
        .catch(error => {
          this.props.onLoadingFalse();
          this.props.onDisplayError('Failed, Try again later');
        });
    }
  }

  onAddUserSubmit = (e) => {
    e.preventDefault();

    if($('#password').val().length < 8)
    {
      this.props.onDisplayError('Password must have atleast 6 characters');
    }
    else if($('#password').val() !== $('#confirm_password').val())
    {
      this.props.onDisplayError('Confirm password must be same as password');
    }
    else
    {
      swal({
        title: "Are you sure?",
        text: "To add this new user?",
        buttons: ['Cancel', 'Add'],
      })
      .then(willDelete => {
        if (willDelete) {
          var formData = new FormData(document.getElementById('add_user_form'));
          this.props.onAddNewUser(formData);
        }
      });
    }
  }

  render() {
    const { profile_pic, error, message } = this.state;

    let image_preview = '';

    if(profile_pic){
      image_preview = <img src={profile_pic} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/users">Users</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add User</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add User </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/admissions/add_admission/1"><button type="button" className="btn btn-primary btn-sm">Add Admission <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Add new user form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_user_form" onSubmit={this.onAddUserSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text"> Mobile Number * : </label>
                      <input type="number" name="mobile_number" placeholder="Mobile Number *" className="form-control" autoFocus onBlur={(e) => this.handleChangeMobileNumber(e)} required/>
                      {error !== '' ? <span className="small_text text-danger">{error}</span> : ''}
                      {message !== '' ? <span className="small_text text-success">{message}</span> : ''}
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Full Name : </label>
                      <input type="text" name="name" placeholder="Full Name *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID * : </label>
                      <input type="email" name="email" placeholder="Email ID *" className="form-control" required />
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Date of Birth : </label>
                      <input type="date" name="dob" placeholder="Date of Birth" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Referral Code : </label>
                      <input type="text" name="referral" placeholder="Referral Code" className="form-control"/>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Gender : </label>
                        <select name="gender" className="form-control" required>
                          <option value=""> Select Gender </option>
                          <option value="Male"> Male </option>
                          <option value="Female"> Female </option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Status : </label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Profile Photo: </label>
                        <input type="file" name="profile_pic" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {image_preview}
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <label className="small_text">Communication Address : </label>
                      <textarea rows={5} name="communication_address" placeholder="Communication Address" className="form-control"/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Permanent Address : </label>
                      <textarea rows={5} name="permanent_address" placeholder="Permanent Address" className="form-control"/>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="small_text"> New Password * : </label>
                        <input type="password" id="password" name="password" placeholder="Password" className="form-control" required/>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="small_text"> Confirm Password * : </label>
                        <input type="password" id="confirm_password" name="confirm_password" placeholder="Confirm Password" className="form-control" required/>
                      </div>
                    </div>

                    <input type="hidden" name="user_role" value={1} />
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add User </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading: state.admin.isLoading,
        message: state.admin.message,
        error: state.admin.error,
    }
};

const mapActionsRoProps = {
    onAddNewUser: addNewUser,
    onDisplayMessage: onDisplayMessage,
    onDisplayError: onDisplayError,
    onLoadingTrue: onLoadingTrue,
    onLoadingFalse: onLoadingFalse
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddUser);