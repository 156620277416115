import React from 'react';
import './FailedTransaction.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFailedOrders } from '../../actions/FailedTransaction';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from '../../actions/Login';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';
import server from '../../utils/Server';
import {SortItems, SearchItems} from '../../utils/DataTable';
import * as moment from 'moment'
moment.locale();  // en

class Admissions extends React.Component {
  constructor(props){
    super(props);
    this.state = {activePage : 1, downloadFile: '', sortKey: 1, searchKey: '', pageNo: 1};
    this.deleteAdmission = this.deleteAdmission.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
	  this.props.onGetAdmissions('');
  }

  deleteAdmission = (admission_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this admission permanently?",
      dangerMode: true,
	    buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteAdmission(1, admission_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetAdmissions('?' + params);
  }

  handleDownloadExcel = () => {
    swal({
      title: "Are you sure?",
      text: "To download all admissions list?",
      buttons: ['Cancel', 'Download'],
    })
    .then(willDownload => {
      if (willDownload) {
        this.props.onLoadingTrue();
        server.get('/download_admissions_excel')
            .then(response => {
                this.props.onLoadingFalse();
                if(response.data.statuscode === 200) {

                  // Create anchor tag and download file
                  const link = document.createElement('a');
                  link.href = 'https://www.cipsedu.com/api/storage/app/public/' + response.data.file;
                  link.download = response.data.file;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);

                  setTimeout( this.props.onDisplayMessage(response.data.message), 5000);
                }
                else
                  this.props.onDisplayError(response.data.error);
            })
            .catch(error => {
              this.props.onLoadingFalse();
              this.props.onDisplayError(error.message);
            });
      }
    });
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value); 
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetAdmissions('?' + params);
  }

  handleSorting = (sortKey) => {
    this.setState({sortKey: sortKey});
  }

  handleSearch = (searchKey) => {
    this.setState({searchKey: searchKey.toLowerCase()});
  }

  renderAdmissions = () => {
    const { base_url } = this.props;
    const {searchKey, pageNo} = this.state;
    var failedOrders = this.props.failedOrders;
    var sortKey = parseInt(this.state.sortKey);

    // Sorting
    /*if (sortKey === 1) {
      SortItems(admissions, 'id', 2, 2);
    } else if(sortKey === 2) {
      SortItems(admissions, 'id', 2, 1);
    } else if (sortKey === 3) {
      SortItems(admissions, 'name', 1, 2);
    } else if(sortKey === 4) {
      SortItems(admissions, 'name', 1, 1);
    } else if(sortKey === 5) {
      SortItems(admissions, 'order_price', 2, 1);
    } else if(sortKey === 6) {
      SortItems(admissions, 'order_price', 2, 2);
    } else if(sortKey === 7) {
      SortItems(admissions, 'course_name', 1, 2);
    } else if(sortKey === 8) {
      SortItems(admissions, 'batch_name', 1, 2);
    } else if(sortKey === 9) {
      SortItems(admissions, 'status', 1, 1);
    } else if(sortKey === 10) {
      SortItems(admissions, 'status', 1, 2);
    }*/

    //debugger;
    // Searching
    if (searchKey !== "") {
      // admissions = SearchItems(admissions, ['name', 'email', 'mobile_number'], searchKey);
      failedOrders = failedOrders.filter(user => ( (user.name && user.name.toLowerCase().includes(searchKey)) || (user.mobile_number && user.mobile_number.toString().includes(searchKey)) || (user.batch_name && user.batch_name.toLowerCase().includes(searchKey)) || (user.course_name && user.course_name.toLowerCase().includes(searchKey)) || (user.order_price && user.order_price.toString().includes(searchKey)) ));
    }

    if(failedOrders && failedOrders !== '' && failedOrders.length > 0) {
      return (
        <tbody>
          {failedOrders.map((failedOrder, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{failedOrder.rzpay_order_id}</td>
              <td>{('Rs. ' + failedOrder.order_price)}</td>
              <td>{failedOrder.user_name}</td>
              <td>{failedOrder.mobile_number}</td>
              <td>{moment( failedOrder.ordered_date ).format('DD-MM-YYYY') }</td>
              <td>{failedOrder.status.toString() === '1' ? 'Success' : 'Failed'}</td>
              <td><Link to={'/failedTransaction/edit_failedTransaction/' + failedOrder.id}><button className="btn btn-primary btn-sm" title="Detail"> Detail </button></Link></td>
            </tr>
          ))}
        </tbody>
      )
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="8" className="text-center"> No admissions found.</td>
          </tr>
        </tbody>
      )
    }
  }

  render() {

    const { failedOrders, states, plans, total_count, page_count, page_size, base_url } = this.props;
    const {sortKey, searchKey} = this.state;
    let pagination_display = '';

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} failed Orders. </p> */}
          <p className="small_text d-inline ml-2"> Showing {failedOrders.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} admissions. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {failedOrders.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">FailedTransaction</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Failed Transaction </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>


          <div id="search_filter_form">
            <div className="row">
              {/*<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSorting(e.target.value)}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Names A-Z</option>
                    <option value="4">Names Z-A</option>
                    <option value="5">Fees H-L</option>
                    <option value="6">Fees L-H</option>
                    <option value="7">Courses A-Z</option>
                    <option value="8">Batches Z-A</option>
                    <option value="9">Active First</option>
                    <option value="10">Inactive First</option>
                  </select>
                </div>
              </div> */}


              <div className="col-md-2 col-12" />
              <div className="col text-right">
                <div className="input-group mb-3">
                <div className="input-group-append">
                    <button type="button" className="search_btn btn-primary px-3"><i className="fa fa-search"></i></button>
                  </div>
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by mobile number" value={searchKey} onChange={(e) => this.handleSearch(e.target.value)} autoComplete="off"/>
                 
                </div>
              </div>
            </div>
          </div>

          {/* Display all admissions table */}
          <table className="table table-responsive table-bordered table-striped mt-3">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No</th>
                <th className="bg-blue text-light" width="20%">Order ID</th>
                <th className="bg-blue text-light" width="15%">Order Amount</th>
                <th className="bg-blue text-light" width="15%">Order User Name</th>
                <th className="bg-blue text-light" width="15%">Order User Mobile</th>
                <th className="bg-blue text-light" width="15%">Order Date</th>
                <th className="bg-blue text-light" width="15%">Status</th>
                <th className="bg-blue text-light" width="15%">Detail</th>

              </tr>
            </thead>

            {/* Display all the admissions */}
            {this.renderAdmissions()}
          </table>
          
          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
    base_url: state.admin.base_url,
    failedOrders : state.failedTransaction.failedOrders,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
  }
};

const mapActionsRoProps = {
  onGetAdmissions : getFailedOrders,
  onLoadingTrue : onLoadingTrue,
  onLoadingFalse : onLoadingFalse,
  onDisplayMessage : onDisplayMessage,
  onDisplayError : onDisplayError,
};

export default connect(mapStatesToProps, mapActionsRoProps) (Admissions);