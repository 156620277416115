import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS';
export const GET_BATCH_SUCCESS = 'GET_BATCH_SUCCESS';

export const getAllBatches = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllBatches' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetBatchesSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewBatch = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewBatch', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getBatchDetails = (batch_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditBatch/' + batch_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetBatchSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateBatch = (batch_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateBatch/' + batch_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteBatch = (batch_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteBatch/' + batch_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllBatches(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetBatchesSuccess = (data) => {
    return {
        type : GET_BATCHES_SUCCESS,
        payload : {
            batches : data
        }
    }
}

export const onGetBatchSuccess = (data) => {
    return {
        type : GET_BATCH_SUCCESS,
        payload : {
            batch : data
        }
    }
}