import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_GALLARYS_SUCCESS = 'GET_GALLARYS_SUCCESS';
export const GET_GALLARY_SUCCESS = 'GET_GALLARY_SUCCESS';

export const getAllGalleryImage = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getAllGalleryImage' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetGallarySuccess(response.data.data));

                    console.log("testing999", response.data);
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addGalleryImage = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/addGalleryImage', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {

                    console.log("testing111", response);
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {    //console.log("testing222", response);
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getGalleryImageDetail = (achiever_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getGalleryImageDetail/' + achiever_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetGallerySuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateGalleryImage = (gallary_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/updateGalleryImage/' + gallary_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteGallaryImage = (gallery_image_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/deleteGalleryImage/' + gallery_image_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllGalleryImage(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetGallarySuccess = (data) => {
    return {
        type : GET_GALLARYS_SUCCESS,
        payload : {
            gallery : data
        }
    }
}

export const onGetGallerySuccess = (data) => {
    return {
        type : GET_GALLARY_SUCCESS,
        payload : {
            gallery : data
        }
    }
}




