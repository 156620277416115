import React from 'react';
import './Videos.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewVideo } from '../../actions/Videos';
import swal from 'sweetalert';

class Videos extends React.Component {
  constructor(props){
    super(props);
    this.onAddVideoSubmit = this.onAddVideoSubmit.bind(this);
  }

  onAddVideoSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new video?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_video_form'));
        this.props.onAddNewVideo(formData);
      }
    });
  }

  render() {

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/videos">Videos</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Video</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Video </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new video form  */}
          <div className="row add_container">
            <div className="col"></div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_video_form" onSubmit={this.onAddVideoSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Subject Name : </label>
                        <input type="text" name="video_subject" placeholder="Subject Name" className="form-control"/>
                      </div>
                      <div className="col">
                        <label className="small_text"> Chapter Name : </label>
                        <input type="text" name="video_chapter" placeholder="Chapter Name" className="form-control"/>
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <label className="small_text"> Video Youtube ID * : </label>
                        <input type="text" name="video_path" placeholder="Video Youtube ID *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Status : </label>
                      <select name="status" className="form-control" required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Video Description : </label>
                      <textarea rows={8} name="video_description" placeholder="Video Description" className="form-control"/>
                    </div>
                  </div>
                </div>
        
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Video </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewVideo : addNewVideo
};

export default connect(mapStatesToProps, mapActionsRoProps) (Videos);
