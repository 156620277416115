import { GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTION_SUCCESS } from '../actions/Subscription';

const initialState = {
    subscriptions : [],
    subscription : {}
}

export default function Subscription_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_SUBSCRIPTION_SUCCESS :
            return Object.assign({}, state, { subscription : payload.book });

        case GET_SUBSCRIPTIONS_SUCCESS :
            return Object.assign({}, state, { subscriptions : payload.books });

        default :
            return state;
    }
}
