import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import Admin_login_reducer from '../reducers/Login';
import Rolling_images_reducer from '../reducers/RollingImages';
import Admin_dashboard_reducer from '../reducers/Dashboard';
import Pagination_reducer from '../reducers/Pagination';
import User_reducer from '../reducers/Users';
import Admission_reducer from '../reducers/Admissions';
import News_update_reducer from '../reducers/NewsUpdates';
import Exam_reducer from '../reducers/Exams';
import Course_reducer from '../reducers/Courses';
import Video_reducer from '../reducers/Videos';
import Faculty_reducer from '../reducers/Faculties';
import Achiever_reducer from '../reducers/Achievers';
import Book_reducer from '../reducers/Books';
import Batch_reducer from '../reducers/Batches';
import Question_reducer from '../reducers/Questions';
import Class_reducer from '../reducers/Classes';
import Hostel_reducer from '../reducers/Hostels';
import Booking_reducer from '../reducers/HostelBookings';
import Gallery_reducer from '../reducers/Gallery';
import GalleryCategory_reducer from '../reducers/GalleryCategory';
import WrittenExam_reducer from '../reducers/WrittenExam';
import DeliveryCharge_reducer from '../reducers/DeliveryCharge';
import Subscription_reducer from '../reducers/Subscription';
import FailedTransaction_reducer from '../reducers/FailedTransaction';


const All_reducers = combineReducers({
    admin: Admin_login_reducer,
    rolling_images: Rolling_images_reducer,
    dashboard: Admin_dashboard_reducer,
    paginate: Pagination_reducer,
    user: User_reducer,
    admission: Admission_reducer,
    news: News_update_reducer,
    exam: Exam_reducer,
    course: Course_reducer,
    video: Video_reducer,
    faculty: Faculty_reducer,
    achiever: Achiever_reducer,
    book: Book_reducer,
    batch: Batch_reducer,
    question: Question_reducer,
    classe: Class_reducer,
    hostel: Hostel_reducer,
    booking: Booking_reducer,
    gallery: Gallery_reducer,
    galleryCategory: GalleryCategory_reducer,
    writtenExam: WrittenExam_reducer,
    deliveryCharge: DeliveryCharge_reducer,
    subscription: Subscription_reducer,
    failedTransaction: FailedTransaction_reducer,


});
  
const store = createStore(All_reducers, applyMiddleware(thunk));

export default store;
