import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHostelDetails, updateHostel } from '../../actions/Hostels';
import swal from 'sweetalert';

class EditHostel extends React.Component {
  constructor(props){
    super(props);
    const { hostel } = this.props;
    this.state = { hostel : hostel, hostel_id : this.props.match.params.hostel_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetHostelDetails(this.state.hostel_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let hostel = this.props.hostel;
    hostel[name] = value;
    this.setState({ hostel : hostel });
  }

  handleValueChange = (e) => {
    var name = e.editor.name;
    var value = e.editor.getData();
    let hostel = this.props.hostel;

    hostel[name] = value;
    this.setState({ hostel : hostel });
  }

  onUpdateHostelSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this hostel?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_hostel_form'));
        this.props.onUpdateHostel(this.state.hostel_id, formData);
      }
    });
  }

  render() {

    const { hostel } = this.props;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/hostels">Hostels</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Hostel</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Hostel </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/hostels/add_hostel"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit hostel form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_hostel_form" onSubmit={this.onUpdateHostelSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Hostel Name * : </label>
                      <input type="text" name="hostel_name" placeholder="Hostel Name" className="form-control" value={hostel.hostel_name ? hostel.hostel_name : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Hostel Type * : </label>
                        <select name="hostel_type" className="form-control" value={hostel.hostel_type ? hostel.hostel_type : ''} onChange={this.onChangeHandler} required>
                          <option value=""> Select hostel type </option>
                          <option value="1"> Boys hostel </option>
                          <option value="0"> Girls hostel </option>
                        </select>
                      </div>
                      <div className="col">
                        <label className="small_text">Status : </label>
                        <select name="status" className="form-control" value={hostel.status ? hostel.status : ''} onChange={this.onChangeHandler} >
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Hostel Price Per Month * : </label>
                      <input type="number" name="hostel_price" placeholder="Hostel Price Per Month" className="form-control" value={hostel.hostel_price ? hostel.hostel_price : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Hostel Capacity * : </label>
                        <input type="number" name="hostel_capacity" placeholder="Total Beds" className="form-control" value={hostel.hostel_capacity ? hostel.hostel_capacity : ''} onChange={this.onChangeHandler} required/>
                      </div>
                      <div className="col">
                        <label className="small_text">Available Beds * : </label>
                        <input type="number" disabled={true} placeholder="Available Beds" className="form-control" value={hostel.available_beds ? hostel.available_beds : ''} />
                        <input type="hidden" name="available_beds" value={hostel.available_beds} />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Contact Number : </label>
                      <input type="number" name="hostel_contact_number" placeholder="Contact Number" className="form-control" value={hostel.hostel_contact_number ? hostel.hostel_contact_number : ''} onChange={this.onChangeHandler} />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Address : </label>
                      <textarea rows={6} name="hostel_address" placeholder="Hostel Address" className="form-control" value={hostel.hostel_address ? hostel.hostel_address : ''} onChange={this.onChangeHandler} />
                    </div>
                  </div>
                </div>
            
                <div className="form-group text-center mt-3">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Hostel </button>
                </div>
              </form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    hostel : state.hostel.hostel,
  }
};

const mapActionsRoProps = {
  onGetHostelDetails : getHostelDetails,
  onUpdateHostel : updateHostel
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditHostel);
