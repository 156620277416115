import { GET_GALLARYS_SUCCESS, GET_GALLARY_SUCCESS } from '../actions/Gallery';

const initialState = {

    gallerys : [],
    gallery : {}

}

export default function Gallery_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_GALLARY_SUCCESS :
            return Object.assign({}, state, { gallery : payload.gallery });
            
        case GET_GALLARYS_SUCCESS :
            return Object.assign({}, state, { gallerys : payload.gallery });

        default :
            return state;

    }
}
