import { GET_IMAGES_SUCCESS, GET_IMAGE_SUCCESS } from '../actions/RollingImages';

const initialState = {
    rollingImages : [],
    rollingImage : {},
}

export default function Course_reducer (state = initialState, { type, payload } ) {
    switch (type)
    {
        case GET_IMAGE_SUCCESS :
            return Object.assign({}, state, { rollingImage : payload.rollingImage });

        case GET_IMAGES_SUCCESS :
            return Object.assign({}, state, { rollingImages : payload.rollingImages });

        default :
            return state;
    }

}
