import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_ACHIEVERS_SUCCESS = 'GET_ACHIEVERS_SUCCESS';
export const GET_ACHIEVER_SUCCESS = 'GET_ACHIEVER_SUCCESS';

export const getAllAchievers = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllAchievers' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetAchieversSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewAchiever = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewAchiever', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {

                    console.log("testing111", response);
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {    console.log("testing222", response);
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getAchieverDetails = (achiever_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditAchiever/' + achiever_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetAchieverSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateAchiever = (achiever_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateAchiever/' + achiever_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteAchiever = (achiever_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteAchiever/' + achiever_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllAchievers(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetAchieversSuccess = (data) => {
    return {
        type : GET_ACHIEVERS_SUCCESS,
        payload : {
            achievers : data
        }
    }
}

export const onGetAchieverSuccess = (data) => {
    return {
        type : GET_ACHIEVER_SUCCESS,
        payload : {
            achiever : data
        }
    }
}