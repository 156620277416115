import { GET_DELIVERY_CHARGES_SUCCESS, GET_DELIVERY_CHARGE_SUCCESS } from '../actions/DeliveryCharge';

const initialState = {

    deliveryCharges : [],
    deliveryCharge : {}

}

export default function DeliveryCharge_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_DELIVERY_CHARGE_SUCCESS :
            return Object.assign({}, state, { deliveryCharge : payload.deliveryCharge });
            
        case GET_DELIVERY_CHARGES_SUCCESS :
            return Object.assign({}, state, { deliveryCharges : payload.deliveryCharge });

        default :
            return state;

    }
}
