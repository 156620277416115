import React from 'react';
import './NewsUpdates.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewNewsUpdate } from '../../actions/NewsUpdates';
import swal from 'sweetalert';

class AddNews extends React.Component {

  constructor(props) {
    super(props);
    this.state = {fileType: '0', news_image_path: ''};
  }

  handleImageChange = (e) => {
    this.setState({ news_image_path : URL.createObjectURL(e.target.files[0]) });
  }

  handleFileTypeChange = (e) => {
    this.setState({fileType: e.target.value});
  }

  handleNewsTypeChange = (e) => {
    //this.setState({fileType: e.target.value});
  }

  onAddNewsUpdateSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new news?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_news_update_form'));
        var news_streams = document.getElementsByClassName('news_streams');
        var streams = new Array();

        for(let i=0;i<news_streams.length;i++)
        {
          if(news_streams[i].checked)
          {
            streams.push(news_streams[i].value);
          }
        }

        formData.append('news_streams', streams.toString());
        this.props.onAddNewNewsUpdate(formData);
      }
    });
  }

  render() {
    const {news_image_path, fileType} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/news_updates">News</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add News</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add News </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/* <Link to="/news_updates/news_categories"><button type="button" className="btn btn-primary btn-sm">Categories <i className="ml-1 fa fa-plus"></i></button></Link> */}
            </div>
          </div>
          
          {/*  Add new news_update form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_news_update_form" onSubmit={this.onAddNewsUpdateSubmit} autoComplete="off">

                

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">News Title * : </label>
                      <textarea name="news_title" rows="3" placeholder="News Title *" className="form-control" required/>
                    </div>


                    <div className="form-group">
                      <label className="small_text">News Type : </label>
                      <select name="news_type"className="form-control">
                        <option value="YES"> Scrolling </option>
                        <option value="NO"> General </option>
                      </select>
                    </div>


                    <div className="form-group">
                      <label className="small_text">File Type : </label>
                      <select name="file_type" value={fileType ? fileType : "0"} onChange={(e) => this.handleFileTypeChange(e)} className="form-control">
                        <option value="0"> Image </option>
                        <option value="1"> Video </option>
                      </select>
                    </div>

                    {
                      fileType == "0" ? 
                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Image : </label>
                            <input type="file" name="news_image_path" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control"/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {news_image_path !== '' ? <img src={news_image_path} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>
                    : <div className="form-group">
                        <label className="small_text">Youtube Video ID : </label>
                        <input type="text" name="news_image_path" placeholder="Youtube Video ID" className="form-control"/>
                      </div>
                    }
                  
                    <div className="form-group">
                      <label className="small_text">Status * : </label>
                      <select name="status" className="form-control" required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>                         
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">News Description : </label>
                      <textarea name="news_description" rows="14" placeholder="News Description" className="form-control"/>
                    </div>
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add News </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
  }
};

const mapActionsRoProps = {
  onAddNewNewsUpdate : addNewNewsUpdate
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddNews);
