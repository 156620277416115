import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_EXAMS_SUCCESS = 'GET_EXAMS_SUCCESS';
export const GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS';

export const getAllExams = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllTests' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetExamsSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewExam = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/AddNewTest', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getExamDetails = (exam_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditTest/' + exam_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetExamSuccess(response.data.data));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateExam = (exam_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateTest/' + exam_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteExam = (exam_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/DeleteTest/' + exam_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllExams(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetExamsSuccess = (data) => {
    return {
        type : GET_EXAMS_SUCCESS,
        payload : {
            exams : data
        }
    }
}

export const onGetExamSuccess = (exam) => {
    return {
        type : GET_EXAM_SUCCESS,
        payload : {
            exam : exam
        }
    }
}