import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const NEWS_DETAILS_SUCCESS = 'NEWS_DETAILS_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const getAllNewsUpdates = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.get('/GetAllNews' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetNewsUpdatesSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getNewsCategories = () => {
    return dispatch => {
        dispatch(onLoadingTrue());
        return server.get('/get_dropdown_categories')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetCategoriesSuccess(response.data.categories));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewNewsUpdate = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewNews', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getNewsDetails = (news_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.get('/EditNews/' + news_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetNewsUpdateSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateNewsUpdate = (news_update_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateNews/' + news_update_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteNewsUpdate = (news_update_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteNews/' + news_update_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllNewsUpdates(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetNewsUpdatesSuccess = (data) => {
    return {
        type : GET_NEWS_SUCCESS,
        payload : {
            news_updates : data
        }
    }
}

export const onGetNewsUpdateSuccess = (news_update) => {
    return {
        type : NEWS_DETAILS_SUCCESS,
        payload : {
            news_update : news_update
        }
    }
}

export const onGetCategoriesSuccess = (categories) => {
    return {
        type : GET_CATEGORIES_SUCCESS,
        payload : {
            categories : categories
        }
    }
}