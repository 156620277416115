import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse } from './Login';

export const GET_DASHBOARD = 'GET_DASHBOARD';

export const getAdminDashboard = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAdminDashboard')
                .then(response => {
                    dispatch(onLoadingFalse());
                    if(response.data.statuscode === 200)
                        dispatch(onGetDashboardSuccess(response.data.data));
                })
                .catch(err => {
                    dispatch(onLoadingFalse());
                });
    };
}

export const onGetDashboardSuccess = (data) => {
    return {
        type: GET_DASHBOARD,
        payload: {
            dashboardData: data,
        }
    }
}