
import { GET_FAILED_ORDERS_SUCCESS, GET_FAILED_ORDER_SUCCESS } from '../actions/FailedTransaction';

const initialState = {
    failedOrders: [],
    failedOrderDetail: {},
    //payments: {},
    //admissionpdfData : {}
}

export default function FailedTransaction_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_FAILED_ORDER_SUCCESS:
            return Object.assign({}, state, { failedOrderDetail : payload.order });

        case GET_FAILED_ORDERS_SUCCESS:
            return Object.assign({}, state, { failedOrders : payload.orders });

        /*case GET_PAYMENTS_SUCCESS:
            return Object.assign({}, state, { payments : payload.payments });

        case GET_ADMISSION_PDF_SUCCESS:
            return Object.assign({}, state, { admissionpdfData : payload.admissionPdfData })
            */
        default :
            return state;
    }
}
