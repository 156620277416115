import React from 'react';
import './Questions.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewQuestion } from '../../actions/Questions';
import { getDropDownCourses } from '../../actions/Courses';
import swal from 'sweetalert';

class AddQuestion extends React.Component {

  constructor(props){
    super(props);
    this.state = { exam_id : this.props.match.params.exam_id, exam_name : this.props.match.params.exam_name, images: {question_image: '', option1: '', option2: '', option3: '', option4: '', option5: ''}, answerType: "0"};
  }
  
  // componentWillMount () {
	//   this.props.onGetDropDownCourses();
  // }

  onAddQuestionSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new question?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_question_form'));
        this.props.onAddNewQuestion(formData);
      }
    });
  }

  handleFileTypeChange = (e) => {
    this.setState({answerType: e.target.value});
  }

  handleImageChange = (e) => {
    const { name } = e.target;
    let images = this.state.images;

    images[name] = URL.createObjectURL(e.target.files[0]);

    this.setState({ images : images });
  }

  render() {
    // const {courses} = this.props;
    const {exam_id, exam_name, images, answerType} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/exams">Exams</Link> </li>
                  <li className="breadcrumb-item"><Link to={"/questions/" + exam_name + "/" + exam_id}>Questions</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Question</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Question </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                
            </div>
          </div>
          
          {/*  Add new question form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_question_form" onSubmit={this.onAddQuestionSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Subject - Course : </label>
                      <input type="text" placeholder="Subject - Course" className="form-control" value={exam_name} disabled/>
                    </div>
                    
                    <div className="form-group">
                      <label className="small_text"> Question : </label>
                      <textarea rows={4} name="question" placeholder="Question" className="form-control"/>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Question Image : </label>
                        <input type="file" name="question_image" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {images.question_image !== '' ? <img src={images.question_image} className="img-fluid img-thumbnail" alt=""/> : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Answer Type : </label>
                      <select name="answer_type" value={answerType ? answerType : "0"} onChange={(e) => this.handleFileTypeChange(e)} className="form-control">
                        <option value="0"> Text </option>
                        <option value="1"> Image </option>
                      </select>
                    </div>

                     <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Correct Answer * : </label>
                        <select name="answer" className="form-control" required>
                          <option value="1"> Option 1 </option>
                          <option value="2"> Option 2 </option>
                          <option value="3"> Option 3 </option>
                          <option value="4"> Option 4 </option>
                          <option value="5"> Option 5 </option>
                        </select>
                      </div>
                      <div className="col">
                        <label className="small_text"> Status * : </label>
                        <select name="status" className="form-control" required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {
                    answerType == "0" ? 
                      <div className="col">
                        <div className="form-group">
                          <label className="small_text"> Option 1 * : </label>
                          <textarea rows={2} name="option1" placeholder="Option 1 *" className="form-control" required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 2 * : </label>
                          <textarea rows={2} name="option2" placeholder="Option 2 *" className="form-control" required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 3 * : </label>
                          <textarea rows={2} name="option3" placeholder="Option 3 *" className="form-control" required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 4 * : </label>
                          <textarea rows={2} name="option4" placeholder="Option 4" className="form-control" required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 5 : </label>
                          <textarea rows={2} name="option5" placeholder="Option 5" className="form-control"/>
                        </div>
                      </div>
                    : 
                      <div className="col">
                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 1 Image * : </label>
                            <input type="file" name="option1" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {images.option1 !== '' ? <img src={images.option1} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 2 Image * : </label>
                            <input type="file" name="option2" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {images.option2 !== '' ? <img src={images.option2} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 3 Image * : </label>
                            <input type="file" name="option3" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {images.option3 !== '' ? <img src={images.option3} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 4 Image * : </label>
                            <input type="file" name="option4" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {images.option4 !== '' ? <img src={images.option4} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 5 Image : </label>
                            <input type="file" name="option5" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control"/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {images.option5 !== '' ? <img src={images.option5} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>
                      </div>
                  }

                  <input type="hidden" name="test_id" value={exam_id} />
                </div>
                 
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Question </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
	isLoading : state.admin.isLoading, 
	courses : state.course.courses
  }
};

const mapActionsRoProps = {
  onGetDropDownCourses : getDropDownCourses,
  onAddNewQuestion : addNewQuestion
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddQuestion);

