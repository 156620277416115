export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';

export const addPaginationValues = (total_count, page_count, page_size) => {
    return {
        type : GET_PAGE_SUCCESS,
        payload : {
            total_count : total_count,
            page_count : page_count,
            page_size : page_size,
        }
    }
}