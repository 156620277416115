import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllHostels, deleteHostel } from '../../actions/Hostels';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';

class Hostels extends React.Component {
  constructor(props){
    super(props);
    this.state = { activePage : 1 };
    this.deleteHostel = this.deleteHostel.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
    this.props.onGetHostels();
  }

  deleteHostel = (hostel_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this hostel permanently?",
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteHostel(hostel_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetHostels('?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value);
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetHostels('?' + params);
  }

  render() {

    const { hostels, total_count, page_count, page_size, base_url } = this.props;
    let hostels_display = {};
    let pagination_display = '';

    if(hostels && hostels.length > 0)
    {
      hostels_display = hostels.map((hostel, index) => (
        <tr key={index}>
          <td>{index+1}</td>
          <td>{hostel.hostel_name}</td>
          <td>{hostel.hostel_type === '0' ? 'Girls' : 'Boys'}</td>
          <td>{hostel.hostel_capacity}</td>
          <td>{hostel.available_beds}</td>
          <td>{hostel.hostel_price > 0 ? ('Rs. ' + hostel.hostel_price) : '0'}</td>
          <td>{hostel.status === '1' ? 'Active' : 'Inactive'}</td>
          <td><Link to={'/hostels/edit_hostel/' + hostel.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
          <td><button className="icon_button" title="Delete" onClick={() => this.deleteHostel(hostel.id)}> <i className="fa fa-trash text-danger"></i> </button></td>
        </tr>
      ))
    }
    else
    {
      hostels_display = <tr><td colSpan="10" className="text-center"> No hostels found.</td></tr>
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} hostels. </p> */}
          <p className="small_text d-inline ml-2"> Showing {hostels.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} hostels. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {hostels.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Hostels</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Hostels </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/hostels/add_hostel"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

           {/* filters, sort and search container */}
           {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Hostels A-Z</option>
                    <option value="2">Hostels Z-A</option>
                    <option value="3">Newest First</option>
                    <option value="4">Oldest First</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input type="hidden" name="page_size" id="page_size" value={page_size}/>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by hostel name" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all hostels table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No.</th>
                <th className="bg-blue text-light" width="25%">Name</th>
                <th className="bg-blue text-light" width="15%">Type</th>
                <th className="bg-blue text-light" width="15%">Capacity</th>
                <th className="bg-blue text-light" width="10%">Available</th>
                <th className="bg-blue text-light" width="10%">Price Per Month</th>
                <th className="bg-blue text-light" width="10%">Status</th>
                <th className="bg-blue text-light" width="5%">Edit</th>
                <th className="bg-blue text-light" width="5%">Delete</th>
              </tr>
            </thead>
            <tbody>

              {/* Display all the hostels */}
              {hostels_display}

            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    hostels : state.hostel.hostels,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url: state.admin.base_url,
  }
};

const mapActionsRoProps = {
  onGetHostels : getAllHostels,
  onDeleteHostel : deleteHostel
};

export default connect(mapStatesToProps, mapActionsRoProps) (Hostels);
