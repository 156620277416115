import React, { useRef } from 'react';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import "./PDF.css";
import Images from '../../assets/images';
import DateFormat from '../../utils/DateFormat';
import * as moment from 'moment'
moment.locale();


const payments = {
	"0" : "Cash" ,
	"1" : "Online"
}
function AdmissionPDF (props) {
 const {pdfData} = props;
return (
    <>
		<div className='bodyformat'> 
			<div>
				<div className="pdf-header">
					<div className="header-logo  flex-column">
						<div className="logo d-flex justify-content-center mt-2 mb-1"><img src={Images.logo} /></div>
						<div className="d-flex justify-content-center "><h7>CLASSIC KAS & IAS STUDY CIRCLE</h7></div>
						<div className="d-flex justify-content-center yellowColor mb-2"><h7>KARNATAKA CLASSIC EDUCATION Pvt Ltd</h7></div>

						<div className="d-flex justify-content-center font-weight-bold"><h5>Receipt/Tax Invoice</h5></div>
					</div> 
					<hr  className="m-0"/> 
				</div>
				
				
		<div className="pdf-body mt-2">
			<div className="receipt-contents d-flex justify-content-between"> 
				<div className="invoice-info">
					<span>Date : </span>
					<strong>{pdfData.start_date && DateFormat(pdfData.start_date, 2)}</strong>
				</div>
				<div className="text-right">
					<div className="invoice-info">
						<span>Receipt No : </span>
						<strong>KCA{pdfData.id}</strong>
					</div>
					<div className="invoice-info">
						<span>GST No : </span>
						<strong>GST1234567</strong>
					</div>
				</div>
			</div>

			<div className="mt-2 mb-2 text-left">
				<div className="invoice-info">
					<span>Full Name : </span>
					<strong>{pdfData.name}</strong>
				</div>
				<div className="invoice-info">
					<span>Communication Address : </span>
					<strong>{pdfData.communication_address}</strong>
				</div>
				<hr />
			</div>

			<div className="pdf-info-table row">
				<div className="col-12 col-sm-6 p-0">
					<div className="d-flex flex-column align-items-center">
						<div className=""><h6  className="mb-0 ">Hostel Details</h6></div>
						<div className="d-flex justify-content-between contact-labels text-left">
							<div className="m-0 pl-0">
								<div>Hostel Name</div>
								<div>Hostel Address</div>
								<div>Duration</div>
							</div>
							<div className="contact-border m-0 text-left">
								<div>{pdfData.hostel_name}</div>
								<div>{pdfData.hostel_address}</div>
								<div>{pdfData.no_of_months} Months</div>
							</div>

						</div>
					</div>
					
				</div>
				
				<div className="col-12 col-sm-6 text-right">
					<div>
						<h6 className="text-center">Collect Hostel Fees</h6>
						<table className="table table-bordered ml-auto">
							<thead>
								<tr>
									<td className="greyColor">Fee Type</td>
									<td>Amount</td>
								</tr>
							</thead>
							<tbody>
								<tr>
								<td className="greyColor">Hostel Fees</td>
									<td>{parseFloat(pdfData.total_price).toFixed(2)}</td>
								</tr>
								<tr>
								<td className="greyColor">Paid Amount</td>
									<td>{parseFloat(pdfData.paid_price).toFixed(2)}</td>
								</tr>
								<tr>
								<td className="greyColor">Pending Amount</td>
									<td>{parseFloat(pdfData.paid_price).toFixed(2)}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="d-flex justify-content-between p-1"> 
						<div>Payment Mode</div>
						<div className="border px-5 py-1">Cash</div>
					</div>
					<div className="border comments">
						<span>Comments If Any</span>
					</div>
				</div>

				
			</div>
			<hr />
		</div>
			</div>
			<div>
				<div className="foot text-left">
						<div className="note m-2">
							<b>Note</b>
							<p>* Fees Once paid will not be refunded or transferred</p>
						</div>

						<div className="footer row">
							<div className="col-8">
							<h6 className="heading"> KARNATAKA CLASSIC EDUCATION Pvt Ltd </h6>
							<p className="address">The Classic KAS & IAS Study Circle Unit A,<br/>
								Akshata Arcade, Saptapur Bavi,<br/>
								Dharwad-580001 <br/>
							</p>
								
							</div>
							<div className="col-4">
							<p className="address">Email:  classicappdwd@gmail.com </p>
							<p className="address">PH: +91 9980552080, <br/> +91 9980552081</p>
							</div>
						</div>
			</div>
		</div>
		</div>
    </>
  );
}

export default AdmissionPDF;