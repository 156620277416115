import React from 'react';
import './Notifications.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendNotifications, onDisplayError } from '../../actions/Login';
import { getAllCourses } from '../../actions/Courses';
import { getAllBatches } from '../../actions/Batches';
import swal from 'sweetalert';

class Notifications extends React.Component {

	constructor (props) {
		super(props);
		this.state = {message: '' , currentBatches : [], notification_image_path: ''};
	}

	handleImageChange = (e) => {
    this.setState({ notification_image_path : URL.createObjectURL(e.target.files[0]) });
  }

	async componentWillMount() {
		
		await this.props.getAllCourses();
		await this.props.getAllBatches('');
	}

	onSubmitNotification = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To send this notification",
			buttons: ['Cancel', 'Send'],
		})
		.then(willDelete => {
			if (willDelete) {
				const formData = new FormData(document.getElementById('send_notification_form'));
				this.props.onSendNotifications(formData);
			}
		});
	}

	CourseChange = async (e) =>{
		const { name , value} = e.target;
		let currentBatches = []
		this.props.batches.filter(item =>{
			if(item.course_id == value){
				currentBatches.push(item)
			}
		})
		this.setState({
			currentBatches
		})
	}

  render() {
		const {message, notification_image_path} = this.state;
		const {classes} = this.props;
		const {courses, batches} = this.props;

		//console.log("testinggggg",courses, batches);

    	return (
			<div className="row">
				<div className="col">
		
					{/* Breadcrumbs and add button container */}
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<nav area-label="breadcrumb">
								<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Notifications</li>
								</ol>
							</nav>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<h4 className="text-center page_header"> NOTIFICATIONS </h4>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
						</div>
					</div>

					{/* Edit admin profile */}
					<div className="row add_container form_container">
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
							<h5 className="text-center page_header text-uppercase mt-2">SEND NOTIFICATION</h5>

							<form id="send_notification_form" onSubmit={this.onSubmitNotification} autoComplete="off">
								<div className="form-group">
									<label className="small_text"> Users * : </label>
									<select name="notification_type" className="form-control" required>
										<option value=""> Select Users </option>
										<option value="All"> All Users (Including students) </option>
										<option value="Student"> Only Students</option>
									</select>
								</div>


								<div className="form-group">
									<label className="small_text"> Courses * : </label>
									<select name="course_type" className="form-control"  id="lang" onChange={this.CourseChange}>
										<option > Select Course </option>
										{
											courses.length > 0 && courses.map((d, i)=>{
												return <option key={d.id} value={d.id}> {d.course_name} </option>
											})
										}
									</select>
								</div>
								<div className="form-group">
									<label className="small_text"> Batch * : </label>
									<select name="batch_type" className="form-control" >
										<option value=""> Select Batch </option>
										{
											this.state.currentBatches.length > 0 && this.state.currentBatches.map((d, i)=>{
												return <option key={d.id} value={d.id}> {d.batch_name} </option>
											})
										}
									</select>
								</div>

								
								<div className="form-group">
									<label className="small_text"> Title* : </label>
									<input type="text" name="notification_header" placeholder="Title *" className="form-control" required/>
								</div>

								<div className="form-group">
									<label className="small_text"> Message * : </label>
									<textarea rows={5} name="notification_message" placeholder="Message *" className="form-control" onChange={(e) => this.setState({message: e.target.value})} required/>
									<span className="small_text">{'Total charcters: ' + ('0' + message.length).slice(-2) + ' (160 charcters limited for SMS)'}</span>
								</div>


						<div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Image : </label>
                            <input type="file" name="notification_image_path" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control"/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {notification_image_path !== '' ? <img src={notification_image_path} className="img-fluid img-thumbnail" alt=""/> : null}
                          </div>
                        </div>
	
								<div className="form-group text-center">
									<button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''}> SEND </button>
								</div>


							</form>
						</div>
						<div className="col"></div>
					</div>

				</div>
			</div>
		);
  	}

}


const mapStatesToProps = (state) => {
	return {
		isLoading : state.admin.isLoading,
		courses : state.course.courses,
		batches : state.batch.batches
	}
}

const mapActionsToProps = {
	onSendNotifications : sendNotifications,
	onDisplayErroMessage : onDisplayError,
	getAllCourses : getAllCourses,
	getAllBatches : getAllBatches

}

export default connect(mapStatesToProps, mapActionsToProps) (Notifications);
