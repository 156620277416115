import React from 'react';
import './Achievers.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewAchiever } from '../../actions/Achievers';
import swal from 'sweetalert';

class Achievers extends React.Component {
  constructor(props){
    super(props);
    this.state = { images : { achiever_image_path : ''} };
    this.onAddAchieverSubmit = this.onAddAchieverSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    let images = this.state.images;
    images[e.target.name] = URL.createObjectURL(e.target.files[0]);
    this.setState({ images : images });
  }

  onAddAchieverSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new achiever?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_achiever_form'));
        this.props.onAddNewAchiever(formData);
      }
    });
  }

  render() {

    const { images } = this.state;
    let image_preview = '';

    if(images.achiever_image_path){
      image_preview = <img src={images.achiever_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/achievers">Achievers</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Achiever</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Achiever </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new achiever form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="add_achiever_form" onSubmit={this.onAddAchieverSubmit} autoComplete="off">

                <div className="form-group">
                    <input type="text" name="achiever_name" placeholder="Achiever Name *" className="form-control" required/>
                </div>

                <div className="form-group">
                  <input type="text" name="batch_number" placeholder="Achiever Batch *" className="form-control"/>
                </div>

                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <label className="small_text">Achiever Photo * : </label>
                    <input type="file" name="achiever_image_path" onChange={this.onChangeHandler} className="form-control"/>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                      {image_preview}
                  </div>
                </div>

                <div className="form-group">
                  <input type="text" name="achiever_video_path" placeholder="Achiever Video Path *" className="form-control"/>
                </div>

                <div className="form-group">
                    <textarea rows={5} name="achiever_message" placeholder="Achiever Message *" className="form-control" required/>
                </div>

                <div className="form-group">
                  <select name="status" className="form-control" required>
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Achiever </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewAchiever : addNewAchiever
};

export default connect(mapStatesToProps, mapActionsRoProps) (Achievers);
