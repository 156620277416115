import { GET_NEWS_SUCCESS, NEWS_DETAILS_SUCCESS, GET_CATEGORIES_SUCCESS } from '../actions/NewsUpdates';

const initialState = {
    news_updates : [],
    news_update : {},
    categories : []
}

export default function News_update_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case NEWS_DETAILS_SUCCESS :
            return Object.assign({}, state, { news_update : payload.news_update });

        case GET_NEWS_SUCCESS :
            return Object.assign({}, state, { news_updates : payload.news_updates });

        case GET_CATEGORIES_SUCCESS :
            return Object.assign({}, state, { categories : payload.categories });

        default :
            return state;
    }
}
