import { GET_EXAMS_SUCCESS, GET_EXAM_SUCCESS } from '../actions/Exams';

const initialState = {
    exams : [],
    exam : {}
}

export default function Exam_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_EXAM_SUCCESS :
            return Object.assign({}, state, { exam : payload.exam });

        case GET_EXAMS_SUCCESS :
            return Object.assign({}, state, { exams : payload.exams });

        default :
            return state;
    }
}
