import React from 'react';
import './Questions.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getQuestionDetails, updateQuestion } from '../../actions/Questions';
import { getDropDownCourses } from '../../actions/Courses';
import swal from 'sweetalert';

class Questions extends React.Component {
  constructor(props){
    super(props);
    const {match, question} = props;
    this.state = { exam_id : match.params.exam_id, exam_name : match.params.exam_name, question_id : match.params.question_id, images: {question_image: question.question_image ? question.question_image : '', option1: question.option1 ? question.option1 : '', option2: question.option2 ? question.option2 : '', option3: question.option3 ? question.option3 : '', option4: question.option4 ? question.option4 : '', option5: question.option5 ? question.option5 : ''}, answerType: question.answer_type ? question.answer_type : '0'};
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    // this.props.onGetDropDownCourses();
    this.props.onGetQuestionDetails(this.state.question_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let {images} = this.state;
    let question = this.props.question;

    if(question.answer_type == '1' && (name == 'question_image' || name === 'option1' || name === 'option2' || name === 'option3' || name === 'option4' || name === 'option5')) {
      images[name] = URL.createObjectURL(e.target.files[0]);
    } else {
      question[name] = value;
    }

    this.setState({ question : question, images: images });
  }

  onUpdateQuestionSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
	  text: "To update this question?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_question_form'));
        this.props.onUpdateQuestion(this.state.question_id, formData);
      }
    });
  }

  render() {

    const { question, courses, base_url } = this.props;
    const {exam_id, exam_name, images} = this.state;

    let question_image_preview = '';
    let option1_image_preview = '';
    let option2_image_preview = '';
    let option3_image_preview = '';
    let option4_image_preview = '';
    let option5_image_preview = '';

    if(question.question_image && question.question_image !== ''){
      question_image_preview = <img src={base_url + question.question_image} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.question_image !== ''){
      question_image_preview = <img src={images.question_image} className="img-fluid img-thumbnail" alt=""/>;
    }

    if(question.option1 && question.option1 !== ''){
      option1_image_preview = <img src={base_url + question.option1} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.option1 !== ''){
      option1_image_preview = <img src={images.option1} className="img-fluid img-thumbnail" alt=""/>;
    }

    if(question.option2 && question.option2 !== ''){
      option2_image_preview = <img src={base_url + question.option2} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.option2 !== ''){
      option2_image_preview = <img src={images.option2} className="img-fluid img-thumbnail" alt=""/>;
    }

    if(question.option3 && question.option3 !== ''){
      option3_image_preview = <img src={base_url + question.option3} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.option3 !== ''){
      option3_image_preview = <img src={images.option3} className="img-fluid img-thumbnail" alt=""/>;
    }

    if(question.option4 && question.option4 !== ''){
      option4_image_preview = <img src={base_url + question.option4} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.option4 !== ''){
      option4_image_preview = <img src={images.option4} className="img-fluid img-thumbnail" alt=""/>;
    }

    if(question.option5 && question.option5 !== ''){
      option5_image_preview = <img src={base_url + question.option5} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.option5 !== ''){
      option5_image_preview = <img src={images.option5} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/exams">Exams</Link> </li>
                  <li className="breadcrumb-item"><Link to={"/questions/" + exam_name + "/" + exam_id}>Questions</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Question</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Question </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/questions/add_question"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

          {/*  Edit question form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_question_form" onSubmit={this.onUpdateQuestionSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Subject - Course : </label>
                      <input type="text" placeholder="Subject - Course" className="form-control" value={exam_name} disabled/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Question : </label>
                      <textarea rows={4} name="question" placeholder="Question" className="form-control" value={question.question ? question.question : ''} onChange={this.onChangeHandler}/>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Question Image : </label>
                        <input type="file" name="question_image" placeholder="File" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {question_image_preview}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Answer Type * : </label>
                      <select name="answer_type" className="form-control" value={question.answer_type ? question.answer_type : ''} onChange={this.onChangeHandler} required>
                        <option value="0"> Text </option>
                        <option value="1"> Image </option>
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text"> Correct Answer * : </label>
                        <select name="answer" className="form-control" value={question.answer ? question.answer : ''} onChange={this.onChangeHandler} required>
                          <option value="1"> Option 1 </option>
                          <option value="2"> Option 2 </option>
                          <option value="3"> Option 3 </option>
                          <option value="4"> Option 4 </option>
                          <option value="5"> Option 5 </option>
                        </select>
                      </div>
                      <div className="col">
                        <label className="small_text"> Status : </label>
                        <select name="status" className="form-control" value={question.status ? question.status : ''} onChange={this.onChangeHandler} required>
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {
                    question.answer_type == "0" ? 
                      <div className="col">
                         <div className="form-group">
                          <label className="small_text"> Option 1 * : </label>
                          <textarea rows={2} name="option1" placeholder="Option 1 *" className="form-control" value={question.option1 ? question.option1 : ''} onChange={this.onChangeHandler} required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 2 * : </label>
                          <textarea rows={2} name="option2" placeholder="Option 2 *" className="form-control" value={question.option2 ? question.option2 : ''} onChange={this.onChangeHandler} required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 3 * : </label>
                          <textarea rows={2} name="option3" placeholder="Option 3 *" className="form-control" value={question.option3 ? question.option3 : ''} onChange={this.onChangeHandler} required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 4 * : </label>
                          <textarea rows={2} name="option4" placeholder="Option 4" className="form-control" value={question.option4 ? question.option4 : ''} onChange={this.onChangeHandler} required/>
                        </div>

                        <div className="form-group">
                          <label className="small_text"> Option 5 : </label>
                          <textarea rows={2} name="option5" placeholder="Option 5" className="form-control" value={question.option5 ? question.option5 : ''} onChange={this.onChangeHandler}/>
                        </div>
                      </div>
                    : 
                      <div className="col">
                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 1 Image * : </label>
                            <input type="file" name="option1" placeholder="File" onChange={this.onChangeHandler} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {option1_image_preview}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 2 Image * : </label>
                            <input type="file" name="option2" placeholder="File" onChange={this.onChangeHandler} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {option2_image_preview}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 3 Image * : </label>
                            <input type="file" name="option3" placeholder="File" onChange={this.onChangeHandler} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {option3_image_preview}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 4 Image * : </label>
                            <input type="file" name="option4" placeholder="File" onChange={this.onChangeHandler} className="form-control" required/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {option4_image_preview}
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Option 5 Image : </label>
                            <input type="file" name="option5" placeholder="File" onChange={this.onChangeHandler} className="form-control"/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {option5_image_preview}
                          </div>
                        </div>
                      </div>
                    }

                  <input type="hidden" name="test_id" value={exam_id} />
                </div>
                 
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Question </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    courses : state.course.courses,
    question : state.question.question,
    base_url: state.admin.base_url
  }
};

const mapActionsRoProps = {
  onGetDropDownCourses : getDropDownCourses,
  onGetQuestionDetails : getQuestionDetails,
  onUpdateQuestion : updateQuestion
};

export default connect(mapStatesToProps, mapActionsRoProps) (Questions);
