import React from 'react';
import './WrittenExam.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getWrittenExamDetail, updateWrittenExam } from '../../actions/WrittenExam';
import { getAllCourses } from '../../actions/Courses';
import { getAllBatches } from '../../actions/Batches';

import swal from 'sweetalert';

class EditWrittenExam extends React.Component {

  constructor(props){
    super(props);
    this.state = { written_exam_id : this.props.match.params.written_exam_id, images : { achiever_image_path : '' } , message: '' , currentBatches : [], notification_image_path: '' };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  async componentWillMount () {

    await this.props.getWrittenExamDetail(this.state.written_exam_id);
    await this.props.getAllCourses();
    await this.props.getAllBatches(''); 

  }



  CourseChange = async (e) =>{
    const { name , value} = e.target;
    let currentBatches = []
    this.props.batches.filter(item =>{
      if(item.course_id == value){
        currentBatches.push(item)
      }
    })
    this.setState({
      currentBatches
    })
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let writtenExam = this.props.writtenExam;
    let images = this.state.images;
    writtenExam[name] = value;

    if(e.target.name === 'achiever_image_path'){
      images[name] = URL.createObjectURL(e.target.files[0]);
      writtenExam[name] = '';
    }

    this.setState({ writtenExam : writtenExam, images : images });
  }

  onUpdateGallerySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this achiever?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_achiever_form'));

        console.log("testtkjjj", this.state.written_exam_id);
        this.props.updateWrittenExam(this.state.written_exam_id, formData);
      }
    });
  }

  render() {

    const { writtenExam, base_url } = this.props;
    const {courses, batches} = this.props;


    console.log("yyyy",writtenExam.batch_id);

    const { images } = this.state;
    let image_preview = '';

    if(writtenExam && writtenExam.written_exam_question){
      image_preview = <img src={base_url + writtenExam.written_exam_question} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.written_exam_question){
      image_preview = <img src={images.written_exam_question} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/writtenExam">Written Exam</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Written Exam</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Written Exam </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/writtenExam/add_written_exam"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewAchiever}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit achiever form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="edit_achiever_form" onSubmit={this.onUpdateGallerySubmit} autoComplete="off">

               {/*<div className="form-group">
                    <label className="small_text"> Category * : </label>
                    <select name="gallery_category" className="form-control" value={written.gallery_category ? gallery.gallery_category : ''} onChange={this.onChangeHandler} className="form-control" required>
                        <option value="0">Select Category </option>
                        {
                          galleryCategorys && galleryCategorys.map(galleryCategory => (
                            <option key={galleryCategory.id} value={galleryCategory.id}>{galleryCategory.gallery_category_name} </option>
                        ))
                        }
                    </select>
                </div>

                <div className="form-group">
                  <label className="small_text">Gallery Name * : </label>
                  <input type="text" name="gallery_title" placeholder="Gallery image title *" value={gallery.gallery_title ? gallery.gallery_title : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

               
                */}


                 {/*<div className="form-group">
                  <label className="small_text"> Courses * : </label>
                  <select name="course_type" className="form-control" id="lang" value={writtenExam.course_id ? writtenExam.course_id : ''} onChange={this.CourseChange} required>
                    <option > Select Course </option>
                    {
                      courses.length > 0 && courses.map((d, i)=>{
                        return <option key={d.id} value={d.id}> {d.course_name} </option>
                      })
                    }
                  </select>
                </div>

                <div className="form-group">
                  <label className="small_text"> Batch * : </label>
                  <select name="batch_id" className="form-control"  value={writtenExam.batch_id ? writtenExam.batch_id : ''} onChange={this.onChangeHandler} required>
                    <option value=""> Select Batch </option>
                    {
                      this.state.currentBatches.length > 0 && this.state.currentBatches.map((d, i)=>{
                        return <option key={d.id} value={d.id}> {d.batch_name} </option>
                      })
                    }
                  </select>
                </div>*/}


                 <div className="form-group">
                  <label className="small_text">Exam title * : </label>
                  <input type="text" name="written_exam_title" placeholder="Exam title *" value={writtenExam.written_exam_title ? writtenExam.written_exam_title : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

              
                <div className="form-group">
                  <label className="small_text">Exam validity * : </label>
                  <input type="date" name="written_exam_validity" placeholder="Exam validity *" value={writtenExam.written_exam_validity ? writtenExam.written_exam_validity : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                 <div className="form-group">
                  <label className="small_text">Exam max marks * : </label>
                  <input type="number" name="written_exam_marks" placeholder="Exam max marks *" value={writtenExam.written_exam_marks ? writtenExam.written_exam_marks : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <label className="small_text">Exam Question Paper * : </label>
                    <input type="file" name="written_exam_question" onChange={this.onChangeHandler} className="form-control"/>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                    {image_preview}
                  </div>
                </div>

                <div className="form-group">
                  <label className="small_text">Status * : </label>
                  <select name="written_exam_status" className="form-control" value={writtenExam.written_exam_status} onChange={this.onChangeHandler} required>
                    <option value="Active"> Active </option>
                    <option value="InActive"> In Active </option>
                  </select>
                </div>
      
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Written Exam </button>
                </div>


              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    writtenExam : state.writtenExam.writtenExam,
    base_url : state.admin.base_url,
    courses : state.course.courses,
    batches : state.batch.batches
    
  }
};

const mapActionsRoProps = {
  getWrittenExamDetail : getWrittenExamDetail,
  updateWrittenExam : updateWrittenExam,
  getAllCourses : getAllCourses,
  getAllBatches : getAllBatches
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditWrittenExam);
