import React from 'react';
import './Routes.css';
import { Route, NavLink, HashRouter as Router } from 'react-router-dom';
import $ from 'jquery';

import Dashboard from '../Dashboard';

import Users from '../Users';
import AddUser from '../Users/AddUser';
import EditUser from '../Users/EditUser';

import Admissions from '../Admissions';
import AddAdmission from '../Admissions/AddAdmission';
import EditAdmission from '../Admissions/EditAdmission';

import FailedTransaction from '../FailedTransaction';
import EditFailedTransaction from '../FailedTransaction/EditFailedTransaction';


import HostelBookings from '../HostelBookings';
import AddHostelBooking from '../HostelBookings/AddBooking';
import EditHostelBooking from '../HostelBookings/EditBooking';

import Hostels from '../Hostels';
import AddHostel from '../Hostels/AddHostel';
import EditHostel from '../Hostels/EditHostel';

import Notifications from '../Notifications';

import Reports from '../Reports';

import AdminUsers from '../AdminUsers';
import AddAdmin from '../AdminUsers/AddAdmin';
import EditAdmin from '../AdminUsers/EditAdmin';


import NewsUpdates from '../NewsUpdates';
import AddNews from '../NewsUpdates/AddNews';
import EditNews from '../NewsUpdates/EditNews';

import RollingImages from '../RollingImages';
import AddImage from '../RollingImages/AddImage';
import EditImage from '../RollingImages/EditImage';

import Courses from '../Courses';
import AddCourse from '../Courses/AddCourse';
import EditCourse from '../Courses/EditCourse';

import Batches from '../Batches';
import AddBatch from '../Batches/AddBatch';
import EditBatch from '../Batches/EditBatch';

import Classes from '../Classes';
import AddClass from '../Classes/AddClass';
import EditClass from '../Classes/EditClass';

import Faculties from '../Faculties';
import AddFaculty from '../Faculties/AddFaculty';
import EditFaculty from '../Faculties/EditFaculty';

import Achievers from '../Achievers';
import AddAchiever from '../Achievers/AddAchiever';
import EditAchiever from '../Achievers/EditAchiever';

import Books from '../Books';
import AddBook from '../Books/AddBook';
import EditBook from '../Books/EditBook';

import Exams from '../Exams';
import AddExam from '../Exams/AddExam';
import EditExam from '../Exams/EditExam';

import Questions from '../Questions';
import AddQuestion from '../Questions/AddQuestion';
import EditQuestion from '../Questions/EditQuestion';

import Videos from '../Videos';
import AddVideo from '../Videos/AddVideo';
import EditVideo from '../Videos/EditVideo';

import Gallery from '../Gallery';
import AddGallery from '../Gallery/AddGallery';
import EditGallery from '../Gallery/EditGallery';

import GalleryCategory from '../GalleryCategory';
import AddGalleryCategory from '../GalleryCategory/AddGalleryCategory';
import EditGalleryCategory from '../GalleryCategory/EditGalleryCategory';

import WrittenExam from '../WrittenExam';
import AddWrittenExam from '../WrittenExam/AddWrittenExam';
import EditWrittenExam from '../WrittenExam/EditWrittenExam';

import DeliveryCharge from '../DeliveryCharge';
import AddDeliveryCharge from '../DeliveryCharge/AddDeliveryCharge';
import EditDeliveryCharge from '../DeliveryCharge/EditDeliveryCharge';


import Subscription from '../Subscription';
import AddSubscription from '../Subscription/AddSubscription';
import EditSubscription from '../Subscription/EditSubscription';


import Profile from '../Profile';
import { connect } from 'react-redux';

class Routes extends React.Component {

	CloseMenu () {
		$('#admin_menu_navbar').collapse('hide');
	}

	render () {
		const {adminInfo} = this.props;

		return (
			<Router>
				<nav className="navbar navbar-light" id="menu_navbar">
					<button className="navbar_btn navbar-toggler" type="button" data-toggle="collapse" data-target="#admin_menu_navbar" aria-controls="admin_menu_navbar" aria-expanded="false" aria-label="{{ __('Toggle navigation') }}">
						<img src={process.env.PUBLIC_URL + '/menu_button.png'} alt="Menu" height="30"/>
					</button>

					<div className="collapse navbar-collapse" id="admin_menu_navbar">
						<button type="button" className="close close_btn" onClick={this.CloseMenu}> <img src={process.env.PUBLIC_URL + '/close_button.png'} alt="Close" height="30"/> </button>
						<ul className="navbar-nav sticky ml-auto pt-4 mb-5 pb-5">
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/"> Dashboard </NavLink> </li>
							{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/achievers"> Achievers </NavLink> </li>}
							{(adminInfo.user_role == 2) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/admins"> Admins </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/admissions"> Admissions </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/failedTransaction"> Failed Transaction </NavLink> </li>}

							{(adminInfo.user_role == 2 || adminInfo.user_role == 3 || adminInfo.user_role == 6 || adminInfo.user_role == 11) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/batches"> Batches </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/books"> Books </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/courses"> Courses </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/deliveryCharge"> Delivery Charge </NavLink> </li>}

							{(adminInfo.user_role == 2 || adminInfo.user_role == 7) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/exams"> Exams </NavLink> </li>}


							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/gallery"> Gallery Images </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/galleryCategory"> Gallery Category </NavLink> </li>}

							{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/faculties"> Faculties </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 10) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/hostels"> Hostels </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 10) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/hostel_bookings"> Hostel Bookings </NavLink> </li>}

							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/notifications"> Notifications </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/news_updates"> News Updates </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 5) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/reports"> Reports </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/rolling_images"> Rolling Images </NavLink> </li>}

							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/writtenExam"> Written Exam </NavLink> </li>}


							{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/users"> Users </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/videos"> Videos </NavLink> </li>}
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/profile"> Profile </NavLink> </li>
						</ul>
					</div>
				</nav>

				{/* User roles */}
				{/* 1 -> Student
				2 -> Master admin
				3 -> Course admin
				4 -> Admission admin
				5 -> Reports admin
				6 -> Faculty admin
				7 -> Exams admin
				8 -> Data admin
				9 -> Others admin
				10 -> Hostel admin 
				11 -> Faculty*/}
		
				<Route exact path="/" component={Dashboard} />

				{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && 
					<div>
						<Route exact path="/gallery" component={Gallery} />
						<Route exact path="/gallery/add_gallery" component={AddGallery} />
						<Route exact path="/gallery/edit_gallery/:gallery_id" component={EditGallery} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && 
					<div>
						<Route exact path="/galleryCategory" component={GalleryCategory} />
						<Route exact path="/galleryCategory/add_gallery_category" component={AddGalleryCategory} />
						<Route exact path="/galleryCategory/edit_gallery_category/:gallery_category_id" component={EditGalleryCategory} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && 
					<div>
						<Route exact path="/writtenExam" component={WrittenExam} />
						<Route exact path="/writtenExam/add_written_exam" component={AddWrittenExam} />
						<Route exact path="/writtenExam/edit_written_exam/:written_exam_id" component={EditWrittenExam} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && 
					<div>
						<Route exact path="/rolling_images" component={RollingImages} />
						<Route exact path="/rolling_images/add_image" component={AddImage} />
						<Route exact path="/rolling_images/edit_image/:image_id" component={EditImage} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && 
					<div>
						<Route exact path="/users" component={Users} />
						<Route exact path="/users/add_user" component={AddUser} />
						<Route exact path="/users/edit_user/:user_id" component={EditUser} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && 
					<div>
						<Route exact path="/admissions" component={Admissions} />
						<Route exact path="/admissions/add_admission/:mobile_number" component={AddAdmission} />
						<Route exact path="/admissions/edit_admission/:admi_id" component={EditAdmission} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && 
					<div>
						<Route exact path="/failedTransaction" component={FailedTransaction} />
						<Route exact path="/failedTransaction/edit_failedTransaction/:transaction_id" component={EditFailedTransaction} />
					</div>
				}

			
				{(adminInfo.user_role == 2 || adminInfo.user_role == 10) && 
					<div>
						<Route exact path="/hostel_bookings" component={HostelBookings} />
						<Route exact path="/hostel_bookings/add_booking" component={AddHostelBooking} />
						<Route exact path="/hostel_bookings/:user_id/:booking_id/edit_booking/:hostel_id" component={EditHostelBooking} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 10) && 
					<div>
						<Route exact path="/hostels" component={Hostels} />
						<Route exact path="/hostels/add_hostel" component={AddHostel} />
						<Route exact path="/hostels/edit_hostel/:hostel_id" component={EditHostel} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && 
					<div>
						<Route exact path="/notifications" component={Notifications} />
					</div>
				}

				{(adminInfo.user_role == 2) && 
					<div>
						<Route exact path="/admins" component={AdminUsers} />
						<Route exact path="/admins/add_admin" component={AddAdmin} />
						<Route exact path="/admins/edit_admin/:admin_id" component={EditAdmin} />
					</div>
				}

				




				{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && 
					<div>
						<Route exact path="/courses" component={Courses} />
						<Route exact path="/courses/add_course" component={AddCourse} />
						<Route exact path="/courses/edit_course/:course_id" component={EditCourse} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && 
					<div>
						<Route exact path="/deliveryCharge" component={DeliveryCharge} />
						<Route exact path="/deliveryCharge/add_delivery_charge" component={AddDeliveryCharge} />
						<Route exact path="/deliveryCharge/edit_delivery_charge/:delivery_charge_id" component={EditDeliveryCharge} />
					</div>
				}


				{(adminInfo.user_role == 2 || adminInfo.user_role == 3 || adminInfo.user_role == 6 || adminInfo.user_role == 11) && 
					<div>
						<Route exact path="/batches" component={Batches} />
						<Route exact path="/batches/add_batch" component={AddBatch} />
						<Route exact path="/batches/:type/edit_batch/:batch_id" component={EditBatch} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 3 || adminInfo.user_role == 6 || adminInfo.user_role == 11) && 
					<div>
						<Route exact path="/classes/:batch_id" component={Classes} />
						<Route exact path="/classes/:batch_id/add_class" component={AddClass} />
						<Route exact path="/classes/:batch_id/edit_class/:class_id" component={EditClass} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 3 || adminInfo.user_role == 6 || adminInfo.user_role == 11) && 
					<div>
						<Route exact path="/subscription/:book_id" component={Subscription} />
						<Route exact path="/subscription/:book_id/add_subscription" component={AddSubscription} />
						<Route exact path="/subscription/:book_id/edit_subscription/:subscription_id" component={EditSubscription} />
					</div>
				}


				{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && 
					<div>
						<Route exact path="/news_updates" component={NewsUpdates} />
						<Route exact path="/news_updates/add_news" component={AddNews} />
						<Route exact path="/news_updates/edit_news/:news_id" component={EditNews} />
					</div>
				}
				
				{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && 
					<div>
						<Route exact path="/faculties" component={Faculties} />
						<Route exact path="/faculties/add_faculty" component={AddFaculty} />
						<Route exact path="/faculties/edit_faculty/:faculty_id" component={EditFaculty} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && 
					<div>
						<Route exact path="/achievers" component={Achievers} />
						<Route exact path="/achievers/add_achiever" component={AddAchiever} />
						<Route exact path="/achievers/edit_achiever/:achiever_id" component={EditAchiever} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && 
					<div>
						<Route exact path="/books" component={Books} />
						<Route exact path="/books/add_book" component={AddBook} />
						<Route exact path="/books/edit_book/:book_id" component={EditBook} />
					</div>
				}
		
				{(adminInfo.user_role == 2 || adminInfo.user_role == 7) && 
					<div>
						<Route exact path="/exams" component={Exams} />
						<Route exact path="/exams/add_exam" component={AddExam} />
						<Route exact path="/exams/edit_exam/:exam_id" component={EditExam} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 7) && 
					<div>
						<Route exact path="/questions/:exam_name/:exam_id" component={Questions} />
						<Route exact path="/questions/:exam_name/:exam_id/add_question" component={AddQuestion} />
						<Route exact path="/questions/:exam_name/:exam_id/edit_question/:question_id" component={EditQuestion} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && 
					<div>
						<Route exact path="/videos" component={Videos} />
						<Route exact path="/videos/add_video" component={AddVideo} />
						<Route exact path="/videos/edit_video/:video_id" component={EditVideo} />
					</div>
				}

				{(adminInfo.user_role == 2 || adminInfo.user_role == 5) && 
					<div>
						<Route exact path="/reports" component={Reports} />
					</div>
				}
				
				<Route exact path="/profile" component={Profile} />
				
			</Router>
		);
	}
};

const mapStatesToProps = (state) => {
	return {
		isAuth : state.admin.isAuth,
		adminInfo : state.admin.adminInfo,
	}
}

export default connect(mapStatesToProps, null) (Routes);
