import { GET_USERS_SUCCESS, GET_USERLIST_SUCCESS, GET_USER_SUCCESS, GET_ROLES_SUCCESS } from '../actions/Users';

const initialState = {
    users : [],
    user : {},
    roles: [],
    userList: {
        data: [],
        dataCount: 0
    }
}

export default function User_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_USER_SUCCESS :
            return Object.assign({}, state, { user : payload.user });

        case GET_USERS_SUCCESS :
            return Object.assign({}, state, { users : payload.users });

        case GET_USERLIST_SUCCESS :
            return Object.assign({}, state, { userList : payload.users });

        case GET_ROLES_SUCCESS :
            return Object.assign({}, state, { roles : payload.roles });

        default :
            return state;
    }
}
