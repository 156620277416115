import { GET_FACULTIES_SUCCESS, GET_FACULTY_SUCCESS } from '../actions/Faculties';

const initialState = {
    faculties : [],
    faculty : {}
}

export default function Faculty_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_FACULTY_SUCCESS :
            return Object.assign({}, state, { faculty : payload.faculty });

        case GET_FACULTIES_SUCCESS :
            return Object.assign({}, state, { faculties : payload.faculties });

        default :
            return state;
    }
}
