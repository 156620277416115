import React from 'react';
import './WrittenExam.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addWrittenExam } from '../../actions/WrittenExam';
import { getAllCourses } from '../../actions/Courses';
import { getAllBatches } from '../../actions/Batches';

import swal from 'sweetalert';

class AddWrittenExam extends React.Component {

  constructor(props){
    super(props);
    this.state = { images : { achiever_image_path : ''}, message: '' , currentBatches : [], notification_image_path: '' };

    this.onAddWrittenExamSubmit = this.onAddWrittenExamSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    let images = this.state.images;
    images[e.target.name] = URL.createObjectURL(e.target.files[0]);
    this.setState({ images : images });
  }



  async componentWillMount() {
    await this.props.getAllCourses();
    await this.props.getAllBatches('');
  }



  CourseChange = async (e) =>{
    const { name , value} = e.target;
    let currentBatches = []
    this.props.batches.filter(item =>{
      if(item.course_id == value){
        currentBatches.push(item)
      }
    })
    this.setState({
      currentBatches
    })
  }

  onAddWrittenExamSubmit = (e) => {

    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new achiever?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_achiever_form'));
        this.props.onAddWrittenExam(formData);
      }
    });
  }

  render() {

    const { images } = this.state;
    const {courses, batches} = this.props;


    let image_preview = '';


    if(images.achiever_image_path){
      image_preview = <img src={images.achiever_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/writtenExam">Written Exam</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Written Exam</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Written Exam </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/* Add new achiever form */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="add_achiever_form" onSubmit={this.onAddWrittenExamSubmit} autoComplete="off">

                
              <div className="form-group">
                  <label className="small_text"> Courses * : </label>
                  <select name="course_type" className="form-control"  id="lang" onChange={this.CourseChange}>
                    <option > Select Course </option>
                    {
                      courses.length > 0 && courses.map((d, i)=>{
                        return <option key={d.id} value={d.id}> {d.course_name} </option>
                      })
                    }
                  </select>
                </div>
                <div className="form-group">
                  <label className="small_text"> Batch * : </label>
                  <select name="batch_id" className="form-control" >
                    <option value=""> Select Batch </option>
                    {
                      this.state.currentBatches.length > 0 && this.state.currentBatches.map((d, i)=>{
                        return <option key={d.id} value={d.id}> {d.batch_name} </option>
                      })
                    }
                  </select>
                </div>


                <div className="form-group">
                    <input type="text" name="written_exam_title" placeholder="Exam title *" className="form-control" required/>
                </div>

                 <div className="form-group">
                    <input type="date" name="written_exam_validity" placeholder="Exam Validity *" className="form-control" required/>
                </div>

                <div className="form-group">
                    <input type="number" name="written_exam_marks" placeholder="Exam Max marks *" className="form-control" required/>
                </div>

              
                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <label className="small_text">Exam Question Paper * : </label>
                    <input type="file" name="written_exam_question" onChange={this.onChangeHandler} className="form-control"/>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                      {image_preview}
                  </div>
                </div>

                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Written Exam </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {

  return {
    isLoading : state.admin.isLoading,
    courses : state.course.courses,
    batches : state.batch.batches

  }
};

const mapActionsRoProps = {
  onAddWrittenExam : addWrittenExam,
  getAllCourses : getAllCourses,
  getAllBatches : getAllBatches

};

export default connect(mapStatesToProps, mapActionsRoProps) (AddWrittenExam);
