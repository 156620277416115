import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewCourse } from '../../actions/Courses';
import swal from 'sweetalert';

class AddCourse extends React.Component {
  constructor(props){
    super(props);
    this.state = { images : { course_icon : ''}};
  }

  onAddCourseSubmit = (e) => {
    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: "To add this new course?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_course_form'));
        this.props.onAddNewCourse(formData);
      }
    });
  }

  handleImageChange = (e) => {
    const { name } = e.target;
    let images = this.state.images;

    if(name === 'course_icon_path'){
      images['course_icon'] = URL.createObjectURL(e.target.files[0]);
    }

    this.setState({ images : images });
  }

  render() {

    const {images, course} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/courses">Courses</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Course</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Course </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new course form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_course_form" onSubmit={this.onAddCourseSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Course Name * : </label>
                      <input type="text" name="course_name" placeholder="Course Name" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Description * : </label>
                      <textarea rows={6} name="course_discription" placeholder="Course Description" className="form-control" />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Syllabus * : </label>
                      <textarea rows={6} name="course_syllabus" placeholder="Course Syllabus" className="form-control" />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Instructions * : </label>
                      <textarea rows={6} name="course_instructions" placeholder="Course Instructions" className="form-control" />
                    </div>

                    <div className="form-group">
                        <label className="small_text">Course Duration * : </label>
                      <input type="text" name="course_duration" placeholder="Course Duration" className="form-control" required/>
                    </div>

                    <div className="form-group">
                        <label className="small_text">Order Number * : </label>
                      <input type="number" name="order_number" placeholder="Course Order Number" className="form-control" required/>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Course Type : </label>
                        <select name="course_type" className="form-control">
                          <option value="1"> Online </option>
                          <option value="0"> Offline </option>
                        </select>
                      </div>
                      <div className="col">
                        <label className="small_text">Status : </label>
                        <select name="status" className="form-control">
                          <option value="1"> Active </option>
                          <option value="0"> Inactive </option>
                        </select>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Course Icon : </label>
                        <input type="file" name="course_icon_path" placeholder="Course Icon" onChange={(e) => this.handleImageChange(e)} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {images.course_icon !== '' ? <img src={images.course_icon} className="img-fluid img-thumbnail" alt="Banner"/> : null}
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Course </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
  }
};

const mapActionsRoProps = {
  onAddNewCourse : addNewCourse,
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddCourse);
