import { GET_ACHIEVERS_SUCCESS, GET_ACHIEVER_SUCCESS } from '../actions/Achievers';

const initialState = {
    achievers : [],
    achiever : {}
}

export default function Achiever_reducer (state = initialState, { type, payload } ) {

    switch (type)
    {
        case GET_ACHIEVER_SUCCESS :
            return Object.assign({}, state, { achiever : payload.achiever });

        case GET_ACHIEVERS_SUCCESS :
            return Object.assign({}, state, { achievers : payload.achievers });

        default :
            return state;
    }
}
