import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllHostelBookings, deleteHostel } from '../../actions/HostelBookings';
import { onDisplayMessage, onDisplayError, onLoadingTrue, onLoadingFalse } from '../../actions/Login';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';
import server from '../../utils/Server';
import {SortItems, SearchItems} from '../../utils/DataTable';

class Hostels extends React.Component {
  constructor(props){
    super(props);
    this.state = { activePage : 1, sortKey: 1, searchKey: '', pageNo: 1 };
    this.deleteHostel = this.deleteHostel.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
    this.props.onGetBookings();
  }

  handleHostelCheckout = (booking_id, hostel_id, balance_amount) => {

    swal({
      title: balance_amount > 0 ? ("Balance - Rs. " + balance_amount) : "PAID STUDENT",
      text: "This user has Rs. " + balance_amount + " to be paid, Are you sure to checkout this user?",
      dangerMode: true,
      buttons: ['CANCEL', 'CHECKOUT'],
    })
    .then(willCheckout => {
      if (willCheckout) {
          this.props.onLoadingTrue();
          server.post('/HostelCheckout/' + booking_id + '?hostel_id=' + hostel_id)
            .then(response => {
              this.props.onLoadingFalse();
              if(response.data.statuscode === 200) {
                this.props.onDisplayMessage(response.data.message);
                this.props.onGetBookings();
              } else {
                this.props.onDisplayError(response.data.message);
              }
            })
            .catch(error => {
              this.props.onLoadingFalse();
              this.props.onDisplayError('Failed, Try again later');
            });
      }
    });
  }

  deleteHostel = (hostel_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this booking permanently?",
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteHostel(hostel_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetBookings('?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value);
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetBookings('?' + params);
  }

  handleSorting = (sortKey) => {
    this.setState({sortKey: sortKey});
  }

  handleSearch = (searchKey) => {
    this.setState({searchKey: searchKey.toLowerCase()});
  }

  renderHostelBookings = () => {
    const { base_url } = this.props;
    const {searchKey, pageNo} = this.state;
    var bookings = this.props.bookings;
    var sortKey = parseInt(this.state.sortKey);

    // Sorting
    if (sortKey === 1) {
      SortItems(bookings, 'id', 2, 2);
    } else if(sortKey === 2) {
      SortItems(bookings, 'id', 2, 1);
    } else if (sortKey === 3) {
      SortItems(bookings, 'username', 1, 2);
    } else if(sortKey === 4) {
      SortItems(bookings, 'username', 1, 1);
    } else if(sortKey === 5) {
      SortItems(bookings, 'hostel_name', 1, 2);
    } else if(sortKey === 6) {
      SortItems(bookings, 'hostel_name', 1, 1);
    } else if(sortKey === 7) {
      SortItems(bookings, 'total_price', 2, 2);
    } else if(sortKey === 8) {
      SortItems(bookings, 'total_price', 2, 1);
    } else if(sortKey === 9) {
      SortItems(bookings, 'balance_amount', 2, 2);
    } else if(sortKey === 10) {
      SortItems(bookings, 'balance_amount', 2, 1);
    } else if(sortKey === 11) {
      SortItems(bookings, 'status', 1, 1);
    } else if(sortKey === 12) {
      SortItems(bookings, 'status', 1, 2);
    }

    // Searching
    if (searchKey !== "") {
      // bookings = SearchItems(bookings, ['name', 'email', 'mobile_number'], searchKey);
      bookings = bookings.filter(booking => ((booking.username && booking.username.toLowerCase().includes(searchKey)) || ( booking.user_details.mobile_number && booking.user_details.mobile_number.toString().includes(searchKey)) || (booking.hostel_name && booking.hostel_name.toLowerCase().includes(searchKey)) || ( booking.total_price && booking.total_price.toString().includes(searchKey)) || (booking.balance_amount && booking.balance_amount.toString().includes(searchKey))));
    }

    if(bookings && bookings !== '' && bookings.length > 0) {
      return (
        <tbody>
          {bookings.map((booking, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{booking.hostel_name}</td>
              <td>{booking.username}<hr className="m-1"/>{booking.user_details.mobile_number}</td>
              <td>{booking.no_of_months + ' Months'}</td>
              <td>{booking.total_price > 0 ? ('Rs. ' + booking.total_price) : '0'}</td>
              <td className="text-danger"><b>{booking.balance_amount > 0 ? ('Rs. ' + booking.balance_amount) : '0'}</b></td>
              {/* <td>{booking.status === '1' ? 'Active' : 'Inactive'}</td> */}
              {/* <td>{(booking.balance_amount > 0 && booking.status === '1') ? <Link to={'/hostel_bookings/' + booking.user_id + '/edit_booking/' + booking.hostel_id}><button className="btn btn-primary btn-sm" title="Pay">{booking.balance_amount > 0 ? 'PAY' : 'PAID'}</button></Link> : <b className="text-primary">PAID</b>}</td> */}
              {/* <td>{(booking.balance_amount > 0) ? <Link to={'/hostel_bookings/' + booking.user_id + '/edit_booking/' + booking.hostel_id}><button className="btn btn-primary btn-sm" title="Pay">{booking.balance_amount > 0 ? 'PAY' : 'PAID'}</button></Link> : <b className="text-success medium_text"><i className="fa fa-check mr-1"/>PAID</b>}</td> */}
              <td><Link to={'/hostel_bookings/' + booking.user_id + '/' + booking.booking_id + '/edit_booking/' + booking.hostel_id}><button className="btn btn-primary btn-sm" title="Pay">{booking.balance_amount > 0 ? 'PAY' : 'PAID HISTORY'}</button></Link></td>
              <td>{booking.status === '1' ? <button className="btn btn-danger btn-sm" title="Checkout" onClick={() => this.handleHostelCheckout(booking.id, booking.hostel_id, booking.balance_amount)}>CHECKOUT </button> : <b className="text-danger">CHECKED <br/> OUT</b>}</td>
            </tr>
        ))}
        </tbody>
      )
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="8" className="text-center"> No bookings found.</td>
          </tr>
        </tbody>
      )
    }
  }

  render() {

    const { bookings, total_count, page_count, page_size, base_url } = this.props;
    const {sortKey, searchKey} = this.state;
    let pagination_display = '';

    // Show pagination only if values are more than 10
    if(total_count > 10) {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} bookings. </p> */}
          <p className="small_text d-inline ml-2"> Showing {bookings.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    } else {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} bookings. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {bookings.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Hostel Bookings</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Hostel Bookings </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/hostel_bookings/add_booking"><button type="button" className="btn btn-primary btn-sm">New Booking <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

          <div id="search_filter_form">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSorting(e.target.value)}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Students A-Z</option>
                    <option value="4">Students Z-A</option>
                    <option value="5">Hostel A-Z</option>
                    <option value="6">Hostel Z-A</option>
                    <option value="7">Total Fees H-L</option>
                    <option value="8">Total Fees L-H</option>
                    <option value="9">Balance H-L</option>
                    <option value="10">Balance L-H</option>
                    <option value="11">Active First</option>
                    <option value="12">Inactive First</option>
                  </select>
                </div>
              </div>

              <div className="col-md-2 col-12" />
              <div className="col text-right">
                <div className="input-group mb-3">
                <div className="input-group-append">
                    <button type="button" className="search_btn btn-primary px-3"><i className="fa fa-search"></i></button>
                  </div>
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, mobile number, hostel, balance or fees" value={searchKey} onChange={(e) => this.handleSearch(e.target.value)} autoComplete="off"/>
                 
                </div>
              </div>
            </div>
          </div>

           {/* filters, sort and search container */}
           {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Hostels A-Z</option>
                    <option value="2">Hostels Z-A</option>
                    <option value="3">Newest First</option>
                    <option value="4">Oldest First</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input type="hidden" name="page_size" id="page_size" value={page_size}/>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by hostel name" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all bookings table */}
          <table className="table table-responsive table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No.</th>
                <th className="bg-blue text-light" width="20%">Hostel</th>
                <th className="bg-blue text-light" width="20%">Student</th>
                <th className="bg-blue text-light" width="15%">Duration</th>
                <th className="bg-blue text-light" width="15%">Total Fees</th>
                <th className="bg-blue text-light" width="13%">Balance</th>
                {/* <th className="bg-blue text-light" width="12%">Status</th> */}
                <th className="bg-blue text-light" width="5%">Pay</th>
                <th className="bg-blue text-light" width="5%">Checkout</th>
              </tr>
            </thead>

            {/* Display all the bookings */}
            {this.renderHostelBookings()}
          </table>

          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    hostels : state.hostel.hostels,
    bookings : state.booking.bookings,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url: state.admin.base_url,
  }
};

const mapActionsRoProps = {
  onGetBookings : getAllHostelBookings,
  onDeleteHostel : deleteHostel,
  onDisplayMessage: onDisplayMessage,
  onDisplayError: onDisplayError,
  onLoadingTrue: onLoadingTrue,
  onLoadingFalse: onLoadingFalse
};

export default connect(mapStatesToProps, mapActionsRoProps) (Hostels);
