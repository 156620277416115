import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRollingImageDetails, updateRollingImage } from '../../actions/RollingImages';
import swal from 'sweetalert';

class EditImage extends React.Component {
  constructor(props){
    super(props);
    const { image } = this.props;
    this.state = { image : image, image_id : this.props.match.params.image_id, localImage: image.file_path !== '' ? image.file_path : '' };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetImageDetails(this.state.image_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (this.props.image.file_type == '0' && name === 'file_path'){
      this.setState({localImage: URL.createObjectURL(e.target.files[0])});
    } else {
      let image = this.props.image;
      image[name] = value;
      this.setState({ image : image });
    }
  }

  onUpdateImageSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this image?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_image_form'));
        this.props.onUpdateImage(this.state.image_id, formData);
      }
    });
  }

  render() {

    const { image, base_url } = this.props;
    const {localImage} = this.state;
    let image_preview = '';

    if(image.file_path){
      image_preview = <img src={base_url + image.file_path} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(localImage){
      image_preview = <img src={localImage} className="img-fluid img-thumbnail" alt=""/>;
    }


    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/rolling_images">Rolling Images</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Image</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Image </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/rolling_images/add_image"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit course form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12 card p-3">
              <br/>

              <form id="edit_image_form" onSubmit={this.onUpdateImageSubmit} autoComplete="off">
                

              <div className="form-group">
                  <select name="target_for" value={image.target_for ? image.target_for : 'App'} onChange={this.onChangeHandler} className="form-control">

                    <option value="App"> App </option>
                    <option value="web"> Web </option>
                  </select>
                </div>


                <div className="form-group">
                  <label className="small_text">Type : </label>
                  <select name="file_type" value={image.file_type ? image.file_type : '0'} onChange={this.onChangeHandler} className="form-control">
                    <option value="0"> Image </option>
                    <option value="1"> Video </option>
                  </select>
                </div>

                {
                  image.file_type === "0" ? 
                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Image : </label>
                        <input type="file" name="file_path" placeholder="File" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {image_preview}
                      </div>
                    </div>
                : <div className="form-group">
                    <label className="small_text">Youtube Video ID : </label>
                    <input type="text" name="file_path" placeholder="Youtube Video ID" className="form-control" value={image.file_path ? image.file_path : ''} onChange={this.onChangeHandler}/>
                  </div>
                }
                
                <div className="form-group">
                  <label className="small_text">Order number : </label>
                  <input type="number" name="order_number" placeholder="Course Order Number" className="form-control" value={image.order_number ? image.order_number : ''} onChange={this.onChangeHandler} required/>
                </div>
                
                <div className="form-group">
                  <label className="small_text">Status : </label>
                  <select name="status" value={image.status ? image.status : ''} onChange={this.onChangeHandler} className="form-control">
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>
            
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Image </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    image : state.rolling_images.rollingImage,
    base_url: state.admin.base_url,
  }
};

const mapActionsRoProps = {
  onGetImageDetails : getRollingImageDetails,
  onUpdateImage : updateRollingImage
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditImage);
