import React from 'react';
import './Videos.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllVideos, deleteVideo } from '../../actions/Videos';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';

class Videos extends React.Component {

  constructor(props){
    super(props);
    this.state = { activePage : 1 };
    this.deleteVideo = this.deleteVideo.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
    this.props.onGetVideos('');
  }

  deleteVideo = (video_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this video permanently?",
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteVideo(video_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetVideos('?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value); 
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetVideos('?' + params);
  }

  render() {

    const { videos, total_count, page_count, page_size, base_url } = this.props;
    let videos_display = {};
    let pagination_display = '';

    if(videos && videos !== '' && videos.length > 0)
    {
      videos_display = videos.map((video, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{video.video_subject}</td>
          <td>{video.video_chapter}</td>
          <td>{video.video_path}</td>
          <td>{video.status === '1' ? 'Active' : 'Inactive'}</td>
          <td><Link to={'/videos/edit_video/' + video.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
          {/* <td><button className="icon_button" title="Delete" onClick={() => this.deleteVideo(video.id)}> <i className="fa fa-trash text-danger"></i> </button></td> */}
        </tr>
      ))
    }
    else
    {
      videos_display = <tr><td colSpan="7" className="text-center"> No videos found.</td></tr>
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} videos. </p> */}
          <p className="small_text d-inline ml-2"> Showing {videos.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} videos. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {videos.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Videos</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Videos </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/videos/add_video"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewVideo}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Videos A-Z</option>
                    <option value="4">Videos Z-A</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input type="hidden" name="page_size" id="page_size" value={page_size}/>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by video, code or currency" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all videos table */}
          <table className="table table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No.</th>
                <th className="bg-blue text-light" width="25%">Subject</th>
                <th className="bg-blue text-light" width="25%">Chapter</th>
                <th className="bg-blue text-light" width="25%">Youtube ID</th>
                <th className="bg-blue text-light" width="10%">Status</th>
                <th className="bg-blue text-light" width="10%">Edit</th>
                {/* <th className="bg-blue text-light" width="5%">Delete</th> */}
              </tr>
            </thead>
            <tbody>

              {/* Display all the videos */}

              {videos_display}

            </tbody>
          </table>
          
          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
    videos : state.video.videos,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  onGetVideos : getAllVideos,
  onDeleteVideo : deleteVideo
};

export default connect(mapStatesToProps, mapActionsRoProps) (Videos);
