import { GET_DASHBOARD } from '../actions/Dashboard';

const initialState = {
    dashboardData: [],
};

export default function Admin_dashboard_reducer (state = initialState, { type, payload } ) {
    
    switch(type)
    {
        case GET_DASHBOARD:
            return Object.assign({}, state, { dashboardData : payload.dashboardData});

        default :
            return state;
    }
}
