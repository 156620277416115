import React from 'react';
import './NewsUpdates.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getNewsDetails, updateNewsUpdate } from '../../actions/NewsUpdates';
import swal from 'sweetalert';

class EditNews extends React.Component {

  constructor(props){
    super(props);
    const {news_update} = props;
    this.state = { news_id : this.props.match.params.news_id, news_update: news_update, news_image_path: news_update.news_image_path ? news_update.news_image_path : '' };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetNewsDetails(this.state.news_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (this.props.news_update.file_type == '0' && name === 'file_path'){
      this.setState({news_image_path: URL.createObjectURL(e.target.files[0])});
    } else {
      let news_update = this.props.news_update;
      news_update[name] = value;

      this.setState({ news_update : news_update });
    }
  }

  onUpdateNewsUpdateSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
	    text: "To update this news?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_news_update_form'));
        var news_streams = document.getElementsByClassName('news_streams');
        var streams = new Array();

        for(let i=0;i<news_streams.length;i++)
        {
          if(news_streams[i].checked)
          {
            streams.push(news_streams[i].value);
          }
        }

        formData.append('news_streams', streams.toString());
        this.props.onUpdateNewsUpdate(this.state.news_id, formData);
      }
    });
  }

  render() {

    const {news_update, base_url} = this.props;
    const {news_image_path} = this.state;

    let image_preview = '';

    if(news_update.news_image_path && news_update.news_image_path !== ''){
      image_preview = <img src={base_url + news_update.news_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(news_image_path !== ''){
      image_preview = <img src={news_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/news_updates">News</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit News</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit News </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/* <Link to="/news_updates/news_categories"><button type="button" className="btn btn-primary btn-sm">Categories <i className="ml-1 fa fa-plus"></i></button></Link> */}
              <Link to="/news_updates/add_news"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
        
          {/*  Edit news_update form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="edit_news_update_form" onSubmit={this.onUpdateNewsUpdateSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">News Title * : </label>
                      <textarea name="news_title" rows="3" placeholder="News Title *" value={news_update.news_title ? news_update.news_title : ''} onChange={this.onChangeHandler} className="form-control" required/>
                    </div>


                    <div className="form-group">
                      <label className="small_text">News Type : </label>
                      <select name="is_scroll_news" value={ news_update.is_scroll_news } onChange={this.onChangeHandler} className="form-control">
                        <option value="YES"> Scrolling </option>
                        <option value="NO"> General </option>
                      </select>
                    </div>


                    <div className="form-group">
                      <label className="small_text">File Type : </label>
                      <select name="file_type" value={news_update.file_type ? news_update.file_type : '0'} onChange={this.onChangeHandler} className="form-control">
                        <option value="0"> Image </option>
                        <option value="1"> Video </option>
                      </select>
                    </div>

                    {
                      news_update.file_type === "0" ? 
                        <div className="row form-group">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                            <label className="small_text">Image : </label>
                            <input type="file" name="news_image_path" placeholder="File" onChange={this.onChangeHandler} className="form-control"/>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            {image_preview}
                          </div>
                        </div>
                    : <div className="form-group">
                        <label className="small_text">Youtube Video ID : </label>
                        <input type="text" name="news_image_path" placeholder="Youtube Video ID" className="form-control" value={news_update.news_image_path ? news_update.news_image_path : ''} onChange={this.onChangeHandler}/>
                      </div>
                    }

                    <div className="form-group">
                      <label className="small_text">Status * : </label>
                      <select name="status" className="form-control" value={news_update.status ? news_update.status : ''} onChange={this.onChangeHandler} required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="small_text">News Description : </label>
                      <textarea name="news_description" rows="14" placeholder="News Description" value={news_update.news_description ? news_update.news_description : ''} onChange={this.onChangeHandler} className="form-control"/>
                    </div>
                  </div>
                </div>
                 
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update News </button>
                </div>
              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
    base_url : state.admin.base_url,
    news_update : state.news.news_update
  }
};

const mapActionsRoProps = {
  onGetNewsDetails : getNewsDetails,
  onUpdateNewsUpdate : updateNewsUpdate
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditNews);
